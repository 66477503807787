import React,{useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';


const DiscoveryScript = () => {
    return (
        <div className='discovery-script col-md-12 pt-3'>
            <div className="row justify-content-center">
                <div className='col-md-5'>
                    <h3 className='fs-5 text-center'>Discovery Call Script</h3>
                    <p className='fw-bold text-center'>Record All Calls and Take Notes on Inquiry Form</p>

                    <ul style={{marginLeft: "100px"}}>
                        <li className='pb-2'>
                            <span className='fw-bold'>Step 1: Research the Client (Before Call)</span>
                            <ul>
                                <li>Look at their website based on their email domain</li>
                                <li>Come up with a list of questions based on their company and inquiry description</li>
                            </ul>
                        </li>
                        <li className='pb-2'>
                            <span className='fw-bold'>Step 2: Ask Client The Questions About Their Project</span>
                            <ul>
                                <li>Questions You ListedWhile Doing Research</li>
                                <li>What the Project is</li>
                                <li>When the Project is</li>
                                <li>Where the Project is</li>
                            </ul>
                        </li>
                        <li className='pb-2'>
                            <span className='fw-bold'>Step 3: Go Over Our Process</span>
                            <ul>
                                <li>Free Location Walkthrough</li>
                                <li>Project Planning</li>
                                <li>The On-Set Experience</li>
                                <li>Midway Editing Meeting</li>
                            </ul>
                        </li>
                        <li className='pb-2'>
                            <span className='fw-bold'>Step 4: Summarize All Specific Details of Project</span>
                            <ul>
                                <li>Relevant Packages Based on Details</li>
                                <li>Times per Package</li>
                            </ul>
                        </li>
                        <li className='pb-2'>
                            <span className='fw-bold'>Step 5: Discuss Next Action Steps</span>
                            <ul>
                                <li>Proposal Being Sent</li>
                                <li>Mention Discount</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DiscoveryScript;