import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, useParams } from 'react-router-dom';
import './App.scss';
import Container from '@mui/material/Container';
import Routers from './routes';
import Layout from './shared/layouts/layout';
import Header from './shared/header';
import { AppStateProvider, useAppStateContext } from './state/provider';
import LoadingService from './shared/loader';
import { useAxios } from './core/useAxios';
import { LicenseInfo } from '@mui/x-license-pro';
import { decryptString, encryptString } from './utils/utils';
import { ConfirmProvider } from 'material-ui-confirm';
LicenseInfo.setLicenseKey(decryptString(`${process.env.REACT_APP_MUI_GRID_LIC}`));

function App() {
  const pathPattern: any = window.location.pathname;
  const { state } = useAppStateContext();
    const { isLoader } = state.config;
  return (
    <>
      <ConfirmProvider>
        <AppStateProvider>
          <BrowserRouter>
            <div className='h-100' id='app'>
              {/* {<LoadingService open={loading}/>} */}
              {/* {isHeader() ? <Layout />: null} */}
              {/* {isHeader() ? <Header />: null} */}
              <Suspense fallback={<div>Loading...</div>}>
                <Routers />
              </Suspense>
            </div>
          </BrowserRouter>
        </AppStateProvider>
      </ConfirmProvider>
    </>
  );
}

export default App;
