import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface StatusDialogProps {
    isOpen: boolean,
    handleClose: () => void,
    message: string,
    title: string
}

function Status_Dialog(props: StatusDialogProps) {
    const {isOpen, handleClose, message, title} = props;
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{title ? title.toUpperCase() : null}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            </DialogActions>
      </Dialog>
    );
}

export default Status_Dialog;