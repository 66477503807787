import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import DynamicTabs from '../../shared/tabs/Tabs';
import { NUMBER_10, _talent_day } from './modal';
import TalentForm from "./TalentForm";
import { TABS_NUM } from '../../utils/utils';

function Talent(props: any) {
    const { formikProps } = props;
    const { values, errors, setFieldValue, touched } = formikProps;

    const handleTalentDaysChange = (e: any) => {
        const talent_days: number = Number(e?.target?.value);
        let data: any = values?.talents?.days;
        const remaining_days = talent_days - data?.length;
        if (remaining_days > 0) {
            TABS_NUM(remaining_days || 0)
                .forEach(() => data.push(_talent_day))

            setFieldValue('talents.days', data);
        };
        if (talent_days < data?.length && data.length > 0) {
            const updated_day = data.slice(0, talent_days);
            setFieldValue(`talents.days`, updated_day);
        }
        setFieldValue('talents.talent_days_needed', Number(talent_days))
    }

    return (
        <>
            <div className='row m-0 mb-2 mt-3'>
                <div className='col-6 p-0'>
                    <FormControl variant="outlined" size='small' fullWidth>
                        <InputLabel id="talents_days_needed">Talent Days Needed?</InputLabel>
                        <Select
                            labelId="talents_days_needed"
                            id="talents_days_needed"
                            name={`talents.talent_days_needed`}
                            value={values.talents.talent_days_needed}
                            onChange={handleTalentDaysChange}
                            label="Talent Days Needed?"
                        >
                            {
                                NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>
                            {(errors?.talents?.talent_days_needed &&
                                touched?.talents?.talent_days_needed) && errors?.talents?.talent_days_needed
                            }
                        </FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className='row m-0 d-tabs'>
                {(values.talents.talent_days_needed && values.talents.talent_days_needed > 0) ?
                    <DynamicTabs 
                        tabNumber={values.talents.talent_days_needed}
                        formikProps={formikProps} 
                        label={'day'}
                        key={'days'}
                        components={TalentForm}
                        formName={'talents'}
                    />
                    : null
                }
            </div>
        </>
    );
}

export default Talent;