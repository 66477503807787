import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader, CardContent, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Person as PersonIcon } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { getUserInfo } from '../../../utils/utils';
import moment from 'moment';
import './widgets.scss';
import { APIMETHOD } from '../../../core/constant';
import { useAxios } from '../../../core/useAxios';
import { Box, Button, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Pagination } from "@mui/material";




interface Invoice {
  client_name: string;
  project_name: string;
  project_manager: string;
  invoice_id: number;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--true": {
    backgroundColor: "#81c784",
    "&:hover, &.Mui-selected": {
      backgroundColor: "#81c784",
    },
  },
}));



const CardWrapper = styled(Card)({
  width: '600px', // Reduce the card width
  margin: 'auto',
  backgroundColor: 'white',
  maxHeight: '800px', // Reduce the card height
  overflowY: 'auto',
  paddingTop: '0px',
  marginTop: '0px',
});

const CardHeaderWrapper = styled(CardHeader)({
  backgroundColor: 'white',
  color: 'black',
  font: "menu",
  textAlign: 'start',
  position: 'sticky',
  top: '0',
  zIndex: '1',
  paddingBottom: '0px',
  fontSize: '2rem', // Set your desired font size here
  fontWeight: 'bold', // Set font weight to bold for emphasis
});

const HeaderIconWrapper = styled(Avatar)({
  width: '3rem', // Reduce the avatar size
  height: '3rem', // Reduce the avatar size
  backgroundColor: 'white',
  color: "black"
});
const AvatarWrapper = styled(Avatar)({
  backgroundColor: 'secondary',
  width: '36px', // Reduce the avatar size
  height: '36px', // Reduce the avatar size
});

const ListItemWrapper = styled(ListItem)({
  // borderBottom: '1px solid #ccc',
  padding: '8px', // Reduce the padding
});

const ListItemTextWrapper = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px', // Reduce the text width
  },
});

const CardContentWrapper = styled(CardContent)({
  paddingTop: '0px'
})

const RecentProposalsWidget: React.FC = () => {
  // const top5Users = users.slice(0, 5);
  const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);
  const [page, setPage] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(0);
  const navigate = useNavigate();

  const theme = useTheme();
  const { get, put, loading } = useAxios({ showLoader: true });
  const userInfo = getUserInfo();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };
 const getInvoices = async () => {
    // const result: Invoice[] = await get({ url: APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] + 'simple/?is_archived=False' });
    const result: any = await get({
      url:
        APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
        "simple/?is_archived=False",
    });
    let invoicedprojects: any[] = [];
    //get projects whose invoices have been sent
     result.map((pr: any) => {
      if (
        pr.stage === "card_info_saved" ||
        pr.stage === "invoice_sent" ||
        pr.stage === "first_down_payment" ||
        pr.stage === "partial_payment"||
        pr.stage === "fully_paid"
      ) {
        invoicedprojects.push(pr);
      }
    });
    let sortedinvoices = invoicedprojects.sort((a: any, b: any) =>
       new Date(b.invoice_sent_at) > new Date(a.invoice_sent_at) ? 1 : -1
     );
    if (sortedinvoices) {
      sortedinvoices.map((r: any) => {
        r["status"] = r.is_stripe_payment_setup === true ? true : false;
        return r;
      });
    }

    setInvoiceList(sortedinvoices);
    setTotalRows(result.count);
  };

  useEffect(() => {
    getInvoices()
  }, [page])


  const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 10,sortable:true, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1},
  { field: 'client_name', headerName: 'Client Name', width: 150 },
  { field: 'invoice_sent_at', headerName: 'Sent On', width: 100, renderCell:(params:any)=>{
      let sentdate = `${params.row.invoice_sent_at?moment(params.row.invoice_sent_at).format('MM/DD/YYYY'):'-'}`
      return sentdate
    } },   { field: 'project_manager', headerName: 'Project Manager', width: 150},
  {
    field: 'invoice_id', headerName: 'Invoice', width: 100, renderCell: (params: any) => {
        return (<Button size='small' sx={{ height: '25px', background: '#9DECF2', border: 'border: 1px solid #81D0D8', color: '#000', fontFamily: 'VerdanaBold' }}
            variant="contained" onClick={() => navigate(`/preview-invoice/${params?.row?.invoice_id}/`)}>
            Proposal
        </Button>
        )
    },
  },
];

  return (
    <CardWrapper>
      <CardHeaderWrapper
        avatar={
          <HeaderIconWrapper>
            {" "}
            <MarkEmailReadIcon fontSize="large" />{" "}
          </HeaderIconWrapper>
        }
        title={<Typography variant="h5">Recently Sent Proposals</Typography>}
      />
      <CardContentWrapper>
        <ListItemWrapper>
          <div style={{ height: 630, width: 600 }}>
            <StyledDataGrid
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold !important",
                },
              }}
              rows={invoiceList}
              columns={columns}
              pageSize={10}
              onPageChange={(params: any) =>
                handlePageChange(params.event, params.page)
              }
              getRowClassName={(params) =>
                `super-app-theme--${params.row.status}`
              }
              autoPageSize={true}
              rowCount={totalRows}
              rowsPerPageOptions={[5]}
            />
          </div>
        </ListItemWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};

export default RecentProposalsWidget;
