
import React from "react";
import { root1 } from "../..";
import Toast, { ToastProps } from "./Notification";

interface ToastOptions {
  severity: string;
  message: any;
  duration?: number;
}

export class ToastManager {
  private isOpen: boolean;
  private containerRef: HTMLDivElement;

  constructor() {
    this.isOpen = false;
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const toastContainer = document.createElement("div") as HTMLDivElement;
    toastContainer.id = "toast-container-main";
    body.insertAdjacentElement("beforeend", toastContainer);
    this.containerRef = toastContainer;
  }

  public show(options: ToastOptions): void {
    const _options: any = options;
    _options.duration = options?.duration || 30000 
    const toast: any = {
      isOpen: true,
      ..._options,
      handleClose: (data: any) => this.handleClose()
    };
    this.render(toast);
  }

  public hide(): void {
    const toast: any = {
        isOpen: false,
        handleClose: (data: any) => this.handleClose()
      };
    this.render(toast);
  }

  public handleClose = () => {
    const toast: any = {
        isOpen: false,
        handleClose: (data: any) => {}
    };
    this.render(toast);
  }

  private render(props: any): void {
    const toastsList = <Toast {...props} />
    // ReactDOM.render(toastsList, this.containerRef);
    root1.render(toastsList);
  }
}

export const toast = new ToastManager();