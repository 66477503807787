import React, { useEffect } from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export interface ToastProps  {
    id: string;
    isOpen: boolean,
    handleClose: (data: any) => void;
    message: string,
    severity: any,
    duration?: number;
}

function Toast(props: ToastProps) {
    const { isOpen, handleClose, message, severity, duration } = props;

    const _handleClose = () => {
        handleClose(false);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose(false);
        }, duration);
    
        return () => clearTimeout(timer);
      }, [handleClose, duration]);
    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen} autoHideDuration={30000} onClose={() => _handleClose()}>
            <Alert onClose={() => _handleClose()} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Toast;