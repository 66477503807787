import React, { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import { Timer } from '@mui/icons-material';
import './CountdownTimer.scss'; // Import your SCSS file
import moment from 'moment'
interface CountdownTimerProps {
  initialTime: number; // Initial time in seconds
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);

  const checkTimeAndDay = () => {
    const now = moment();
    const currentHour = now.hour();
    const currentDay = now.day();

    // Check if the current time is between 9:00 am and 10:00 pm and it's not a weekend
    if (
      currentHour >= 9 &&
      currentHour <= 17 && // 22 represents 5:00 pm in 24-hour format
      currentDay !== 0 && // Not Sunday
      currentDay !== 6 // Not Saturday
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if(checkTimeAndDay()){
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
        console.log("ticking::")
      }, 1000);
      return () => clearInterval(timer); 
    }

  }, [time]);

  // Calculate minutes and seconds
   
  const minutes = time>0 ? Math.floor(time / 60) : Math.ceil(time / 60) == 0 ? '-' + Math.ceil(time / 60) : Math.ceil(time / 60) ;
  const seconds = time>0 ? time % 60 : time*(-1) % 60;

  return (
    <div className={` countdown-timer ${time < 0 ? ' countdown-timer-over' : ''} ${time < 0 ? ' countdown-timer-over' : ''}`}>
      <Paper elevation={3} className='p-3'>
        <Typography variant="h6" className="timer-title">
          Response Timer
        </Typography>
        <Typography variant="body1" className="time-remaining">
           {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
        </Typography>
      </Paper>
    </div>
  );
};

export default CountdownTimer;
