import { AuthActionType } from "./actionType";

export const loggedIn = (user: any) => ({
    type: AuthActionType.USER_LOGGED_IN,
    data: {
        user
    }
})

export const loggedOut = () => ({
    type: AuthActionType.USER_LOGGED_OUT
})

export const loginFailed = (error: Error) => ({
    type: AuthActionType.USER_LOGIN_FAILED,
    data: {
        error
    }
})

export const setAppContext = (appContext: any) => ({
    type: AuthActionType.SET_APP_CONTEXT,
    data: appContext
})

