import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { TextField, Autocomplete } from '@mui/material';

import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { debounce } from "lodash";

export default function GeoLocation(props: any) {
    const {name, value, onChange, formikProps} = props;

    const {setFieldValue, handleChange} = props?.formikProps;
    const [placeOption, setPlaceOption] = useState([
      "Chandler Studio",
      "Tempe Studio",
    ]);

    // const { ref, autocompleteRef }: any = usePlacesWidget({
    //   apiKey: 'AIzaSyDWI3ZX67Yxx3NyhNNhS6RQXHACwnSp0l0',
    //   libraries: ['places'],
    //   options: {
    //     fields: ["formatted_address", "geometry", "name"],
    //     strictBounds: false,
    //     types: ["establishment"],
    //   },
    //   onPlaceSelected: (place) => {
    //     setFieldValue(name, place.formatted_address);
    //   }
    // });

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
      apiKey: 'AIzaSyDWI3ZX67Yxx3NyhNNhS6RQXHACwnSp0l0',
    });

    useEffect(() => {
      const initialPlaces = ["Chandler Studio", "Tempe Studio", "Scottsdale Studio"];
      if (placePredictions.length > 0) {
        let places = placePredictions.map((item) => item.description)
        // console.log("Place Predict: ", places);
        initialPlaces.push(...places)
      }
      setPlaceOption(initialPlaces);
    }, [placePredictions]);

    const handleLocationChange = (evt:any, val:string) => {
      handleChange({
        target: {
          name: name,
          value: val ?? ""
        }
      })

      onChange(val ?? "");
    }

    const handleLocationInputChange = debounce ((evt:any) => {
      getPlacePredictions({input: evt?.target?.value})
    }, 500);

  return (
      // <TextField
      //     color="secondary"
      //     variant="outlined"
      //     inputRef={ref}
      //     size= "small"
      //     id="country"
      //     name={name}
      //     onChange={handleChange}
      //     value={value}
      //     margin="dense"
      //     label="Location"
      //     multiline
      //   />

      <Autocomplete 
        disablePortal
        id="location-autocomplete"
        value={value ?? null}
        options={placeOption}
        // sx={{width: "300"}}
        onChange={handleLocationChange}
        onInputChange={handleLocationInputChange}
        renderInput={(params) => <TextField
          {...params}
          color="secondary"
          variant="outlined"
          // inputRef={ref}
          size= "small"
          // id="country"
          name={name}
          onChange={handleChange}
          // value={value}
          margin="dense"
          label="Location"
          multiline
        />}/>

      
  );
}