
import { ConfigState } from "./state";
import { Action } from '..';
import { configType } from "./actionType";

export const INITIAL_STATE: ConfigState = {
    isLoader: false,
    rowId: ''
}

export const configReducer = (state = INITIAL_STATE, action: Action) => {
    switch(action.type) {
        case configType.LOADER:
            return {
                ...state,
                isLoader: action.data,
            }
        case configType.ROWID:
            return {
                ...state,
                rowId: action.data,
            }
        default:
            return state;
    }
}