import React, { useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import DynamicTabs from '../../shared/tabs/Tabs';
import { FormControl, TextField, FormHelperText, Select, MenuItem, InputLabel } from '@mui/material';
import { NUMBER_10, _items } from './modal';
import { TABS_NUM } from '../../utils/utils';




import CustomForm from './CustomForm';

interface CustomSectionProps {
    formikProps: any
}


export default function CustomSection(props:CustomSectionProps){
const { formikProps } = props;

  const { handleChange, setFieldValue, values, errors, touched } = formikProps;

  const handleCustomsChange = (e: any) => {
      const items: number = Number(e?.target?.value);
      let data: any = values?.custom?.number_of_items;
      const remaining_items = items - data?.length;
      if (remaining_items > 0) {
          TABS_NUM(remaining_items || 0)
              .forEach(() => data.push(_items))

          setFieldValue('custom.number_of_items', data);
      };
      if (items < data?.length && data.length > 0) {
          const updated_items = data.slice(0, items);
          setFieldValue(`custom.number_of_items`, updated_items);
      }
      setFieldValue('custom.total_items_needed', Number(items))
  }


return(
<>
  <div>
    <div className='row m-0 mb-2 mt-3'>
      <div className='col-6 p-0'>
          <FormControl variant="outlined" size='small' fullWidth>
              <InputLabel id="total_items_needed">Total Items Needed</InputLabel>
              <Select
                  labelId="total_items_needed"
                  id="total_items_needed"
                  name={`custom.total_items_needed`}
                  value={values.custom.total_items_needed}
                  onChange={handleCustomsChange}
                  label="Total Items Needed"
              >
                 {
                    NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                  }
              </Select>
              <FormHelperText>
                  {(errors?.custom?.total_items_needed &&
                      touched?.custom?.total_items_needed) && errors?.custom?.total_items_needed
                  }
              </FormHelperText>
          </FormControl>
      </div>
  </div>
  </div>
  <div className='row m-0 d-tabs'>
        {(values.custom.total_items_needed && values.custom.total_items_needed > 0) ?
            <DynamicTabs 
                tabNumber={values.custom.total_items_needed}
                formikProps={formikProps} 
                label={'Item'}
                key={'number_of_items'}
                components={CustomForm}
                formName={'custom'}
            />
            : null
        }
</div>
</>
)

}
