import React, { useEffect, useMemo, useState } from "react";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, Select, MenuItem, FormHelperText, TextField } from "@mui/material";
import GeoLocation from "../../shared/location/GeoLocation";
import moment from 'moment';
import DateInput from '../../shared/date/DateInput';
import { NUMBER_10, _talent_day } from './modal';
import { TABS_NUM } from "../../utils/utils";
import TalentDetail from "./TalentDetail";

function TalentForm(props: any) {
    const { index, formikProps } = props;
    const { handleChange, setFieldValue, values, errors, touched } = formikProps;

    useEffect(() => {
        let data: any = values.talents?.days;
        if (!data[index]) {
            data[index] = _talent_day;
            setFieldValue('talents.days', data);
        }
        console.log("Set Talent Day: ", data);
    }, [index]);

    const handleCoordinatorChange = (evt:any) => {
        const isChecked = evt.target.checked;
        console.log("Is Checked Coordinator :", isChecked);
        setFieldValue(`talents.days[${index}].coordinator`, isChecked)
    }

    return (
        values.talents.days[index] && (
            <div>
                <div className="row border-bottom mb-2 pb-2">
                    <div className="col-4">
                        <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                        <DateInput 
                            name={`talents.days[${index}].date_of_shoot`}
                            value={values.talents.days[index].date_of_shoot}
                            label={`Date Of Shoot`}
                            handleChange={(date: string) => {
                                // setFieldValue(`post_production.edit[${index}].deadline_of_initial_delivery`, date)
                                handleChange({
                                    target: {
                                        name: `talents.days[${index}].date_of_shoot`,
                                        value: date
                                    }
                                })
                            }}
                            helperText={(errors?.talents?.days[index]?.date_of_shoot &&
                                touched?.talents?.days[index]?.date_of_shoot) &&
                                errors?.talents?.days[index]?.date_of_shoot
                            } 
                        />
                        </FormControl>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel id="aspect">Number of Talents Needed</InputLabel>
                            <Select
                                labelId="aspect"
                                id="aspect"
                                name={`talents.days[${index}].num_of_talents_needed`}
                                value={values.talents.days[index].num_of_talents_needed}
                                onChange={handleChange}
                                label="Number of Talents Needed"
                            >
                                {/* <MenuItem value={"Tempe Studio"}>Tempe Studio</MenuItem> */}
                                {
                                    NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                                }
                            </Select>
                            <FormHelperText>
                                {(errors?.talents?.days[index]?.location ||
                                touched?.talents?.days[index]?.location) &&
                                errors?.talents?.days[index]?.location}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        <FormControlLabel
                            // className="mt-4"
                            control={
                                <Checkbox
                                id="new_client"
                                // name={`talents.days[${index}].coordinator`}
                                checked={values?.talents?.days[index].coordinator}
                                onChange={handleCoordinatorChange}
                                // checked={applyDetails}
                                size="small"
                                />
                            }
                            label="Coordinator"
                        />
                    </div>
                </div>
                {
                    TABS_NUM (values.talents.days[index].num_of_talents_needed || 0).map((key:any, idx:number) => {
                        console.log("Index: ", idx);
                        // const talent_package = values?.talents?.days[index]?.package[idx];
                        return (
                            <TalentDetail 
                                dayIndex={index} 
                                talentIndex={idx}
                                formikProps={formikProps} 
                                key={`talent_detail_${idx}`}
                            />
                        );
                    })
                }
            </div>
        )
    );
}

export default TalentForm;