import React, { lazy, Suspense, useMemo } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Client from "../pages/Clients/Client";
import Payment from "../pages/Payment";
import Project from "../pages/Project/Project";
import Template from "../pages/Template/Template";
import RegisterUser from "../pages/UserManagement/RegisterUser";
import Header from "../shared/header";
import Unauthorized from "../shared/Unauthorized";
import { useAppStateContext } from "../state/provider";
import {
  getToken,
  getUserInfo,
  USER_ACCESS,
  USER_ACCESS_AUTH
} from "../utils/utils";
import Protected from "./Protected";
import UserManagement from "../pages/UserManagement/UserManagement";
import CreateInvoice from "../pages/Invoice";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import GenerateInvoice from "../pages/Clients/GenerateInvoice";
import ProjectEnquireResponse from "../pages/Managers/ProjectEnquireResponse";
import Home from "../pages/Home";
import Checkout from "../pages/Checkout";
import PricingManager from "../pages/Pricing";

// const Login = lazy(() => import('../pages/Login/Login'));
// const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
// const Invoice = lazy(() => import('../pages/Invoice/Invoice'));
// const UserManagement = lazy(() => import('../pages/UserManagement/UserManagement'));

function Routers(props: any) {
  const { state, dispatch } = useAppStateContext();
  const { isAuthenticated } = state.auth;
  const path = window?.location?.pathname;
  const userInfo = state.auth?.user || getUserInfo();
  const navigate = useNavigate();

  const isAuthorizedByRole = (path: any) => {
    const userAccess: any = USER_ACCESS_AUTH[userInfo?.role] || [];
    if (userAccess.includes(path) || userAccess[0] === "all" || path === "/") {
      return true;
    }
    return false;
  };

  const isAuthorized = useMemo(() => isAuthorizedByRole(path), [path]);
  // const isAuthorized = isAuthorizedByRole(path);
  const _isAuthenticated = isAuthenticated || getToken();

  if (window?.location?.pathname === "/login" && getToken()) {
    return <Navigate to={"/clients"} replace />;
  }
  return (
    <>
      {_isAuthenticated && <Header />}
      <Routes>
        <Route
          path="/"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Client />
            </Protected>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register/:uid" element={<RegisterUser />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* <Route path="/home" element={<Protected isAuthenticated={_isAuthenticated}
                    isAuthorized={isAuthorized}><Dashboard /></Protected>} /> */}
        <Route
          path="/home"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Home />
            </Protected>
          }
        />
        <Route
          path="/templates"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Template />
            </Protected>
          }
        />
        <Route
          path="/pricing"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <PricingManager />
            </Protected>
          }
        />
        <Route
          path="/clients"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Client />
            </Protected>
          }
        />
        <Route
          path="/payments"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Payment />
            </Protected>
          }
        />
        <Route
          path="/projects"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <Project />
            </Protected>
          }
        />
        <Route
          path="/usermanagement"
          element={
            <Protected
              isAuthenticated={_isAuthenticated}
              isAuthorized={isAuthorized}
            >
              <UserManagement />
            </Protected>
          }
        />
        <Route
          path="/project-inquiry-response/:client_id/:manager_email/:manager_id/:project_id"
          element={
            <Protected isAuthenticated={_isAuthenticated} isAuthorized={true}>
              <ProjectEnquireResponse />
            </Protected>
          }
        />
        {/* <Route path="/invoice" element={<CreateInvoice />} />
                    <Route path="/preview-invoice" element={<GenerateInvoice />} />
                    <Route path="/preview-invoice/:id" element={<GenerateInvoice />} />
                    <Route path="/invoice/:id/:clientId" element={<CreateInvoice />} /> */}
        <Route
          path="/invoice"
          element={
            <Protected isAuthenticated={_isAuthenticated} isAuthorized={true}>
              <CreateInvoice />
            </Protected>
          }
        />
        <Route
          path="/preview-invoice"
          element={
            <Protected isAuthenticated={_isAuthenticated} isAuthorized={true}>
              <GenerateInvoice />
            </Protected>
          }
        />
        <Route
          path="/preview-invoice/:id"
          element={
            <Protected isAuthenticated={_isAuthenticated} isAuthorized={true}>
              <GenerateInvoice />
            </Protected>
          }
        />
        <Route
          path="/invoice/:id/:clientId"
          element={
            <Protected isAuthenticated={_isAuthenticated} isAuthorized={true}>
              <CreateInvoice />
            </Protected>
          }
        />
        <Route path="/checkout*" element={<Checkout />} />
      </Routes>
    </>
  );
}

export default Routers;
