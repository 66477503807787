import React, { useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { useParams, } from 'react-router-dom';

interface DiscountProps {
    invoiceDetails: any,
}

function DiscountForm (props: DiscountProps) {

    const { invoiceDetails } = props;
    const { put } = useAxios({showLoader: true});
    const { id } = useParams();
    const [hasDiscount, setHasDiscount] = useState(false);

    useEffect(() => {
        checkInvoiceHasDiscount();
    }, [invoiceDetails]);

    const handleDiscountChange = () => {
        if (hasDiscount) {
            updateInvoiceDiscount(false);
            setHasDiscount(false);
        } else {
            updateInvoiceDiscount(true);
            setHasDiscount(true);
        }
    }

    const updateInvoiceDiscount = async (discount=false) => {
        const requestdate = {
            add: discount
        }

        try {
            const result: any = await put({ 
                url: APIMETHOD.INVOICE.DISCOUNT.update + id,
                data: requestdate 
            });
    
            // console.log("Discount Update Result: ", result);
            if (result.success) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }

    }

    const checkInvoiceHasDiscount = () => {
        if (invoiceDetails?.invoice?.discount > 0) {
            setHasDiscount(true);
        } else {
            setHasDiscount(false);
        }
    }

    return (
        <div className="bg-white py-3 px-3 mt-4">
            <FormControlLabel
                className="mt-4"
                control={
                    <Checkbox
                        id="discount"
                        // name="client_details.new_client"
                        onChange={handleDiscountChange}
                        checked={hasDiscount}
                        size="small"
                    />
                }
                label="Discount?"
            />
        </div>
    );
}

export default DiscountForm;