import React, { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface FieldProps {
    name: string,
    value: string,
    label: string,
    helperText: boolean,
    handleChange: any,
}

function DateInput (props: FieldProps) {
    const { name, handleChange, value, label, helperText } = props;

    const handleDateChange = (newValue: any) => {
        const date = dayjs (newValue).format("YYYY-MM-DD");
        handleChange(date);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField 
                name={name}
                label={label} 
                onChange={handleDateChange}
                value={dayjs(value ?? "")}
                format="MM/DD/YY"
                slotProps={{
                    textField: {
                        helperText: helperText,
                    },
                }}
                margin="dense"
                size="small" 
            />
        </LocalizationProvider>
    );
}

export default DateInput;