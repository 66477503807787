import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import ClientDetails from "./ClientDetails";
import "./Invoice.scss";
import {
  initialInvoiceForm,
  mapCreateClientRequest,
  mapInvoiceRequest,
  mapInvoiceResponse,
} from "./modal";
import ProductionDetails from "./ProductionDetails";
import PreProduction from "./PreProduction";
import PostProduction from "./PostProduction";
import { DisplayingErrorMessagesSchema } from "./Validation";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import { getTimeDifference, getUserInfo } from "../../utils/utils";
import LoadingService from "../../shared/loader";
import Status_Dialog from "../../shared/Dialog/Status_Dialog";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "../../shared/Toser/ToastManager";
import CountdownTimer from "../Managers/CountdownTimer";
import RentalLocation from "./RentalLocations";
import CustomSection from "./Custom";
import DiscoveryScript from "./DiscoveryScript";
import Modal from "@mui/material/Modal";
import { useConfirm } from "material-ui-confirm";
import Talent from "./Talent";

const accodianStyle = {
  background: "#23BAD1 0% 0% no-repeat padding-box",
  borderRradius: "4px",
  minHeight: "2.5rem",
  height: "2.5rem",
  color: "#fff",
};

let ERROR_SUCCESS_MSG = "Invoice Created Successfully !!";
let InvoiceData: any;

const ERROR_DATA: any = {
  email: true,
};
export const style: any = {
  secondary: {
    background: "#F3F3F3",
    boxShadow: "0px 2px 5px #0000000D",
    border: "1px solid #E5E5E5",
    borderRadius: "4px",
    color: "#000",
    px: 3,
    fontFamily: "VerdanaBold",
  },
  submit: {
    background:
      "transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 10px 20px #0000000D",
    borderRadius: "4px",
    opacity: 1,
    color: "#ffffff",
    px: 3,
    fontFamily: "VerdanaBold",
  },
};

function CreateInvoice(props: any) {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [apiError, setApiError] = useState<any>(null);
  const { post, error, loading, get, patch, put } = useAxios({
    showLoader: true,
  });
  const [isSuccesError, setIsSuccessError] = useState(false);
  const [newClientId, setNewClientId] = useState<any>("");
  const [_initialInvoiceForm, setInitialInvoiceForm] =
    useState<any>(initialInvoiceForm);
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const { id, clientId } = useParams();
  const [invoice_id, setInvoiceId] = useState<any>("");
  // const [timerVisiblity, setTimerVisiblity] = useState<boolean>(false)
  const [timeReamin, setTimeReamin] = React.useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [lowAmount, setLowAmount] = useState(1);
  const [upcharge, setupcharge] = useState(0);
  useEffect(() => {
    if (InvoiceData?.invoice?.up_charge !== undefined) {
      setupcharge(InvoiceData.invoice.up_charge);
    }
  }, [InvoiceData?.invoice?.up_charge]);
  const [minShotWithPackageArr, setMinShotWithPackageArr] = useState([]);
  const confirm = useConfirm();
  
  useEffect(() => {
    if (error) {
      const _errors: any = error?.exactErr?.invoice;
      console.log(_errors, "errrr");
      if (_errors && !_errors?.detail) {
        setApiError(_errors);
      } else if (error?.exactErr?.email) {
        toast.show({ severity: "error", message: error?.exactErr?.email[0] });
      } else {
        if (error?.message === "Low amount") {
        } else {
          toast.show({ severity: "error", message: error?.message });
        }
      }
      if (error?.exactErr?.check_low_ammount) {
        setIsOpen(true);
      }
    }
  }, [error]);
  useEffect(() => {
    if (id && id != "0") {
      const getInvoice = async () => {
        const result: any = await get({
          url:
            APIMETHOD.INVOICE.CREATE_INVOICE[userInfo.role] +
            id +
            "/form-detail/",
        });
        if (result) {
          InvoiceData = result;
          result.client.new_client = false;

          const mapedData: any = mapInvoiceResponse(result);

          if (clientId === "clone") {
            mapedData.client_details.project_name =
              "Clone - " + mapedData.client_details.project_name;
          }

          // if(clientId === 'clone') {
          //     mapedData.client_details.project_name = '';
          //     mapedData.client_details.name = '';
          //     mapedData.client_details.email = '';
          //     mapedData.client_details.phone = '';
          // }

          setInitialInvoiceForm(mapedData);
        }
      };
      getInvoice();
    } else {
      setInitialInvoiceForm(initialInvoiceForm);
      const getClientData = async () => {
        const clientData: any = await get({
          url: APIMETHOD.DETAILS.CLIENTS[userInfo.role] + clientId,
        });
        if (clientData.response_time == null) {
          const differenceInSeconds = getTimeDifference(clientData.updated_at);
          setTimeReamin(1800 - differenceInSeconds);
        }
      };
      if (id === "0") {
        getClientData();
      }
    }
  }, [id]);
  const handleCloseModal = () => {
    setIsOpen(false); // Close the modal
  };

  const handlePanelChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  // Object.values(errors.client_details).forEach(errorMessage => {
  //           toast.show({severity: "error", message: errorMessage});
  //         });
  const handleSubmit = async (val: any) => {
    const requestData: any = mapInvoiceRequest(Object.assign({}, val));
    const has_min_word = checkShotWithPackageFieldsHaveMinWords(requestData, 50);
    console.log("Request Data: ", requestData);
    // return

    if (has_min_word) {
      confirm({
        title: "Confirmation",
        confirmationText: "Continue",
        cancellationText: "Edit",
        description:
          "The Filming Details Description Has Less Than 50 Characters, Please Add More Filming Details Before Submitting.",
      })
        .then(() => {
          console.log("Saved Invoice");
          _doInvoiceSave(val, requestData);
        })
        .catch((err) => console.error(err));
    } else {
      _doInvoiceSave(val, requestData);
    }
  };

  const _doInvoiceSave = async (val: any, requestData: any) => {
    try {
      if (id && clientId !== "clone" && id != "0") {
        editInvoice(requestData, id);
      } else {
        if (val?.client_details?.list === "--ADD NEW--" && !newClientId) {
          const request: any = {
            name: val?.client_details?.name,
            email: val?.client_details?.email,
            phone: val?.client_details?.phone,
          };
          const newClient = await post({
            url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role],
            data: request,
          });
          if (newClient) {
            setNewClientId(newClient.id);
            requestData.client_details.id = newClient.id;
            createProjectInvoice(requestData);
          }
        } else {
          createProjectInvoice(requestData);
        }
      }

    } catch (error) {
      console.error("_doInvoiceSave Error: ", error);
    }
  };

  const createProjectInvoice = async (requestData: any) => {
    // const projects = await createProjects(mapCreateClientRequest(requestData.client_details));

    const CreateRequest = {
      project: {
        name: requestData.client_details?.project_name,
        category: "video",
        filming_days: requestData.client_details?.filming_days_num ?? 0,
      },
      client: {
        name: requestData.client_details?.name,
        email: requestData.client_details?.email,
        phone: requestData.client_details?.phone,
        new_client: requestData.client_details?.new_client,
      },
      invoice: requestData,
      proceed_with_low_amount: lowAmount,
      up_charge: upcharge,
      _type: searchParams.get("type") === null ? null : searchParams.get("type") ,
    };

    // requestData.project_id = projects.project_id;
    const result: any = await post({
      url: APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] + "create/",
      data: CreateRequest,
    });

    if (result) {
      setInvoiceId(result?.invoice?.id);
      // setIsSuccessError(true);
      navigate("/preview-invoice/" + result?.invoice?.id);
      const a = result;
      setApiError(null);
    }
  };

  const createProjects = async (clientDetails: any) => {
    return await post({
      url: APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo?.role],
      data: clientDetails,
    });
  };

  const editInvoice = async (requestData: any, id: any) => {
    const editRequest = {
      client: {
        ...InvoiceData.client,
        new_client: requestData.client_details?.new_client,
      },
      invoice: requestData,
      proceed_with_low_amount: lowAmount,
      up_charge: upcharge,
      _type: searchParams.get("type"),
      project: {
        ...InvoiceData.project,
        filming_days: requestData?.production?.no_of_filming_days ?? 0,
        client: clientId,
        name: requestData?.client_details?.project_name,
      },
    };
    const result: any = await put({
      url: APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] + id + "/update/",
      data: editRequest,
    });
    if (result) {
      setInvoiceId(result?.invoice?.id);
      ERROR_SUCCESS_MSG = "Invoice Edited Successfully !!";
      navigate("/preview-invoice/" + result?.invoice?.id);
      // setIsSuccessError(true);
      const a = result;
      setApiError(null);
    }
  };

  const getShortWithPackageMinWordArr = (obj: any, index: number) => {
    console.log(`Shot With Package ${index}: `, obj);
    setMinShotWithPackageArr((prev: any) => {
      // prev[`day${index}`][obj.index] = obj.isMin;
      return prev;
    });
  };
  const checkShotWithPackageFieldsHaveMinWords = (requestData: any, numOfCharater:Number=100) => {
    const production = requestData.production;
    const total_filming_days = production.no_of_filming_days;
    let has_min_word = false;

    for (let i = 0; i < total_filming_days; i++) {
      const day = production[`day_${i + 1}`];
      const crew_package_needed = day.crew_package_needed;
      for (let crew_num = 0; crew_num < crew_package_needed; crew_num++) {
        const cp = day[`crew_package_${crew_num + 1}`];
        console.log(`Crew Package ${crew_num+1} num of characters: `, cp.shot_with_this_package.length)
        const wordCount = cp.shot_with_this_package
          // .split(" ")
          // .filter((n: any) => {
          //   return n != "";
          // });
        if (wordCount.length < numOfCharater) {
          has_min_word = true;
          break;
        }
      }
    }

    return has_min_word;
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsSuccessError(false);
    navigate("/preview-invoice/" + invoice_id);
  };
  const onlyNumbers = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key;
      const keyCode = e.keyCode || e.which;
      // Allow numeric characters (0-9) and the backspace key
      if (!/^[0-9.]+$/.test(key) && keyCode !== 8) {
        e.preventDefault();
      }
    },
    []
  );
  const pageTitle = () => {
    switch (searchParams.get("type")) {
      case "pre_production":
        return "Project Planning Only";
      case "post_production":
        return "Post Production Only";
      case "talents":
        return "Talent Only";
      case "rentals":
        return "Studio Only";
      case "custom":
        return "Custom Only";
      default:
        return "New Project Proposal";
    }
  };

  const amount =
    (parseFloat(error?.exactErr?.total) * upcharge) / 100 +
    parseFloat(error?.exactErr?.total);

  const totalAmount = isNaN(amount) ? 0 : parseFloat(amount.toFixed(2));

  return (
    <div className="container">
      <LoadingService open={loading} />
      <Status_Dialog
        isOpen={isSuccesError}
        message={ERROR_SUCCESS_MSG}
        title={"Success"}
        handleClose={handleClose}
      />
      <div className="row justify-content-center">
        <Box sx={{ textAlign: "center", fontFamily: "VerdanaBold", p: 2 }}>
          {pageTitle()}
        </Box>
        {/* <Box sx={{ textAlign: "center", fontFamily: "VerdanaBold", p: 2 }}>
            Create New Invoice Form
          </Box> */}

        <div className="col-sm-12 invoice-container px-4">
          {(searchParams.get("type") == undefined ||
            searchParams.get("type") == null) && <DiscoveryScript />}
          <Formik
            enableReinitialize
            initialValues={_initialInvoiceForm}
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values, { setSubmitting }) => {
              // handleSubmit(values);
              // setSubmitting(true);

              handleSubmit(values);
            }}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                isValid,
                setFieldValue,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                    <TextField
                      name="private_notes"
                      type={"text"}
                      variant="outlined"
                      label="Private Note"
                      onChange={handleChange}
                      value={values?.private_notes || ""}
                      margin="dense"
                      size="small"
                      multiline
                      rows={10}
                      helperText={
                        errors?.private_notes &&
                        touched?.private_notes &&
                        errors?.private_notes
                      }
                    />
                  </FormControl>
                  <ClientDetails formikProps={props} />
                  {(searchParams.get("type") === "production" ||
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" ||
                    !searchParams.has("type")) && (
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handlePanelChange("panel1")}
                      sx={{ mb: 2 }}
                    >
                      <AccordionSummary
                        className="accordionHeader"
                        sx={
                          errors?.production_details || apiError?.production
                            ? {
                                ...accodianStyle,
                                ...{ backgroundColor: "#dc3545" },
                              }
                            : accodianStyle
                        }
                        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      >
                        <Typography>Production</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {apiError?.production && (
                          <small className="errors_color">
                            {apiError?.production[0]}
                          </small>
                        )}
                        <ProductionDetails
                          formikProps={props}
                          getShortWithPackageMinWordArr={
                            getShortWithPackageMinWordArr
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {(searchParams.get("type") === "post_production" ||
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" ||
                    !searchParams.has("type")) && (
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handlePanelChange("panel2")}
                      sx={{ mb: 2 }}
                    >
                      <AccordionSummary
                        className="accordionHeader"
                        sx={
                          errors?.post_production || apiError?.post_production
                            ? {
                                ...accodianStyle,
                                ...{ backgroundColor: "#dc3545" },
                              }
                            : accodianStyle
                        }
                        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      >
                        <Typography>Post-Production</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {apiError?.post_production && (
                          <small className="errors_color">
                            {apiError?.post_production[0]}
                          </small>
                        )}
                        <PostProduction formikProps={props} />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {(searchParams.get("type") === "pre_production" ||
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" ||
                    !searchParams.has("type")) && (
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handlePanelChange("panel3")}
                      sx={{ mb: 2 }}
                    >
                      <AccordionSummary
                        className="accordionHeader"
                        sx={
                          errors?.pre_production || apiError?.pre_production
                            ? {
                                ...accodianStyle,
                                ...{ backgroundColor: "#dc3545" },
                              }
                            : accodianStyle
                        }
                        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      >
                        <Typography>Project Planning</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {apiError?.pre_production && (
                          <small className="errors_color">
                            {apiError?.pre_production[0]}
                          </small>
                        )}
                        <PreProduction
                          formikProps={props}
                          isExpanded={expanded === "panel3" ? 1 : 0}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {(searchParams.get("type") === "talents" || 
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" || 
                    !searchParams.has("type")) && (
                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={handlePanelChange("panel6")}
                        sx={{ mb: 2 }}
                      >
                        <AccordionSummary
                          className="accordionHeader"
                          sx={
                            errors?.talents || apiError?.talents
                              ? {
                                  ...accodianStyle,
                                  ...{ backgroundColor: "#dc3545" },
                                }
                              : accodianStyle
                          }
                          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                        >
                          <Typography>Talents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {apiError?.talents && (
                            <small className="errors_color">
                              {apiError?.talents[0]}
                            </small>
                          )}
                          <Talent formikProps={props} />
                        </AccordionDetails>
                      </Accordion>
                  )}
                  {(searchParams.get("type") === "rentals" ||
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" ||
                    !searchParams.has("type")) && (
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handlePanelChange("panel4")}
                      sx={{ mb: 2 }}
                    >
                      <AccordionSummary
                        className="accordionHeader"
                        sx={
                          errors?.rentals || apiError?.rentals
                            ? {
                                ...accodianStyle,
                                ...{ backgroundColor: "#dc3545" },
                              }
                            : accodianStyle
                        }
                        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      >
                        <Typography>Studios</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {apiError?.rentals && (
                          <small className="errors_color">
                            {apiError?.rentals[0]}
                          </small>
                        )}
                        <RentalLocation formikProps={props} />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {(searchParams.get("type") === "custom" ||
                    searchParams.get("type") === "all" ||
                    searchParams.get("type") === "null" ||
                    !searchParams.has("type")) && (
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handlePanelChange("panel5")}
                      sx={{ mb: 2 }}
                    >
                      <AccordionSummary
                        className="accordionHeader"
                        sx={
                          errors?.custom || apiError?.custom
                            ? {
                                ...accodianStyle,
                                ...{ backgroundColor: "#dc3545" },
                              }
                            : accodianStyle
                        }
                        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      >
                        <Typography>Custom</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {apiError?.custom && (
                          <small className="errors_color">
                            {apiError?.custom[0]}
                          </small>
                        )}
                        <CustomSection formikProps={props} />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <div className="d-flex w-100 justify-content-between">
                    <div className="flex-grow-6 d-flex align-items-center justify-content-start">
                      <div>
                        <Button
                          type="button"
                          className="my-3 text-uppercase secondry"
                          variant="contained"
                          onClick={() => navigate(-1)}
                          sx={{
                            background: "#F3F3F3",
                            boxShadow: "0px 2px 5px #0000000D",
                            border: "1px solid #E5E5E5",
                            color: "#000",
                            fontFamily: "VerdanaBold",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="mx-2 my-3 px-5 text-uppercase gradientRoseYellowBtn"
                          variant="contained"
                          onClick={() => {
                            const client_details_error = errors?.client_details ?? null;
                            if (!!client_details_error) {
                              Object?.values(client_details_error).forEach(
                                (errorMessage) => {
                                  toast.show({
                                    severity: "error",
                                    message: errorMessage,
                                  });
                                }
                              );
                            }
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Dialog
                        PaperProps={{
                          style: {
                            borderRadius: 28,
                            border: "1px solid #D0D0D0",
                            boxShadow: "0px 20px 30px #0000000D",
                          },
                        }}
                        open={isOpen}
                        onClose={handleClose}
                        fullWidth={true}
                      >
                        <DialogContent>
                          <Typography className="p-2 fw-bold">
                            Are You Sure you want to Proceed.
                          </Typography>

                          <Box>
                            <FormControl
                              variant="standard"
                              sx={{ p: 1 }}
                              fullWidth
                            >
                              <Typography className="p-2 fw-bold">
                                Percentage Upcharge
                              </Typography>
                              <TextField
                                // name={InvoiceData?.invoice?.up_charge}
                                variant="outlined"
                                label="Upcharge"
                                onChange={(event: any) => {
                                  setupcharge(event.target.value);
                                }}
                                onKeyDown={onlyNumbers}
                                value={upcharge}
                                margin="dense"
                                size="small"
                              />

                              <Typography className="p-2 d-flex flex-row">
                                Total: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Typography className=" fw-bold">
                                  {error?.exactErr?.total}
                                </Typography>
                              </Typography>
                              <Typography className="p-2 d-flex flex-row">
                                Percentage
                                Upcharge:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Typography className=" fw-bold">
                                  {upcharge}
                                </Typography>
                              </Typography>
                              <Typography className="p-2 d-flex flex-row">
                                New
                                Total:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Typography className=" fw-bold">
                                  {totalAmount}
                                </Typography>
                              </Typography>
                            </FormControl>
                          </Box>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            borderTop: "1px solid #D0D0D0",
                            justifyContent: "left",
                            px: 4,
                            py: 2,
                          }}
                        >
                          <Button
                            sx={style.secondary}
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </Button>

                          <Button
                            sx={style.submit}
                            onClick={() => {
                              handleSubmit(setLowAmount(2));
                            }}
                            disabled={upcharge <= 0}
                          >
                            Save
                          </Button>
                          <Button
                            sx={[
                              style.submit,
                              { marginLeft: "5vmax", backgroundColor: "red" },
                            ]}
                            onClick={() => {
                              handleSubmit(setLowAmount(0));
                            }}
                          >
                            Continue Without Upcharge
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    {timeReamin ? (
                      <div className="d-flex align-items-center justify-content-end p-2 mt-2">
                        <CountdownTimer initialTime={timeReamin} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CreateInvoice;
