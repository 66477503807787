import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import LoadingService from '../../../shared/loader';
import moment from 'moment';
import { useAxios } from '../../../core/useAxios';
import { APIMETHOD } from '../../../core/constant';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }
    
}

interface UserDiaglogProps {
    isOpen: boolean,
    paymentSchedule: any,
    invoiceNumber: any,
    newUpdate: (data: any) => any,
    paymentScheduleIndex: any,
    handleClose: (data: any) => void
}

function UpdatePaymentSchedule(props: UserDiaglogProps) {
    const { isOpen, handleClose, newUpdate, paymentSchedule, paymentScheduleIndex, invoiceNumber } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ date, setDate ] = useState('');
    const [ paymentScheduleId, setPaymentScheduleId ] = useState(null);
    const { put } = useAxios({showLoader: true});
    const { id } = useParams();

    useEffect(() => {
        const ps = paymentSchedule[paymentScheduleIndex];
        console.log("Payment Schedule Index: ", ps);
        // set current payment schedule id and date
        setPaymentScheduleId(ps.id);
        const currentDateFormat = moment(ps?.payment_date).format('YYYY-MM-DD');
        console.log("Payment Current Date: ", currentDateFormat)
        setDate(currentDateFormat);
    }, [paymentSchedule, paymentScheduleIndex])

    const handleSubmit = async () => {
        const inputDate = moment(date+" 11:55").toISOString()
        // console.log("Invoice Number: ", id);
        
        if (paymentScheduleId != null) {
            const requestdate = {
                payment_date: inputDate
            }
    
            const result: any = await put({ 
                url: APIMETHOD.INVOICE.PAYMENT_SCHEDULE + paymentScheduleId, 
                data: requestdate 
            });
    
            console.log("Payment Update Result: ", result);
            setPaymentScheduleId(null);
            setDate('');
            newUpdate(result);
            handleCloseTask();
        }
    }

    const handleCloseTask = () => {
        handleClose(false);
    };

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog PaperProps={{style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' }   }} open={isOpen} onClose={handleCloseTask} fullWidth={true}>
                <DialogContent>
                    <h5 className='p-2'>Update Payment Schedule</h5>
                                
                    <Box>
                        <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                            <TextField
                                margin="dense"
                                id="schedule_date"
                                name='schedule_date'
                                type="date"
                                value={`${date}`}
                                onChange={(e: any) => {setDate(e.target.value)}}
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    autoComplete: "off", // Disable autocomplete
                                }}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2}}>
                    <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
                    <Button sx={style.submit} onClick={() => {handleSubmit()}}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdatePaymentSchedule;