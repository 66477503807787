import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import AccordianVoyage from '../../shared/accordian/AccordianVoyage';
import { TABS_NUM } from '../../utils/utils';
import { NUMBER_10, _days } from './modal';
import { _editData } from './modal';


function CustomForm(props: any) {
    const { index, formikProps } = props;
    const { handleChange, setFieldValue, values, errors, touched } = formikProps;
    

    useEffect(() => {
        let data: any = values.custom.number_of_items;
        if (!data[index]) {
            data[index] = _editData;
            setFieldValue('custom.number_of_items', data);
        }
    }, [index]);
    return (
        <> {values.custom.number_of_items[index] &&
            <div className='row' key={index + 'custom'}>
                <div className='col-6'>
                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`custom.number_of_items[${index}].item_name`}
                            type={'text'}
                            variant="outlined"
                            label="Item Name"
                            onChange={handleChange}
                            value={values.custom?.number_of_items[index]?.item_name}
                            margin="dense"
                            size="small"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            helperText={(errors?.custom?.number_of_items[index]?.item_name ||
                            touched?.custom?.number_of_items[index]?.item_name) &&
                            errors?.custom?.number_of_items[index]?.item_name
                        }
                        />
                    </FormControl>
                </div>
                <div className='col-6'>
                    <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`custom.number_of_items[${index}].price`}
                            type={'number'}
                            variant="outlined"
                            label="Item Price"
                            onChange={handleChange}
                            value={values.custom?.number_of_items[index]?.price}
                            margin="dense"
                            size="small"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            helperText={(errors?.custom?.number_of_items[index]?.price ||
                            touched?.custom?.number_of_items[index]?.price) &&
                            errors?.custom?.number_of_items[index]?.price}
                        />
                    </FormControl>
                </div>
                <div className='col-12'>
                    <FormControl variant="outlined" sx={{ mb: 1 }} fullWidth>
                        <TextField
                            name={`custom.number_of_items[${index}].item_description`}
                            type={'text'}
                            variant="outlined"
                            label="Item Description"
                            multiline
                            rows={3}
                            onChange={handleChange}
                            value={values.custom?.number_of_items[index]?.item_description}
                            margin="dense"
                            size="small"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            helperText={(errors?.custom?.number_of_items[index]?.item_description ||
                            touched?.custom?.number_of_items[index]?.item_description) &&
                            errors?.custom?.number_of_items[index]?.item_description
                        }
                        />
                    </FormControl>
                </div>
            </div>
        }
        </>
    );
}

export default CustomForm;