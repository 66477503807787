import { configType } from "./actionType";


export const _LoaderService = (isLoader: boolean) => ({
    type: configType.LOADER,
    data: {
        isLoader
    }
})

export const setRowId = (rowId: any) => ({
    type: configType.ROWID,
    data: rowId
})


// export const setAppContext = (appContext: any) => ({
//     type: AuthActionType.SET_APP_CONTEXT,
//     data: appContext
// })

