import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button, Box, Switch, FormControlLabel, IconButton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './UserManagement.css';
import { ROWS } from './model';
import AddUser from './AddUser';
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { toast } from '../../shared/Toser/ToastManager';
import LoadingService from '../../shared/loader';
import {GridToolbar} from "@mui/x-data-grid-pro";
let gridData: any[] = [];

const USER_ROLE: any = {
    project_manager: 'Project Manager',
    admin: 'Admin'
}

// const rows = ROWS;

const UserManagement = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const {get, error, patch, loading} = useAxios({showLoader: true});
    const [userList, setUserList] = useState<any[]>([]);

    const columns = [
        { field: 'full_name', headerName: 'Name', flex: 1, fontWeight: 600},
        { field: 'role', headerName: 'Role', flex: 1, renderCell: (params: any) => USER_ROLE[params?.value]},
        { field: 'email', headerName: 'Email', flex: 1 },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1
        }, {
            field: 'is_active',
            headerName: 'Status',
            sortable: false,
            flex: 1,
            renderCell: (params: any) => {
                return <FormControlLabel control={<Switch onChange={() => updateUser(params)} size={'small'} defaultChecked={params?.value} />} label={params?.value ? 'Active': 'Inactive'} />
            }
        }
    ];

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        if(error) {
            toast.show({severity: 'error', message: error?.message});
            console.log(error);
        }
    }, [error]);

    const getUserList = async () => {
         const response: any = await get({url: APIMETHOD.user.GET_USER});
         if(response) {
            gridData = response;
            setUserList(response);
         }   
    };

    const updateUser = async (user: any) => {
        const response: any = await patch({url: APIMETHOD.user.UPDATE_USER + user.row?.id + '/', data: {is_active: !user.value}});
        if(response) {
            const rowIndex = user?.api?.getRowIndex(user?.row?.id);
            let updatedData = userList;
            if(rowIndex > -1) {
                updatedData[rowIndex].is_active = !user.value;
            }
            setUserList(updatedData);
            toast.show({severity: 'success', message: !user?.value ? 'User Activated' : 'User Deactivated'});
        }  
    }

    return (<>
                <LoadingService open={loading} />
                {openDialog && <AddUser isOpen={openDialog} handleClose={()=> setOpenDialog(false)} />}
                <div className='container'>
                    <Box sx={{my: 2}}><h5>User Management</h5></Box>
                    <div className='p-4 bg-white shadow rounded-3'>
                        <div className='row justify-content-between mb-1'>
                            <div className='col-sm-4 mx-2'>
                                <Button sx={{height: '32px', background: '#9DECF2', border: 'border: 1px solid #81D0D8', color: '#000', fontFamily: 'VerdanaBold'}}
                                    variant="contained" onClick={() => setOpenDialog(true)}>
                                        Add user
                                </Button>
                            </div>
                        </div>
                        <div className='w-100 customDataGrid'>
                            <DataGrid className='border-0 defaultFontFamily'
                                sx={{'.MuiDataGrid-columnHeaderTitle': { 
                                    fontWeight: 'bold !important'
                                 }}}
                                rowHeight={35}
                                rows={userList}
                                columns={columns}
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                pageSize={8}
                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </div>
            </>)
}

export default UserManagement;