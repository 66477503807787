import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { NUMBER_10, pre_production_list } from "./modal";

interface preprodProps {
  formikProps: any;
  isExpanded: any;
}

function PreProduction(props: preprodProps) {
  const { formikProps, isExpanded } = props;
  const [isChange, setIsChange] = useState(true);
  const [isOpen, setIsOpen] = useState<any>({
    client: false,
    make_script: false,
    testimony_questions: false,
    do_location_scouting: false,
    need_talent_casting: false,
    need_prop_access: false,
  });
  const {
    values,
    handleChange,
    pre_production,
    setFieldValue,
    errors,
    touched,
  } = formikProps;
  const need_table_read = values?.pre_production.need_table_read;
    useEffect(() => {
      if (isExpanded && need_table_read[0] == true) {
          // setFieldValue('pre_production.need_table_read[3]', 'Briefing Crew on the Project Details, Email Communication with the Client, etc');
          // handleChange({
          //   target: {
          //     name: "pre_production.need_table_read[3]",
          //     value: 'Briefing Crew on the Project Details, Email Communication with the Client, etc'
          //   }
          // })
      }
  }, [need_table_read, isExpanded])

  const valueChange = (event: any, newValue: number) => {
    setIsChange(false);
    if (event.target.id !== "other") {
      if (newValue === 2) {
        setIsOpen((prev: { [key: number]: boolean }) => ({
          ...prev,
          [event.target.id]: true,
        }));
      } else {
        setIsOpen((prev: { [key: number]: boolean }) => ({
          ...prev,
          [event.target.id]: false,
        }));
      }
    } else {
      setIsOpen((prev: { [key: number]: boolean }) => ({
        ...prev,
        [event.target.id]: true,
      }));
    }
    setFieldValue(event.target.name, newValue);
  };

  const onlyNumbers = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key;
      const keyCode = e.keyCode || e.which;
      // Allow numeric characters (0-9) and the backspace key
      if (!/^[0-9.]+$/.test(key) && keyCode !== 8) {
        e.preventDefault();
      }
    },
    []
  );
  return (
    <div>
      {pre_production_list.map((list: any) => {
        const list_name = list.name;

        if (isExpanded === 1) {
          if (isChange) {
            values.pre_production[list?.name][0] =
              `pre_production.${list.name}[0]` ===
                "pre_production.need_table_read[0]" ||
              `pre_production.${list.name}[0]` ===
                "pre_production.make_shot_list[0]" ||
              `pre_production.${list.name}[0]` ===
                "pre_production.make_schedule[0]"
                ? 1
                : values.pre_production[list?.name][0];
          }
        }

        return (
          <div key={list.id} className="pre_pro_list_main my-3 px-3 py-2">
            <div className="pre_pro_list">
              <Box
                sx={{
                  fontSize: "0.875rem",
                  fontFamily: "VerdanaBold",
                  letterSpacing: "0.4px",
                }}
              >
                {list.key}
              </Box>

              {list_name === "make_script" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="make_script"
                    aria-label="make_script"
                  >
                    Voyage
                  </ToggleButton>
                  <ToggleButton
                    id="make_script"
                    name={`pre_production.${list.name}[0]`}
                    value={2}
                    aria-label="make_script"
                  >
                    Client
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={0}
                    aria-label="0"
                  >
                    Not Needed
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : list_name === "testimony_questions" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="testimony_questions"
                  >
                    Voyage
                  </ToggleButton>
                  <ToggleButton
                    id="testimony_questions"
                    name={`pre_production.${list.name}[0]`}
                    value={2}
                    aria-label="testimony_questions"
                  >
                    Client
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={0}
                  >
                    No Testimony
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : list_name === "need_talent_casting" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="need_talent_casting"
                  >
                    Voyage
                  </ToggleButton>
                  <ToggleButton
                    id="need_talent_casting"
                    name={`pre_production.${list.name}[0]`}
                    value={2}
                    aria-label="need_talent_casting"
                  >
                    Client
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={0}
                  >
                    No Talent
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : list_name === "need_prop_access" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="need_prop_access"
                  >
                    Voyage
                  </ToggleButton>
                  <ToggleButton
                    id="need_prop_access"
                    name={`pre_production.${list.name}[0]`}
                    value={2}
                    aria-label="need_prop_access"
                  >
                    Client
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={0}
                  >
                    Not Needed
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : list_name === "do_location_scouting" ? (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="do_location_scouting"
                  >
                    Voyage
                  </ToggleButton>
                  <ToggleButton
                    id="do_location_scouting"
                    name={`pre_production.${list.name}[0]`}
                    value={2}
                    aria-label="do_location_scouting"
                  >
                    Client
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={0}
                  >
                    Location already secured
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup
                  size="small"
                  color="primary"
                  value={values?.pre_production[list.name][0]}
                  exclusive
                  onChange={valueChange}
                >
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    value={1}
                    id="other"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    name={`pre_production.${list.name}[0]`}
                    id="other"
                    value={0}
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </div>

            {values?.pre_production[list?.name][0] ? (
              <div className="row mb-2">
                <div className="col-6">
                  <FormControl variant="outlined" size="small" fullWidth>
                    {`pre_production.${list.name}` ===
                      "pre_production.need_table_read" && (
                      <>
                        <TextField
                          name={`pre_production.${list.name}[3]`}
                          variant="outlined"
                          label="What is being project planned?"
                          onChange={handleChange}
                          value={values.pre_production[list.name][3]}
                          margin="dense"
                          size="small"
                        ></TextField>
                        {errors?.pre_production &&
                          errors?.pre_production[list_name] && (
                            <FormHelperText>
                              {errors?.pre_production[list_name]
                                ? errors?.pre_production[list_name][3]
                                : null}
                            </FormHelperText>
                          )}
                      </>
                    )}

                    {isOpen[list_name] ||
                    values?.pre_production[list?.name][0] === 2 ? null : (
                      <>
                        <TextField
                          name={`pre_production.${list.name}[2]`}
                          variant="outlined"
                          label="How many hours needed?"
                          onChange={handleChange}
                          onKeyDown={onlyNumbers}
                          value={values.pre_production[list.name][2]}
                          margin="dense"
                          size="small"
                        ></TextField>
                        {errors?.pre_production &&
                          errors?.pre_production[list_name] && (
                            <FormHelperText>
                              {errors?.pre_production[list_name]
                                ? errors?.pre_production[list_name][2]
                                : null}
                            </FormHelperText>
                          )}
                      </>
                    )}
                  </FormControl>
                </div>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default PreProduction;
