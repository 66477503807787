import { FormControl, FormControlLabel, Checkbox, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateField } from "@mui/x-date-pickers/DateField";
import moment from 'moment';
import AccordianVoyage from '../../shared/accordian/AccordianVoyage';
import { TABS_NUM } from '../../utils/utils';
import { NUMBER_10, _days } from './modal';

function ProductionForm(props: any) {
    const { index, formikProps } = props;
    const { handleChange, production_details, setFieldValue, values, errors, touched } = formikProps;
    const total_production_days = values?.production_details?.day?.length;
    const [duplicated, setDuplicated] = useState(false);
    
    // useEffect(()=>{
    //     return () => {
    //         let data: any = values.production_details.day;
    //         if(!data[index]) {
    //             data[index] = _days;
    //             setFieldValue('production_details.day', data);
    //         }
    //     }
    // }, [index]);

    const handleFilmingDateChange = (newValue: any) => {
      const date = dayjs (newValue).format("YYYY-MM-DD")
      // console.log("Filming Date: ", dayjs (newValue).format("YYYY-MM-DD"))
      handleChange({
        target: {
          name: `production_details.day[${index}].filming_date`,
          value: date
        }
      })
      
      if (!! values.post_production?.edit[index]?.deadline_of_initial_delivery) {
        const defaultDeadline = moment (date).add(5, "days").format("YYYY-MM-DD")
        // console.log(`Item ${index} Deadline: `, values.post_production?.edit[index]?.deadline_of_initial_delivery);
        setFieldValue(`post_production.edit[${index}].deadline_of_initial_delivery`, defaultDeadline)
      }
    }

    const handleFilmingDateDuplication = () => {
      if (!duplicated) {
        setDuplicated(true);
        setNextFilmingDate();
      } else {
        setDuplicated(false);
      }
    }

    const setNextFilmingDate = (set=true) => {
      const next_index = index+1;

      if (set) {
        handleChange({
          target: {
            name: `production_details.day[${next_index}]`,
            value: values?.production_details?.day[index]
          }
        });
        console.log("Form Values: ", values?.production_details?.day[next_index])
      } else {
        
      }
    }

    return (
      <>
        {" "}
        {values.production_details.day[index] && (
          <div className="row" key={index + "production"}>
            <div className="row align-items-center mb-4">
              <div className="col-4">
                <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField 
                      name={`production_details.day[${index}].filming_date`}
                      label="Filming Date" 
                      onChange={handleFilmingDateChange}
                      value={dayjs(values.production_details?.day[index]?.filming_date) || ""}
                      format="MM/DD/YY"
                      slotProps={{
                        textField: {
                          helperText: errors.production_details?.day[index]?.filming_date &&
                          touched.production_details?.day[index]?.filming_date &&
                          errors.production_details?.day[index]?.filming_date,
                        },
                      }}
                      margin="dense"
                      size="small" 
                      />
                  </LocalizationProvider>

                  {/* <TextField
                    name={`production_details.day[${index}].filming_date`}
                    type={"date"}
                    variant="outlined"
                    label="Filming Date"
                    onChange={handleChange}
                    value={values.production_details?.day[index]?.filming_date || ""}
                    helperText={
                      errors.production_details?.day[index]?.filming_date &&
                      touched.production_details?.day[index]?.filming_date &&
                      errors.production_details?.day[index]?.filming_date
                    }
                    margin="dense"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      autoComplete: "off", // Disable autocomplete
                    }}
                  /> */}
                </FormControl>
              </div>
              <div className="col-4">
                <FormControl
                  variant="outlined"
                  sx={{ m: 1 }}
                  size="small"
                  fullWidth
                >
                  <InputLabel id="role" style={{ backgroundColor: "white" }}>
                    Crew Package Needed?
                  </InputLabel>
                  <Select
                    labelId="crew"
                    id="crew"
                    name={`production_details.day[${index}].crew_package_needed`}
                    value={
                      values.production_details.day[index].crew_package_needed
                    }
                    onChange={handleChange}
                    label="Role"
                  >
                    {NUMBER_10.map((u: number) => (
                      <MenuItem value={u} key={u + "key"}>
                        {u}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.production_details?.day[index]?.crew_package_needed &&
                      touched.production_details?.day[index]
                        ?.crew_package_needed &&
                      errors.production_details?.day[index]?.crew_package_needed}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-4">
                {index+1 < total_production_days && <FormControlLabel
                  // className="mt-4"
                  control={
                    <Checkbox
                      id="duplicate_filming_date"
                      // name='client_details.new_client'
                      onChange={handleFilmingDateDuplication}
                      checked={duplicated}
                      size="small"
                    />
                  }
                  label="Duplicate across the next day"
                />}
              </div>
            </div>
            <div className="col-sm-12 p-0">
              {values.production_details.day[index].crew_package_needed &&
                TABS_NUM(
                  values.production_details.day[index].crew_package_needed || 0
                ).map((crew: any, idx: number) => {
                  return (
                    <AccordianVoyage
                      formikProps={formikProps}
                      tabNumber={index}
                      crewIndex={idx}
                      key={`accordian-voyage-${idx}`}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </>
    );
}

export default ProductionForm;