import React, { useCallback } from 'react'
import { FormControl, TextField, FormHelperText, Select, MenuItem, InputLabel } from '@mui/material';
import DynamicTabs from '../../shared/tabs/Tabs';
import RentalLocationForm from './RentalLocationForm';
import { NUMBER_10, _days } from './modal';
import { TABS_NUM } from '../../utils/utils';

interface RentalLocationProps {
    formikProps: any
}

export default function RentalLocation(props: RentalLocationProps) {

    const { formikProps } = props;
    const { handleChange, setFieldValue, values, errors, touched } = formikProps;

    const handleRentalDaysChange = (e: any) => {
        const rental_days: number = Number(e?.target?.value);
        let data: any = values?.rentals?.days;
        const remaining_days = rental_days - data?.length;
        if (remaining_days > 0) {
            TABS_NUM(remaining_days || 0)
                .forEach(() => data.push(_days))

            setFieldValue('rentals.days', data);
        };
        if (rental_days < data?.length && data.length > 0) {
            const updated_day = data.slice(0, rental_days);
            setFieldValue(`rentals.days`, updated_day);
        }
        setFieldValue('rentals.rental_days_needed', Number(rental_days))
    }

    return (
        <>
            <div className='row m-0 mb-2 mt-3'>
                <div className='col-6 p-0'>
                    <FormControl variant="outlined" size='small' fullWidth>
                        <InputLabel id="rentals_days_needed">Rental Days Needed?</InputLabel>
                        <Select
                            labelId="rentals_days_needed"
                            id="rentals_days_needed"
                            name={`rentals.rental_days_needed`}
                            value={values.rentals.rental_days_needed}
                            onChange={handleRentalDaysChange}
                            label="Rental Days Needed?"
                        >
                            {
                                NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>
                            {(errors?.rentals?.rental_days_needed &&
                                touched?.rentals?.rental_days_needed) && errors?.rentals?.rental_days_needed
                            }
                        </FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className='row m-0 d-tabs'>
                {(values.rentals.rental_days_needed && values.rentals.rental_days_needed > 0) ?
                    <DynamicTabs 
                        tabNumber={values.rentals.rental_days_needed}
                        formikProps={formikProps} 
                        label={'day'}
                        key={'days'}
                        components={RentalLocationForm}
                        formName={'rentals'}
                    />
                    : null
                }
            </div>
        </>
    )
}
