export enum PROJECT_TABS {
  INQUIRY = "inquiry",
  INVOICE_SENT = "invoice_sent",
  CARD_INFO_SAVED = "card_info_saved",
  FIRST_DOWN_PAYMENTS = "first_down_payment",
  PARTIAL_PAYMENTS = "partial_payment",
  FULLY_PAID = "fully_paid",
  ARCHIVE = "archive",
}

export enum PROJECT_TABS_NAME {
  INQUIRY = "Invoice Drafted",
  INVOICE_SENT = "Invoice sent",
  CARD_INFO_SAVED = "Card Info Saved",
  FIRST_DOWN_PAYMENTS = "Down payment made",
  PARTIAL_PAYMENTS = "Partially paid",
  FULLY_PAID = "Fully paid",
  ARCHIVE = "Archive",
}

export interface PROJECT_TAB_INFO_DTO {
  tabName: string;
  tabid: string;
  components: any;
  rows: any[];
  columns: any[];
  // logic for passing selected rows back to the project.tsx
  handleSelectedRows?: (selectedRows: number[]) => void;
  handlePageChange?: (evt:any, pageNumber:number) => void;
  // logic for hiding checkboxes
  showCheckbox?: boolean;
}

export const FILTER_ROW_DATA = (rowData: any = [], id: any) => {
  return rowData.filter((x: any) => {
    if (typeof(id) == "object") {
      return id.includes(x?.stage)
    }
    return x?.stage === id
  });
};
