import { EnvironmentService } from "../shared/environment.service";

export const baseUrl = EnvironmentService.EndPoints;

export const APIMETHOD: any = {
  user: {
    LOGIN: `${baseUrl}/api/user/login/`,
    REGISTER_USER: `${baseUrl}/api/user/register/`,
    INVITE_USER: `${baseUrl}/api/user/registration-link/`,
    GET_USER: `${baseUrl}/api/user/`,
    VERIFY_UID: `${baseUrl}/api/user/verify-reg-link/`,
    UPDATE_USER: `${baseUrl}/api/user/update_user/`,
    CHECK_CREDS: {
      admin: `${baseUrl}/api/client/admin/check-get-creds`,
      project_manager: `${baseUrl}/api/client/project-manager/check-get-creds`
    }
  },
  INVOICE: {
    CREATE_INVOICE: {
      admin: `${baseUrl}/api/invoice/admin/invoice/`,
      project_manager: `${baseUrl}/api/invoice/project-manager/invoice/`
    },
    SET_UPCHARGE:{
      up_charge:`${baseUrl}/api/invoice/invoices`
    },
    CLIENTS: {
      admin: `${baseUrl}/api/client/admin/client/`,
      project_manager: `${baseUrl}/api/client/project-manager/client/`
    },
    CREATE_PROJECTS: {
      admin: `${baseUrl}/api/project/admin/project/`,
      project_manager: `${baseUrl}/api/project/project-manager/project/`
    },
    DISCOUNT: {
      update: `${baseUrl}/api/invoice/admin/invoice/discount/`
    },
    PAYMENT_SECTION: {
      admin: `${baseUrl}/api/invoice/admin/invoice/for-payment-section?`,
      project_manager: `${baseUrl}/api/invoice/project-manager/invoice/for-payment-section?`
    },
    PAYMENT_SCHEDULE: `${baseUrl}/api/invoice/payment_schedule/`,
    GET_PAGINATED_ARCHIED_DATA: {
      admin: `${baseUrl}/api/invoice/admin/invoice/paginate-archive`,
      project_manager: `${baseUrl}/api/invoice/project-manager/invoice/paginate-archive`
    },
    PRICING: `${baseUrl}/api/invoice/admin/key_value/`,
    MARK_AS_SENT: `${baseUrl}/api/invoice/mark_as_sent/`,
    CREW_SIZE_DESC_TOGGLE: `${baseUrl}/api/invoice/toggle_crew_size_desc/`,
    MANUALLY_BOOKED: {
      pay: `${baseUrl}/api/invoice/admin/invoice/:invoice_id/create_payment/`,
      list: `${baseUrl}/api/invoice/admin/invoice/fetch-manually-booked/`,
      payments: `${baseUrl}/api/admin/invoice/`
    },
  },
  CLIENTS: {
    GET_PAGINATED_DATA: {
      admin: `${baseUrl}/api/client/admin/client/paginate`,
      project_manager: `${baseUrl}/api/client/project-manager/client/paginate`
    },
    RECURRING_CLIENTS:{
      recurring_clients: `${baseUrl}/api/client/admin/client/recurring-clients?`,
    },
    GET_PAGINATED_ARCHIED_DATA: {
      admin: `${baseUrl}/api/client/admin/client/paginate-archive`,
      project_manager: `${baseUrl}/api/client/project-manager/client/paginate-archive`
    },
    GET_PAGINATED_ASSIGNE_DATA: {
      admin: `${baseUrl}/api/client/admin/client/paginate-assigne`,
      project_manager: `${baseUrl}/api/client/project-manager/client/paginate-assigne`
    },
    GET_PAGINATED_LIKELYTOBOOK_DATA: {
      admin: `${baseUrl}/api/client/admin/client/paginate-likelytobook`,
      project_manager: `${baseUrl}/api/client/project-manager/client/paginate-likelytobook`
    },
  },
  DETAILS: {
    PROJECT: {
      admin: `${baseUrl}/admin/project/`,
      project_manager: `${baseUrl}/project-manager/project/`
    },
    CLIENTS: {
      admin: `${baseUrl}/api/client/admin/client/`,
      project_manager: `${baseUrl}/api/client/project-manager/client/for-email-template/`
    },
    IS_REPLIED: {
      admin: `${baseUrl}/api/client/admin/is-replied/`,
      project_manager: `${baseUrl}/api/client/project-manager/is-replied/`
    },
    REACHED_CLIENTS: {
      admin: `${baseUrl}/api/client/admin/client/reached/`,
      project_manager: `${baseUrl}/api/client/project-manager/client/reached/`
    }
  },
  PAYMENTS: {
    GET: {
      admin: `${baseUrl}/api/payment/admin/payment-list/`,
      project_manager: `${baseUrl}/api/payment/project-manager/payment-list/`
    },
    MANUAL_LIST: `${baseUrl}/api/payment/admin/manual-payment-list/`
  },
  CHECKOUT: {
    INVOICE: `${baseUrl}/api/payment/process/secure_payment/`,
    SUCCESS: `${baseUrl}/api/payment/checkout/success/`
  }
};

export const constants: any = {
  PAGEURLS: {
    INQUIRE_RESPONSE: {
      local: "http://localhost:3000/project-inquiry-response",
      dev: "https://www.voyageproapp.com/project-inquiry-response",
      prod: "https://www.voyageproapp.com/project-inquiry-response"
    }
  }
};