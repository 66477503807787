import React, { Children } from 'react';
import { Navigate } from 'react-router-dom';

function Protected({isAuthenticated, isAuthorized, children}: any) {
    if(!isAuthenticated) {
        return <Navigate to={'/login'} replace />
    }
    if(!isAuthorized) {
        return <><h4>Not Authorized</h4></>
    }
    return children
}

export default Protected;