import moment from "moment";
import * as Yup from 'yup';

const preValidation = (_name: string) => Yup.array(
    Yup.mixed()
        .test({
            name: _name,
            exclusive: false,
            params: {},
            message: 'Please enter above field',
            test: function (value: any, context: any) {
                const regex = /\[(\d+)\]/;
                const match = context.path.match(regex);
                // "pre_production.*[1]"
                if (match[1] == '1') {
                    return true
                }

                if (_name === context?.type && context?.parent[0] === true && (value == 0 || value === undefined)) {
                    return false;
                }
                return true;
            },
        })
)

export const DisplayingErrorMessagesSchema = Yup.object().shape({
    // private_notes: Yup.string()
    //         .required('Please enter above field'),
    client_details: Yup.object().shape({
        name: Yup.string()
            .required('Please enter client name'),
        email: Yup.string()
            .required('Please enter client email'),
        project_name: Yup.string()
            .required('Please enter projectName'),
        // filming_days_num: Yup.number()
        //     .min(1, 'Please choose greater then 0')
        //     .required('Please enter above field')
        filming_days_num: Yup.number().when('invoice_type', {
            is: (type: string) => type === 'production',
            then: Yup.number().min(1, 'Please choose greater then 0')
                .required('Please enter above field'),
            otherwise: Yup.number().nullable().optional()
        }),
    }),
    // invoice_type
    production_details: Yup.object().shape({
        day: Yup.array(
            Yup.object({
                filming_date: Yup.mixed().required('Please enter filming date'),
                crew_package_needed: Yup.string().required('Please enter crew Package'),
                crew_package: Yup.array(
                    Yup.object({
                        shot_with_this_package: Yup.string().required('Please enter above field'),
                        size: Yup.string().required('Please enter above field'),
                        crew_member_1: Yup.string().when("size", {
                            is: (size: string) => ['solo', 'small', 'medium', 'large'].includes(size),
                            then: Yup.string().nullable().required('Please enter crew member'),
                            otherwise: Yup.string().nullable().optional(),
                        }),
                        crew_member_2: Yup.string().when("size", {
                            is: (size: string) => ['medium', 'large'].includes(size),
                            then: Yup.string().nullable().required('Please enter above field'),
                            otherwise: Yup.string().nullable().optional(),
                        }),
                        crew_member_3: Yup.string().when("size", {
                            is: (size: string) => ['large'].includes(size),
                            then: Yup.string().nullable().required('Please enter above field'),
                            otherwise: Yup.string().nullable().optional(),
                        }),
                        location: Yup.string().required('Please enter location'),
                        arrival_time: Yup.mixed().required('Please enter arrival time'),
                        departure_time: Yup.mixed().required('Please enter departure').test("is-greater", "Departure time should be greater", function (value) {
                            if (this.parent?.arrival_time) {
                                const { arrival_time } = this?.parent;
                                return moment(value, "HH:mm aa").isAfter(moment(arrival_time, "HH:mm aa"));
                            }
                            return false;
                        })
                    }).nullable()
                )
            }).nullable()
        )
    }).nullable(),
    post_production: Yup.object().shape({
        // no_of_edits: Yup.number().min(0, 'Please choose greater then 0'),
        // .required('Please enter above field'),
        no_of_edits: Yup.number().nullable(),
        edit: Yup.array(
            Yup.object({
                type: Yup.string().required('Please select the type'),
                title: Yup.string().required('Please enter title'),
                description: Yup.string().required('Please enter description'),
                aspect_ratio: Yup.string().when('type', {
                    is: (type: string) => type === 'video',
                    then: Yup.string()
                        .nullable()
                        .required('Please enter above field'),
                    otherwise: Yup.string().nullable(),
                }),
                no_of_photos_edit: Yup.number().when('type', {
                    is: (type: string) => type === 'photo',
                    then: Yup.number()
                        .required('Please choose a value greater than 0')
                        .min(1, 'Please choose a value greater than 0'),
                    otherwise: Yup.number().notRequired(),
                }),
                // need_animation: Yup.boolean().required('Please enter above field'),
                final_run_time_of_intro: Yup.mixed().when("type", {
                    is: (type: string) => type === 'video',
                    then: Yup.number()
                        .min(0.25, 'Minimum run time is 25 seconds. Enter time equal or greater than 25 seconds')
                        .required('Please enter above field'),
                    otherwise: Yup.number().min(0),
                }),
                hours_needed_for_initial_delivery: Yup.number()
                    .min(1, 'Please choose greater then 0')
                    .required('Please enter above field'),
                deadline_of_initial_delivery: Yup.mixed()
                    .required('Please enter above field'),
            }).nullable()
        )
    }),
    pre_production: Yup.object().shape({
        make_storyboard: preValidation('make_storyboard'),
        make_shot_list: preValidation('make_shot_list'),
        make_script: preValidation('make_script'),
        make_schedule: preValidation('make_schedule'),
        handle_renting_the_location: preValidation('handle_renting_the_location'),
        testimony_questions: preValidation('testimony_questions'),
        do_location_scouting:preValidation('do_location_scouting'),
        // acquire_location_permit: preValidation('acquire_location_permit'),
        do_location_walkthrough: preValidation('do_location_walkthrough'),
        // handle_purchasing_prop: preValidation('handle_purchasing_prop'),
        // need_rehearsal: preValidation('need_rehearsal'),
        need_table_read: preValidation('need_table_read'),

    }),
    rentals: Yup.object().shape({
        rental_days_needed: Yup.number().nullable(),
        days: Yup.array(
            Yup.object().shape({
                date_of_shoot: Yup.mixed().required('Please enter shoot date'),
                location: Yup.string().required('Please enter location'),
                rental_start_time: Yup.mixed().required('Please enter above field'),
                rental_end_time: Yup.mixed().required('Please enter above field').test("is-greater", "End time should be greater", function (value) {
                    if (this.parent?.rental_start_time) {
                        const { rental_start_time } = this?.parent;
                        return moment(value, "HH:mm aa").isAfter(moment(rental_start_time, "HH:mm aa"));
                    }
                    return false;
                })
            })
        )
    }).nullable(),
    custom: Yup.object().shape({
        total_items_needed: Yup.number().nullable(),
        number_of_items: Yup.array(
            Yup.object().shape({
                item_description: Yup.string().required('Please enter above field'),
                price: Yup.number().required('Please enter above field'),
            })
        )
    }).nullable()
});

