import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AddchartIcon from '@mui/icons-material/Addchart';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Paper from '@mui/material/Paper';

import LoadingService from '../../shared/loader';
import { useAxios } from '../../core/useAxios';
import { useParams } from 'react-router-dom';
import SendResponseEmail, { SendResponseEmailRef } from './SendResponseEmail';
import { getTimeDifference, getUserInfo } from '../../utils/utils';
import { APIMETHOD } from '../../core/constant';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';


const EmailDetails = [
    {
        email_to: '',
        email_from: '',
        body: '<p>Hi {client_name},</p>\n<p> Thank you for sending over those details about your project! We have reviewed the details you provided and would love to hop on a quick call, from there we can send over a proposal for your review! </p>\n <p>  Does <strong>  <em> {date1} or {date2} or {date3}  </strong> </em>work for you? Let me know if you have any questions.</p>\n<p>Looking forward to hearing back!</p>',
        subject: "Let's Discuss Your Video Production Project!",
    },
    {
        email_to: '',
        email_from: '',
        body: '<p>Hi {client_name}, </p> \n <p> Thank you for sending over those details about your project! I just have a few quick questions and then I can send you over a proposal for your review: </p> \n <p> <strong> <em>type your question here </strong> </em> </p>\n\n <p>Once we hear back about these details, I will send you over a proposal right away! </p> \n <p> Feel free to reach out with any additional questions you may have, we look forward to hearing from you! </p> ',
        subject: '{project_name} Details for Booking',
    },
    {
        email_to: '',
        email_from: '',
        body: 'Email Body for Send a proposal immediatly',
        subject: 'Subject for Send a proposal immediatly',
    },
    {
        email_to: 'management@voyagepro.co',
        email_from: '',
        body: '<p>Hi, I am going to deny this project </p>\n <p> Client Details : </p> \n <p>  Name : {client_name} </p> \n <p> Email : {client_email} </p> \n <p>Description: {client_description} </p> \n',
        subject: 'Project Denial: {project_name}',
    }
]

export default function FixedBottomNavigation() {
    const userInfo = getUserInfo();
    const [value, setValue] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);
    const { get, loading } = useAxios({ showLoader: true });
    const { client_id
        , manager_email
        , manager_id
        , project_id } = useParams();
    const [emailRequestDetails, setEmailRequestDetails] = React.useState<any>(null);
    const sendResponseEmailRef = React.useRef<SendResponseEmailRef | null>(null);
    const [emailFields, setEmailFields] = React.useState<any>(null);
    const [projectDetails, setProjectDetails] = React.useState<any>(null);
    const [clientDetails, setClientDetails] = React.useState<any>(null);
    const [timeReamin, setTimeReamin] = React.useState<number>(0);
    const [formatedEmailFields, setFormatedEmailDetails] = React.useState<any>(EmailDetails);
    const navigate = useNavigate();

    const formatEmailDetails = (clientData: any, projectData: any): void => {

        let projectDescription = '<p> Client has not created any project yet </p> \n'
        if (projectData.name) {
            projectDescription = `<p> Below are project details: </p> \n <p> Name: ${projectData.name}</p>  \n <p> Category: ${projectData.category}</p>\n <p> Filming Days: ${projectData.filming_days} <\p>\n`
        }
        let replacementsTemp: any = {
            client_email_address: clientData.email,
            project_description: projectDescription,
            project_name: projectData.name ? projectData.name : '{Project Name}',
            client_name: clientData.name ? clientData.name.split(' ')[0] : '',
            client_email: clientData.email,
            client_description: clientData.description ? clientData.description : '    ',
            date1: moment().format("dddd, MMMM Do") + ' at 5:00 PM',
            date2: moment().add(1, 'day').format("dddd, MMMM Do") + ' at 5:00 PM',
            date3: moment().add(2, 'days').format("dddd, MMMM Do") + ' at 5:00 PM'
        }
        let FormatedEmailDeatils = EmailDetails.map((obj) => {
            let formatedSubject = obj.subject.replace(
                /{([^}]+)}/g,
                (match: any, key: any) => replacementsTemp[key] || match
            )
            let formatedBody = obj.body.replace(
                /{([^}]+)}/g,
                (match: any, key: any) => replacementsTemp[key] || match
            )
            return { ...obj, body: formatedBody, subject: formatedSubject, client_id: client_id }
        })
        setFormatedEmailDetails(FormatedEmailDeatils)
        changeEamilFields(FormatedEmailDeatils, clientData)
    }

    const getClientAndProjectDetails = async () => {
        const clientData: any = await get({ url: APIMETHOD.DETAILS.CLIENTS[userInfo.role] + client_id });
        const projectData = clientData?.projects.length ? clientData.projects[clientData.projects.length - 1] : {}
        console.log("clientDetails::", clientData)
        console.log("projectDetails::", projectData)
        if (clientData) {
            setClientDetails(clientData)
            if (clientData.response_time == null) {
                const now = moment();
                const differenceInSeconds = getTimeDifference(clientData.updated_at);
                console.log("timeRemain::", 1800 - differenceInSeconds)
                setTimeReamin(1800 - differenceInSeconds)
            }
        }
        if (projectData) {
            setProjectDetails(projectData)
        }
        formatEmailDetails(clientData, projectData)
    }

    const changeEamilFields = (FormatedEmailDeatils: any, clientData: any): void => {
        console.log("value::", value)
        if (value == 2) {
            navigate(`/invoice/0/${client_id}`)
        }
        if (FormatedEmailDeatils) {
            if (value < 3) {
                setEmailFields({ ...FormatedEmailDeatils[value], email_to: clientData?.email ? clientData.email : clientDetails.email, email_from: manager_email })
            } else {
                setEmailFields({ ...FormatedEmailDeatils[3], email_to: 'management@voyagepro.co' })
            }
        } else if (clientDetails) {
            if (value < 3) {
                setEmailFields({ ...formatedEmailFields[value], email_to: clientDetails.email, email_from: manager_email })
            } else {
                setEmailFields({ ...formatedEmailFields[3], email_to: 'management@voyagepro.co' })
            }
        }

    }

    React.useEffect(() => {
        console.log("in the useeffect")
        getClientAndProjectDetails()
    }, [])

    React.useEffect(() => {
        changeEamilFields(null, null)
    }, [value])
    return (
        <div className="container">
            <LoadingService open={loading} />
            <Box sx={{ my: 2 }}><h5>Inquirey Response</h5></Box>
            <div className='p-4 bg-white shadow rounded-3'>
                <Box sx={{ pb: 7 }} ref={ref}>
                    <CssBaseline />
                    <Paper sx={{ position: 'static', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                        >
                            <BottomNavigationAction label="Invite client to a phone call" icon={<PermPhoneMsgIcon />} style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }} />
                            <BottomNavigationAction label="Ask Questions via email" icon={<PsychologyAltIcon />} style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }} />
                            <BottomNavigationAction label="Send a proposal immediatly" icon={<AddchartIcon />} style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }} />
                            <BottomNavigationAction label="Deny inquiry invite" icon={<CancelScheduleSendIcon />} style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }} />
                        </BottomNavigation>
                    </Paper>
                    <div className="d-flex">
                        <div className='flex-grow-6'>
                            <SendResponseEmail
                                emailDetails={{ client_id, email: emailFields?.email_to ? emailFields.email_to : '', client_email: emailFields?.email_to ? emailFields.email_to : '', subject: emailFields?.subject ? emailFields.subject : '', body: emailFields?.body ? emailFields.body : '' }}
                                handleCloseEmail={() => { }}
                                key={client_id}
                                emailRequestDetails={emailRequestDetails}
                                setEmailRequestDetails={setEmailRequestDetails}
                                ref={sendResponseEmailRef}
                                emailFields={emailFields}
                            />
                        </div>
                        {timeReamin ? (<div className='flex-grow-4 d-flex align-items-center p-2'>
                            <CountdownTimer initialTime={timeReamin} />
                        </div>) : ''}
                    </div>
                </Box>

            </div>
        </div>

    );
}




