import React, { useEffect, useMemo, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Autocomplete, Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { EMAIL_HELPER_TEXT, getUserInfo } from '../../utils/utils';
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import LoadingService from '../../shared/loader';
import { mapCreateClientRequest } from '../Invoice/modal';
import { toast } from '../../shared/Toser/ToastManager';
import SendEmailToManager, { SendEmailToManagerRef } from '../Managers/SendEmailToManager';


interface UserDiaglogProps {
    isOpen: boolean,
    // addClients: any,
    handleClose: (data: any) => void,
    clientDetails: any,
}

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }

}

function AssignProjectManager(props: UserDiaglogProps) {

    const { isOpen, handleClose, clientDetails } = props;
    const [email, setEmails] = useState<any>('');
    const [client_name, setClientName] = useState<string>('');
    const [projectManager, setProjectManager] = useState<any>(null);
    const [projectManagerId, setProjectManagerId] = useState<any>(null);
    const [errors, setErrors] = useState<any>(null);
    const [clientList, setClients] = React.useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { post, error, get } = useAxios({ showLoader: true });
    const userInfo = getUserInfo();
    const [showSendEmail, setShowSendEmail] = useState(true);
    const [canSendEmail, setCanSendEmail] = useState(false);
    const [emailRequestDetails, setEmailRequestDetails] = useState<any>(null);
    const handleCloseTask = () => {
        handleClose(false);
    };
    const sendEmailToManagerRef = useRef<SendEmailToManagerRef | null>(null);

    const defaultProps = {
        options: clientList,
        getOptionLabel: (option: any) => `${option.full_name}`
    };

    useEffect(() => {
        const getClients = async () => {
            const result = await get({ url: APIMETHOD.user.GET_USER });
            if (result) {
                mapProjectData(result);
                setClients(result);
                defaultProps.options = result;
            }
        }
        getClients();
    }, []);

    const mapProjectData = (clients: any[] = []) => {
        if (clientDetails.id) {
            const selectedClientIndex = clients.findIndex((x: any) => x?.full_name === clientDetails?.assigned_to);
            setClientName(clientDetails?.name);
            if (selectedClientIndex > -1) {
                setProjectManager(clients[selectedClientIndex]);
            }
        }
    }

    useEffect(() => {
        if (error) {
            const _errors = error?.exactErr;
            if (_errors?.project_manager_id && _errors?.project_manager_id[0]) {
                toast.show({ severity: 'error', message: error?.exactErr?.project_manager_id[0] || error.message });
            } else if (_errors?.client_id && _errors?.client_id[0]) {
                toast.show({ severity: 'error', message: error?.exactErr?.client_id[0] || error.message });
            }
        }
    }, [error]);

    const handleSubmit = async () => {
        console.log("data is ::", emailRequestDetails)
        let hasErrorsInMail = null
        if (sendEmailToManagerRef.current) {
            hasErrorsInMail = await sendEmailToManagerRef.current.checkingEmailValidations();
            if (hasErrorsInMail) return
        }
        const formData: any = new FormData();
        const data = {
            'project_manager_id': projectManagerId,
            'client_id': clientDetails.id
        }
        formData.append('project_manager_id', projectManagerId);
        formData.append('client_id', clientDetails.id);
        (!emailRequestDetails.email) && (emailRequestDetails.email = projectManager.email)
        if (!emailRequestDetails.email_cc) {
            emailRequestDetails.email_cc = []
        } else {
            emailRequestDetails.email_cc = emailRequestDetails.email_cc.split(',')
        }
        setIsLoading(true);
        const response = await assignProjectManager(data, emailRequestDetails);
        setIsLoading(false);
        if (response) {
            handleCloseTask();
            console.log("auth_url::",response.auth_url)
            if (response?.auth_url) {
                let link: any = document.createElement('a');
                link.href = response?.auth_url;
                link.target = '_blank';
                link.click();
                localStorage.removeItem(`email_data_manager${clientDetails?.id}`)
            } else {
                toast.show({ severity: 'success', message: response?.message });
            }

        }


    }

    const assignProjectManager = async (clientDetails: any, emailRequestDetails: any) => {
        console.log("formData::", clientDetails)
        return await post({ url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role] + 'assign/', data: { ...clientDetails, ...emailRequestDetails } });
    }

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog PaperProps={{ style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' } }} open={isOpen} onClose={handleCloseTask} fullWidth={true} scroll='body' >
                <DialogContent>
                    <h5 className='p-2'>Assign Project Manager</h5>
                    {error?.exactErr?.project_manager_id && error?.exactErr?.project_manager_id[0] &&
                        <Box sx={{ pb: 2 }}>
                            <small className='errors_color'>{error?.exactErr?.project_manager_id[0]}</small>
                        </Box>
                    }
                    {error?.exactErr?.client_id && error?.exactErr?.client_id[0] &&
                        <Box sx={{ pb: 2 }}>
                            <small className='errors_color'>{error?.exactErr?.client_id[0]}</small>
                        </Box>
                    }

                    <div className='row'>
                        <div className='col-12'>
                            <FormControl variant="standard" fullWidth>
                                <TextField
                                    margin="dense"
                                    id="clientname"
                                    label="Client Name"
                                    type="text"
                                    onChange={(e: any) => { setClientName(e.target.value) }}
                                    fullWidth
                                    variant="standard"
                                    value={client_name}
                                    disabled={true}
                                // helperText={EMAIL_HELPER_TEXT}
                                />
                            </FormControl>
                        </div>
                        <div className='col-12'>
                            <FormControl variant="standard" fullWidth>
                                <Autocomplete
                                    {...defaultProps}
                                    id="_clients"
                                    value={projectManager}
                                    onChange={(event: any, newValue: any) => {
                                        console.log("newValue::", newValue)
                                        setProjectManagerId(newValue?.id);
                                        setProjectManager(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Project Manager" variant="standard" />
                                    )}
                                />
                            </FormControl>
                        </div>
                        
                    </div>
                    {showSendEmail && (
                        <SendEmailToManager
                            emailDetails={{ client_id: clientDetails?.id, email: (projectManager?.email || ''), client_name: clientDetails?.name,client_email: clientDetails?.email,parject_manager_id: (projectManager?.id || ''),description:clientDetails?.description}}
                            handleCloseEmail={() => { setShowSendEmail(false) }}
                            key={clientDetails?.id}
                            projectDetails = {clientDetails?.projects.length ? clientDetails.projects[clientDetails.projects.length -1 ] : null }
                            emailRequestDetails={emailRequestDetails}
                            setEmailRequestDetails={setEmailRequestDetails}
                            ref={sendEmailToManagerRef}
                        />
                    )}

                </DialogContent>
                <DialogActions sx={{ borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2 }}>
                    <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
                    <Button sx={style.submit} onClick={() => { handleSubmit() }}>{'Save'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AssignProjectManager;