import { Box, Button, FormControl, TextField } from '@mui/material';
import { verify } from 'crypto';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { APIMETHOD } from '../../core/constant';
import { useAxios } from '../../core/useAxios';
import Status_Dialog from '../../shared/Dialog/Status_Dialog';
import Header from '../../shared/header';
import LoadingService from '../../shared/loader';
import { encryptString } from '../../utils/utils';
import { INITIAL_REGISTRATION_FORM, REGFORMDATA, UserRoles } from './model';
import './UserManagement.css';
let ERROR_SUCCESS_MSG = 'User Registered Successfully !!';

function RegisterUser(props: any) {
    const { uid } = useParams();
    const { post, get, error, loading } = useAxios({showLoader: true});
    const [userData, setUserData] = useState<any>(null);
    const [initialForm, setInitialForm] = useState<REGFORMDATA>(INITIAL_REGISTRATION_FORM);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isSuccesError, setIsSuccessError] = useState(false);
    const navigate: any = useNavigate();
    

    useEffect(() => {
        verifyUserforRegistration(uid);
    }, [uid])

    useEffect(() => {
        if(error) {
            const _errors = error?.exactErr;
            if(_errors?.first_name && _errors?.first_name[0]){
                ERROR_SUCCESS_MSG = 'First Name: ' + _errors?.first_name[0];
            } else if(_errors?.last_name && _errors?.last_name[0]){
                ERROR_SUCCESS_MSG = 'Last Name :' + _errors?.last_name[0];
            } else if(_errors?.phone && _errors?.phone[0]){
                ERROR_SUCCESS_MSG = 'Phone :' + _errors?.phone[0];
            } else if(_errors?.password && _errors?.password[0]){
                ERROR_SUCCESS_MSG = 'Password :' + _errors?.password[0];
            } else if(_errors?.registration_id && _errors?.registration_id[0]){
                ERROR_SUCCESS_MSG = _errors?.registration_id[0];
            }
            setIsSuccessError(true);
            setIsSubmit(false);
        }
    }, [error])

    const verifyUserforRegistration = async (uid: any) => {
        const response: any = await get({url: APIMETHOD.user.VERIFY_UID + uid});
        if(response) {
            const data: any = {
                email: response.email,
                userRole: response.role
            };
            setInitialForm({...initialForm, ...data});
            setUserData(response);
        } else {
            navigate('/unauthorized');
        }
    }

    const handleSubmit = async (values: any) => {
        const req: any = {
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.userPhone,
            // password: encryptString(values.password),
            // password2: encryptString(values.password),
            password: values.password,
            password2: values.password,
            registration_id: uid
        }
        setIsSubmit(true);
        const response = await post({url: APIMETHOD.user.REGISTER_USER, data: req});
        if(response) {
            ERROR_SUCCESS_MSG = 'User Registered Successfully !!';
            setIsSuccessError(true);
            setTimeout(() => navigate('/login'), 5000);
        }
    }
    const handleClose = () => {
        setIsSuccessError(false);
    }
    return (
        <>
        <Status_Dialog isOpen={isSuccesError}
            message={ERROR_SUCCESS_MSG}
            title={error?.message ? "Error" : "Success"}
            handleClose={handleClose}
        />
        <LoadingService open={loading} />
        <Box sx={{p: 2, textAlign: 'center'}}><h5>Complete Registration</h5></Box>
        <div className='row d-flex justify-content-center'>
            <div className='col-xs-12 col-sm-6 col-md-4 registration-form'>
            <Formik
                enableReinitialize
                initialValues={initialForm}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                    // setSubmitting(true);
                //   handleSubmit(values);
                }}

                validationSchema={Yup.object().shape({
                    first_name: Yup.string()
                        .required('Please enter above field'),
                    last_name: Yup.string()
                        .required('Please enter above field'),
                    // userPhone: Yup.string()
                    //     .required('Please enter above field'),
                    password: Yup.string()
                        .required('Please enter password')
                        .min(8, 'Password is too short - should be 8 chars minimum.')
                        .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        'At least one uppercase letter, one lowercase letter, one number and one special character'),
                    password2: Yup.string()
                        .required(`Please confirm password`)
                        .equals([Yup.ref('password'), null], 'Passwords must match')
                })}
              >
                {(props: any) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    isValid
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'text'}
                                variant="standard"
                                label="Your First Name"
                                name="first_name"
                                onChange={handleChange}
                                helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'text'}
                                variant="standard"
                                label="Your Last Name"
                                name="last_name"
                                onChange={handleChange}
                                helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'text'}
                                variant="standard"
                                label="Your Phone No."
                                name="userPhone"
                                onChange={handleChange}
                                helperText={(errors.userPhone && touched.userPhone) && errors.userPhone}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'password'}
                                variant="standard"
                                label="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                helperText={(errors.password && touched.password) && errors.password}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'password'}
                                variant="standard"
                                label="Retype Password"
                                name="password2"
                                value={values.password2}
                                onChange={handleChange}
                                helperText={(errors.password2 && touched.password2) && errors.password2}
                                margin="dense"
                                size="small"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ }} fullWidth>
                            <TextField
                                type={'email'}
                                variant="standard"
                                label="Your Email"
                                name="email"
                                value={values.email}
                                margin="dense"
                                size="small"
                                disabled={true}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{  }} fullWidth>
                            <TextField
                                variant="standard"
                                label="Your Role"
                                name="userRole"
                                value={values.userRole}
                                onChange={handleChange}
                                margin="dense"
                                size="small"
                                disabled={true}
                        />
                        </FormControl>
                        <Button type="submit" disabled={isSubmit} className='my-3 text-uppercase gradientRoseYellowBtn' fullWidth variant="contained">Save & Proceed</Button>
                      
                    </form>
                  );
                }}
              </Formik>
              </div>
        </div>
        
        </>
    );
}

export default RegisterUser;