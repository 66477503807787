import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  TextField,
  FormHelperText,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import { getUserInfo } from "../../utils/utils";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "../../shared/Toser/ToastManager";
import LoadingService from "../../shared/loader";
import { Editor } from "react-draft-wysiwyg";
import { useNavigate } from "react-router-dom";

interface UpchargeProp {
  upchargeDetails: any;
}

function SetUpcharge(props: UpchargeProp) {
  const { upchargeDetails } = props;
  const { post, get, error, loading } = useAxios({ showLoader: true });
  const [apiError, setApiError] = useState<any>(null);
  const [isChecked, setIsChecked] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  const [upcharge, setupcharge] = useState(0);
  const navigate = useNavigate();
  const userInfo = getUserInfo();
  const handleChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  useEffect(() => {
    if (upchargeDetails?.upCharge !== undefined) {
      setupcharge(upchargeDetails?.upCharge);
    }
  }, [upchargeDetails?.upCharge]);
  useEffect(() => {
    if (error) {
      const _errors: any = error?.exactErr?.invoice;
      if (_errors && !_errors?.detail) {
        setApiError(_errors);
      } else if (error?.exactErr?.email) {
        toast.show({ severity: "error", message: error?.exactErr?.email[0] });
      } else {
        if (error?.message === "Low amount") {
        } else {
          toast.show({ severity: "error", message: error?.message });
        }
      }
    }
  }, [error]);
  const createUpcharge = async () => {
    // const projects = await createProjects(mapCreateClientRequest(requestData.client_details));

    const CreateRequest = {
      id: upchargeDetails?.invoice_id,

      up_charge: upcharge,
    };

    // requestData.project_id = projects.project_id;
    const result: any = await post({
      url: APIMETHOD.INVOICE.SET_UPCHARGE.up_charge,
      data: CreateRequest,
    });

    if (result) {
      navigate("/preview-invoice/" + upchargeDetails?.invoice_id);
      window.location.reload();

      const a = result;
      setApiError(null);
    }
  };
  
  const onlyNumbers = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key;
      const keyCode = e.keyCode || e.which;
      // Allow numeric characters (0-9) and the backspace key
      if (!/^[0-9.]+$/.test(key) && keyCode !== 8) {
        e.preventDefault();
      }
    },
    []
  );
  const handleSubmit = async (val: any) => {
    await createUpcharge();
    getInvoiceDetails();
  };
  //  useEffect(() => {
  //     if (upchargeDetails?.invoice_id) {
  //       const getInvoiceDetails = async () => {
  //         const response: any = await get({
  //           url: APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] +  upchargeDetails?.invoice_id + "/",
  //         });
  //         if (response) {
  //           setInvoiceDetails(response);
  //         }
  //       };
  //       getInvoiceDetails();
  //     }
  //   }, [upchargeDetails?.invoice_id]);
  const getInvoiceDetails = async () => {
    const response: any = await get({
      url:
        APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] +
        upchargeDetails?.invoice_id +
        "/",
    });
    if (response) {
      setInvoiceDetails(response);
    }
  };

  return (
    // <div className="bg-white mt-5 position-fixed"  style={{ width:"23%", position: 'sticky', top: 50, transform: 'translateY(25px)' }}>
      
      <div className="bg-white p-3">
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleSubmit(values);
          }}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,

              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="d-flex flex-column"
              >
                <LoadingService open={loading} />

                <FormControlLabel
                  name="attached_pdf"
                  onChange={handleChange}
                  control={<Checkbox size="small" sx={{}} defaultChecked />}
                  label="Percentage Upcharge"
                />
                <TextField
                  name=""
                  variant="outlined"
                  label="Upcharge"
                  onChange={(e: any) => setupcharge(e.target.value)} // Update upcharge state
                  onKeyDown={onlyNumbers}
                  value={upcharge}
                  size="small"
                  disabled={!isChecked}
                />

                <DialogActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <Button type="submit"  disabled={!isChecked}>Submit</Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </div>
    // </div>
  );
}
export default SetUpcharge;
