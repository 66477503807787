import React, { useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { useParams, } from 'react-router-dom';


function ToggleCrewSizeDescription(props:any) {
    const { invoiceDetails } = props;
    const { id } = useParams();
    const { put } = useAxios({showLoader: true});
    const [offCrewSizeDescription, setOffCrewSizeDescription] = useState(false);

    useEffect(() => {
        console.log("Invoice Details from Toggle Crew Size: ", invoiceDetails)
        setOffCrewSizeDescription(invoiceDetails?.show_crew_size_description)
    }, [invoiceDetails])

    const handleToggleDescriptionChange = (evt:any) => {
        if (offCrewSizeDescription == true) {
            doCrewSizeDescriptionToggling(false);
            setOffCrewSizeDescription(false);
        } else {
            doCrewSizeDescriptionToggling(true);
            setOffCrewSizeDescription(true);
        }
    }

    const doCrewSizeDescriptionToggling = async (show: boolean) => {
        const requestData = {
            show_desc: show
        }

        try {
            const result: any = await put({ 
                url: APIMETHOD.INVOICE.CREW_SIZE_DESC_TOGGLE + id,
                data: requestData 
            });

            console.log(result);

            if (result.success) {
                window.location.reload()
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="bg-white py-3 px-3 mt-4">
            <FormControlLabel
                className="mt-4"
                control={
                    <Checkbox
                        id="toggleDescription"
                        // name="client_details.new_client"
                        onChange={handleToggleDescriptionChange}
                        checked={offCrewSizeDescription}
                        size="small"
                    />
                }
                label="Apply Free Prompter, Audio and Lighting"
            />
        </div>
    );
}

export default ToggleCrewSizeDescription;