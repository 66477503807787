import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements, 
} from "@stripe/react-stripe-js";
import { Button, Box, Checkbox } from "@mui/material";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { APIMETHOD } from "../../core/constant";
import { useAxios } from "../../core/useAxios";
import { FORMAT_SLASH_MM_DD_YYYY } from "../../utils/dateformat";
import CheckoutImage from "../../assets/images/checkout.jpg";
import LoadingSpinner from "./spinner";

const styles = {
  tableHeader: {
    fontSize: 10
  },
  tableCell: {
    fontSize: 12
  },
  error: {
    color: "red",
    marginTop: 10,
    fontSize: 12
  },
  submit: {
    background:
      "transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 10px 20px #0000000D",
    borderRadius: "4px",
    opacity: 1,
    color: "#ffffff",
    px: 3,
    fontFamily: "VerdanaBold"
  },
  checkoutImageStyles: {
    height: 350,
    width: 350,
    objectFit: "cover",
    borderRadius: "5px"
  }
};

const decryptData = (encryptedData, key, iv) => {
  const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);
  const cipher = CryptoJS.AES.decrypt(
    { ciphertext },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Base64.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );

  return cipher.toString(CryptoJS.enc.Utf8);
};

function CheckoutForm({ clientSecret, invoiceId }) {
  const { get } = useAxios({ showLoader: true });
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [isLoading, setIsLoading]=useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const successUrl = `${APIMETHOD.CHECKOUT.SUCCESS}?invoice_id=${invoiceId}`;
  const SOMETHING_WENT_WRONG = "Something went wrong!";
  const [errorMessage, setErrorMessage] = useState(null);
  const [acceptConsent, setAcceptConsent] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);


    if (elements == null) {
      setErrorMessage(SOMETHING_WENT_WRONG);
      return;
    }

    if (stripe == null) {
      setErrorMessage(SOMETHING_WENT_WRONG);
      return;
    }

    // Trigger form validation and wallet collection
    let { submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: successUrl
      }
    });
    setIsLoading(false)

    if (error) 
      setErrorMessage(error.message);
      setIsLoading(false)
  };

  useEffect(() => {
    if (invoiceId) {
      const getInvoiceDetails = async () => {
        const response = await get({
          url: `${APIMETHOD.INVOICE.PAYMENT_SCHEDULE}?invoice_id=${invoiceId}`
        });
        console.log("Invoice Details: ", response)
        if (response) {
          setPaymentSchedule(response);
        }
      };
      getInvoiceDetails();
    }
  }, [invoiceId]);

  return (
    <div className="container">
        <div style={{ margin: "5%", padding: "30px", background: "white" }}>
          <Box
            style={{
              borderBottom: "1px solid #f7f7f7",
              paddingBottom: "10px",
              marginBottom: "30px"
            }}
          >
            <h4>Checkout</h4>
          </Box>
          <div className="row justify-content-between mb-1">
            {paymentSchedule.length ? (
              <div className="col-sm-5">
                <div
                  className="mb-3"
                  style={{
                    fontWeight: "bold"
                  }}
                >
                  Payment Schedule
                </div>
                <table className="table" style={{ width: "100%" }}>
                  <thead className="table-dark">
                    <tr>
                      <th style={styles.tableHeader}>CHARGE DATE</th>
                      <th style={styles.tableHeader}>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentSchedule.map((item, index) => {
                      return (
                        <tr key={index + "item"}>
                          <td style={styles.tableCell}>
                            {FORMAT_SLASH_MM_DD_YYYY(item?.payment_date)}
                          </td>
                          <td style={styles.tableCell}>$ {item?.amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="mx-auto text-center">
                  <img src={CheckoutImage} style={styles.checkoutImageStyles} />
                </div>
              </div>
            ) : null}
            <div className={paymentSchedule.length ? "col-sm-6" : "col-sm-12"}>
              <form className="form">
                <PaymentElement />
                <div>
                  <Checkbox
                    id="new_client"
                    // name='client_details.new_client'
                    onChange={(evt) => setAcceptConsent(evt.target.checked)}
                    checked={acceptConsent}
                    size="small"
                  />
                  <span>I agree to Voyage Pro <a href="https://voyagevideos.com/service" target="__blank">Terms & Conditions</a></span>
                </div>
                <Button
                  style={{ marginTop: 15, width: "100%", fontSize: 14 }}
                  className="btn-custom"
                  // sx={styles.submit}
                  loading={isLoading}
                  disabled={!stripe || !elements || isLoading || !acceptConsent}
                  onClick={e => handleSubmit(e)}
                > 
                  {isLoading ? <LoadingSpinner/>: 
                  "SAVE CARD"}
                </Button>
              </form>
              {/* Show error message */}
              {errorMessage && <p style={styles.error}>{errorMessage}</p>}
            </div>
          </div>
        </div>  
    </div>
  );
}

const Checkout = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const invoiceId = decodeURIComponent(searchParams.get("invoice_id")).replace(
    / /g,
    "+"
  );
  const signature = decodeURIComponent(searchParams.get("signature")).replace(
    / /g,
    "+"
  );
  const key = process.env.REACT_APP_STRIPE_KEY || "ISSimpv3j54L2jGITU7L8w==";
  const iv = process.env.REACT_APP_STRIPE_IV || "ODo9xT/240fxlBTXsEF66w==";
  var stripe_public_key="pk_live_51MmKGGJsCV9bhKBuWoLVq1cYf7KGsUGGoEzrAwtqHtZbF9aK4nKoLnjbpPzknVtEjcckKfanFUWMfQhyZG7pFzax00XwE4lq3p";
  const domain = window.location.host;
  if(domain.includes("dev.voyageproapp.com"))
  {
    stripe_public_key="pk_test_ZImJnIV9omOLEHxu2oF8LtG0";
  }

  const promise =
    process.env.REACT_APP_STRIPE_PROMISE ||
    stripe_public_key;

  const decryptedInvoiceId = decryptData(invoiceId, key, iv);
  const decryptedSignature = decryptData(signature, key, iv);
  const stripePromise = loadStripe(promise);
  const options = {
    clientSecret: decryptedSignature,

  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        clientSecret={decryptedSignature}
        invoiceId={decryptedInvoiceId}
      />
    </Elements>
  );
};

export default Checkout;
