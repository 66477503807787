import { createContext, useReducer } from 'react';

const tabReducer = (state:any, action:any) => {
  switch (action.type) {
    case 'SET_TAB': {
      return {
        setTab: action.payload,
      };
    }
    default: {
      return {
        setTab: state,
      };
    }
  }
};
type INITIAL_STATE_TYPE={setTab:string};
const INITIAL_STATE = {
  setTab: 'inquiry',
};

export const TabContext = createContext<{state:INITIAL_STATE_TYPE, dispatch:React.Dispatch<any>}>({
  state:INITIAL_STATE,
  dispatch: ()=> null
})

export const TabContextProvider = ({ children }:any) => {
  const [state, dispatch] = useReducer(tabReducer, INITIAL_STATE);

  return (
    <TabContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
