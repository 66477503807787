import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import LoadingService from "../../../shared/loader"
import { useState } from "react"
import { useAxios } from "../../../core/useAxios"
import { APIMETHOD } from "../../../core/constant";

interface ManuallyPayInterface {
    isOpen: boolean;
    invoice: any;
    paymentCompleted?: any
    handleClose?: any;
}

enum PaymentTypeEnum {
    PARTIALLY_PAY   = "partial_payment",
    FULL_PAY       = "fully_paid"
}

export default function ManuallyPay(props: ManuallyPayInterface) {
    const { isOpen, invoice, handleClose, paymentCompleted } = props;
    
    const { post } = useAxios();
    
    const [isLoading, setIsLoading] = useState(false);
    const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(PaymentTypeEnum.FULL_PAY);
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async () => {
        try {
            const api_url = APIMETHOD.INVOICE.MANUALLY_BOOKED.pay.replace(":invoice_id", invoice?.id)
            const formData = {
                type: paymentType,
                amount: amount,
            }

            const res = await post({
                url: api_url,
                data: formData
            });

            console.log("Manually Booked Response: ", res)
            
            if (res?.success) {
                invoice.row.total = parseFloat (invoice.row.total) - parseFloat (res.result.amount);
                let fullyPaid = false;
                if (invoice.row.total < 1) {
                    fullyPaid = true;
                }
                handleClose(fullyPaid)
            } else {
                setError(res.message)
            }
        } catch (error) {
            console.error("Error: ", error)
        }
    }

    const handleRadioChange = (evt:any) => {
        setPaymentType(evt.target.value);
    }

    const handleCloseTask = () => {
        handleClose();
    }

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog 
                PaperProps={{style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' } }} 
                open={isOpen} 
                onClose={handleCloseTask} 
                fullWidth={true}
            >
                <DialogContent>
                    <h5 className='p-2'>Manually Mark Pay: {invoice?.row?.project_name} </h5>
                                
                    <div className="py-2">
                        <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
                            <RadioGroup
                                row
                                name={""}
                                onChange={handleRadioChange}
                                value={paymentType}
                            >
                                <FormControlLabel value={PaymentTypeEnum.FULL_PAY} control={<Radio />} label="Mark Fully Paid" />
                                <FormControlLabel value={PaymentTypeEnum.PARTIALLY_PAY} control={<Radio />} label="Make Partial Pay" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {paymentType == PaymentTypeEnum.PARTIALLY_PAY && (
                        <div>
                            <div className="text-right fw-bold fs-14 text-success">
                                <span>Balance: </span>
                                <span>${invoice?.row?.total}</span>
                            </div>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    name="amount"
                                    type="text"
                                    variant="outlined"
                                    label="Amount To Partially Pay"
                                    onChange={(evt:any) => setAmount(evt?.target?.value)}
                                    value={amount}
                                    margin="dense"
                                    size="small"
                                    // helperText={!!error}
                                />
                            </FormControl>
                            <span className="text-danger">{ error }</span>
                        </div>
                    )}
                </DialogContent>
                <DialogActions sx={{borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2}}>
                    <Button className="btn btn-default fw-bold" onClick={handleCloseTask}>Cancel</Button>
                    <Button className="btn btn-custom fw-bold" onClick={() => {handleSubmit()}}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}