import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { EMAIL_HELPER_TEXT, getUserInfo } from '../../utils/utils';
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import LoadingService from '../../shared/loader';
import { mapCreateClientRequest } from '../Invoice/modal';
import { toast } from '../../shared/Toser/ToastManager';
import { isNil } from 'lodash';

interface UserDiaglogProps {
    isOpen: boolean,
    addClients: any,
    clientUpdateData?: any;
    handleClose: (data: any) => void
}

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }
    
}

function AddClients(props: UserDiaglogProps) {
    const { isOpen, clientUpdateData, handleClose, addClients } = props;
    const [email, setEmails] = useState<any>('');
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<any>('');
    const [updateMode, setUpdateMode] = useState(false);
    const [errors, setErrors] = useState<any>(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const { post, put, patch, error } = useAxios({showLoader: true});
    const userInfo: any = getUserInfo();

    const handleCloseTask = () => {
        setUpdateMode(false)
        setEmails("");
        setName("");
        setPhone("");
        handleClose(false);
    };

    useEffect(() => {
        if(error) {
            const _errors = error?.exactErr;
            if(_errors?.email && _errors?.email[0]){
                toast.show({severity: 'error', message: error?.exactErr?.email[0] || error.message});
            } else if(_errors?.name && _errors?.name[0]){
                toast.show({severity: 'error', message: error?.exactErr?.name[0] || error.message});
            } else if(_errors?.phone && _errors?.phone[0]){
                toast.show({severity: 'error', message: error?.exactErr?.phone[0] || error.message});
            }
            
        }
    }, [error]);

    useEffect(() => {
        const data = clientUpdateData?.row;;
        console.log("Client Update Data: ", data);
        if (!isNil(data)) {
            setEmails(data.email);
            setName(data.name);
            setPhone(data.phone);
            setUpdateMode(true);
        }

    }, [clientUpdateData]);

    const handleSubmit = async () => {
        const request: any = {
            name: name,
            email: email,
            phone: phone
        };
        setIsLoading(true);
        const response = await createProjects(request);
        console.log("Client update response: ", response);
        setIsLoading(false);
        if(response) {
            const id = updateMode? clientUpdateData?.row?.id : null
            addClients(response, id);
            handleCloseTask();
            toast.show({severity: 'success', message: `Client ${ updateMode? "updated" : "added" } successfully !!!`});
        }
    }

    const createProjects = async (clientDetails: any) => {
        let method = updateMode? patch : post;
        if (updateMode) {
            clientDetails.id = clientUpdateData?.row?.id;
        }

        console.log("Client Input: ", clientDetails);

        return await method({
            url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role], 
            data: clientDetails
        });
    }

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog PaperProps={{style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' }   }} open={isOpen} onClose={handleCloseTask} fullWidth={true}>
                <DialogContent>
                    <h5 className='p-2'> {updateMode? "Update" : "Add"} Client</h5>
                        {error?.exactErr?.email && error?.exactErr?.email[0] &&
                            <Box sx={{pb: 2}}>
                                <small className='errors_color'>'Email': { error?.exactErr?.email[0]}</small>
                            </Box>
                        }
                        {error?.exactErr?.name && error?.exactErr?.name[0] &&
                            <Box sx={{pb: 2}}>
                                <small className='errors_color'>'Name': {error?.exactErr?.name[0]}</small>
                            </Box>
                        }
                        {error?.exactErr?.phone && error?.exactErr?.phone[0] &&
                            <Box sx={{pb: 2}}>
                                <small className='errors_color'>'Phone': {error?.exactErr?.phone[0]}</small>
                            </Box>
                        }
                        
                                
                    <Box>
                        <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                            <TextField
                                margin="dense"
                                id="clientname"
                                label="Name"
                                type="text"
                                value={name}
                                onChange={(e: any) => {setName(e.target.value)}}
                                fullWidth
                                variant="standard"
                                // helperText={EMAIL_HELPER_TEXT}
                            />
                        </FormControl>
                    </Box>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                                <TextField
                                    margin="dense"
                                    id="clientemail"
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e: any) => {setEmails(e.target.value)}}
                                    fullWidth
                                    variant="standard"
                                    // helperText={EMAIL_HELPER_TEXT}
                                />
                            </FormControl>
                        </div>
                        <div className='col-sm-6'>
                            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                                <TextField
                                    margin="dense"
                                    id="clientphone"
                                    label="Phone"
                                    type="text"
                                    value={phone}
                                    onChange={(e: any) => {setPhone(e.target.value)}}
                                    fullWidth
                                    variant="standard"
                                />
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions sx={{borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2}}>
                    <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
                    <Button sx={style.submit} onClick={() => {handleSubmit()}}>{ updateMode? "Save" : "Submit"}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddClients;