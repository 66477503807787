import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoadingService from '../../shared/loader';
import moment from 'moment';
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }
    
}

interface UserDiaglogProps {
    isOpen: boolean,
    title?: any,
    price?: any,
    newUpdate: (data: any) => any,
    index?: number,
    handleClose: (data: any) => void
}

interface requestdataInterface {
    key?: String,
    value: String
}

function UpdatePrice(props: UserDiaglogProps) {
    const { isOpen, title, handleClose, newUpdate, price } = props;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ amount, setAmount ] = useState('');
    const [ priceId, setPriceId ] = useState(null);
    const [pricingKey, setPricingKey] = useState("");
    const { put, post } = useAxios({showLoader: true});
    const { id } = useParams();

    useEffect(() => {
        setAmount(price.value)
    }, [price])

    const handleSubmit = async () => {

        try {
            const requestdata: requestdataInterface = {
                value: amount
            }
    
            let slug="";
            let method = post;
    
            if (price?.key != null) {
                slug = price?.key.replace(" ", "-")
                console.log("Slug: ", slug)
                method = put
            } else {
                requestdata.key = pricingKey;
            }
            
            const result: any = await method({ 
                url: APIMETHOD.INVOICE.PRICING + slug, 
                data: requestdata 
            });
    
            console.log("Price Update", result);
            newUpdate(result)
            handleCloseTask()
        } catch (error) {
            console.log(error);
        }
    }

    const handleCloseTask = () => {
        handleClose(false);
    };

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog PaperProps={{style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' }   }} open={isOpen} onClose={handleCloseTask} fullWidth={true}>
                <DialogContent>
                    <h5 className='p-2 text-capitalize'>{title ?? "Add New Rate"}</h5>
                                
                    <Box>
                        {price?.key == null && (
                            <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                                <TextField
                                    margin="dense"
                                    id="title"
                                    name='title'
                                    type="text"
                                    label={'Title'}
                                    value={pricingKey}
                                    onChange={(evt) => setPricingKey(evt.target.value)}
                                    fullWidth
                                    variant="standard"
                                    inputProps={{
                                        autoComplete: "off", // Disable autocomplete
                                        // startAdornment: (
                                        //     <InputAdornment position='start'>
                                        //         <AttachMoneyIcon />
                                        //     </InputAdornment>
                                        // )
                                    }}
                                />
                            </FormControl>
                        )}

                        <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                            <TextField
                                margin="dense"
                                id="amount"
                                name='amount'
                                type="text"
                                label={'Amount'}
                                value={amount}
                                onChange={(evt) => setAmount(evt.target.value)}
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    autoComplete: "off", // Disable autocomplete
                                    // startAdornment: (
                                    //     <InputAdornment position='start'>
                                    //         <AttachMoneyIcon />
                                    //     </InputAdornment>
                                    // )
                                }}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2}}>
                    <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
                    <Button sx={style.submit} onClick={() => {handleSubmit()}}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default UpdatePrice;