import React,{useState, useEffect} from 'react';
import { Card, CardHeader, CardContent, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import { APIMETHOD } from '../../../core/constant';
import { useAxios } from '../../../core/useAxios';
import { getUserInfo } from '../../../utils/utils';
import { number } from 'yup';

const CardWrapper = styled(Card)({
  width: '300px', // Reduce the card width
  margin: 'auto',
  backgroundColor: 'white',
  maxHeight: '300px', // Reduce the card height
  overflowY: 'auto',
  paddingTop: '0px',
  marginTop: '0px',
});
const CardHeaderWrapper = styled(CardHeader)({
  backgroundColor: 'white',
  color: 'black',
  font: "menu",
  textAlign: 'start',
  position: 'sticky',
  top: '0',
  zIndex: '1',
  paddingBottom: '0px',
  fontSize: '2rem', // Set your desired font size here
  fontWeight: 'bold', // Set font weight to bold for emphasis
});
const HeaderIconWrapper = styled(Avatar)({
  width: '3rem', // Reduce the avatar size
  height: '3rem', // Reduce the avatar size
  backgroundColor: 'white',
  color: "black"
});

const CardContentWrapper = styled(CardContent)({
  paddingTop: '0px'
})
interface Bookings {
  data: number;
 
}

const UpcomingValueWidget: React.FC = () => {
    const { get, put, loading } = useAxios({ showLoader: true });
    const currentDateTime = moment();
    const userInfo = getUserInfo();


    const [upcomingbookings, setUpcomingBookings]:any[] = useState([]);

    const [upcomingpayments, setUpcomingPayments] = React.useState('Week');
    const [isLoading, setIsLoading] = useState(false);


    const handleChange = (event: SelectChangeEvent) => {
      setIsLoading(true);
      setUpcomingPayments(event.target.value as string);
    };

    const getClients = async () => {
        const result = await get({ url: APIMETHOD.PAYMENTS.GET[userInfo.role] });
        let bookingsarray:any[]=[]
        let res=result.data
        let upcomingsum;
        
        //get values for upcoming payments
        let upcomingPayments = res.filter((payment: any) => {
                let flag = false 
                if (payment?.charge_search) {
                    const unixDateTime = moment(payment.charge_search, 'MM-DD-YYYY');
                    const isDueDateGreater = unixDateTime.isAfter(currentDateTime);
                    flag = isDueDateGreater
                }
                return flag
        })  

        upcomingPayments.map((r:any)=>{
        let depositdates = new Date(r.deposit_search)
        let totals = r.total

        let today = new Date().toISOString().slice(0, 10)
        let startDate = depositdates.toISOString().slice(0, 10)
        const diffInMs   = new Date(today).getTime()- new Date(startDate).getTime()
        let diffInDays = diffInMs / (1000 * 60 * 60 * 24);


        //yearly paid payments
        if(diffInDays>=-365 && upcomingpayments === "Year"){
              bookingsarray.push(totals)
          }
        if(diffInDays>=-30 && upcomingpayments === "Month"){
            bookingsarray.push(r.total)
          }
        if(diffInDays>=-7 && upcomingpayments === "Week"){
            bookingsarray.push(r.total)
          }
        })
        
        let paidsum = (Math.round(bookingsarray.reduce((x:any, y:any) => x + y, 0)*100)/100).toLocaleString();
        setUpcomingBookings(paidsum)
        setIsLoading(false);   

        
      }
      useEffect(() => {
        getClients()
        
      }, [upcomingpayments])

    
      return(
      <CardWrapper>
        <CardHeaderWrapper
            avatar={<HeaderIconWrapper> <AttachMoneyOutlinedIcon fontSize="large" /> </HeaderIconWrapper>}
            title={<Typography variant="h5">Upcoming Value</Typography>}
          />
          <div style={{marginLeft:30, marginTop:20}}>
            <FormControl style={{width:200}}>
            <InputLabel id="demo-simple-select-label">This Week</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={upcomingpayments}
              label="Paid Payments"
              defaultValue={"Week"}
              onChange={handleChange}
            >
              <MenuItem value={"Year"}>In 365 Days</MenuItem>
              <MenuItem value={"Month"}>In 30 Days</MenuItem>
              <MenuItem value={"Week"}>In 7 Days</MenuItem>
            </Select>
          </FormControl>
        <CardContentWrapper>
          <div>
            {isLoading ? <p>Loading...</p> : null}

            <p style={{fontSize:"1.5rem", marginTop:20}}>$ {upcomingbookings}</p>
          </div>
        </CardContentWrapper>
          </div>
      </CardWrapper>
      )
    }

export default UpcomingValueWidget;