import React from "react";
import "../../assets/css/spinner.css";

export default function LoadingSpinner (){
  return(
    <div className="spinner-container">
      <div className="loading-spinner">

      </div>
    </div>

  )

}