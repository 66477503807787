import React, { useEffect } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as lockIcon } from "../../assets/icon/Icon-awesome-user-alt-u.svg";
import { ReactComponent as userIcon } from "../../assets/icon/Icon-awesome-user-alt-l.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import "./Login.css";
import captureimage from "./../../assets/images/logincapture.svg";
import loginRoundImage from "./../../assets/images/loginbubble.svg";
import { USER_INFO } from "./data";
import { useAppStateContext } from "../../state/provider";
import { loggedIn, loginFailed } from "../../state/auth/actions";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import { encryptString } from "../../utils/utils";
import Cookies from "js-cookie";
import { toast } from "../../shared/Toser/ToastManager";
import LoadingService from "../../shared/loader";

const UserIcon: any = userIcon;
const LockIcon: any = lockIcon;

const MyVideo: any =
  "https://elasticbeanstalk-us-east-1-238707838319.s3.amazonaws.com/WebsiteLandingReelUpdate2022.mp4";

const Login = () => {
  const { post, error, loading } = useAxios({ showLoader: true });
  const navigate: any = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { state, dispatch } = useAppStateContext();

  useEffect(() => {
    if (error) {
      toast.show({
        severity: "error",
        message: error?.message,
      });
      console.log(JSON.stringify(error),"errorLogin");
    }
  }, [error]);

  const handleSubmit = async () => {
    try {
      if (email && password) {
        const data = {
          username: email,
          // password: encryptString(password)
          password: password,
        };
        const response: any = await post({
          url: APIMETHOD.user.LOGIN,
          data: data,
        });
        if (response) {
          Cookies.set("accessToken", response?.token);
          const uinfo = {
            email: response.email,
            is_active: response.is_active,
            full_name: response.full_name,
            first_name: response.first_name,
            last_name: response.last_name,
            role: response.role,
          };
          localStorage.setItem("userInfo", JSON.stringify(uinfo));
          dispatch(loggedIn(response));
          navigate("/clients");
        } else {
          navigate("/login");
        }
  
        // const userInfo = USER_INFO.filter((x: any) => x.email === email);
        // if(userInfo) {
        //     dispatch(loggedIn(userInfo[0]));
        //     navigate('/');
        // } else {
        //     // dispatch(loginFailed('Invalid Email or Password'));
        //     navigate('/login');
        // }
      } else {
        alert("Wrong Credentials");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <LoadingService open={loading} />
      <div className="LoginBlock wrapperLoginContainer">
        <div className="video-background">
          <video autoPlay muted width={"100%"} id="myVideo">
            <source src={MyVideo} type="video/mp4" />
          </video>
        </div>
        {/* <video autoPlay={true}>
            <source src={'./../../assets/video_login.mp4'} type="video/mp4" />
        </video> */}
        <div className="d-flex align-items-center justify-content-center h-100 login_block">
          <div className="col-lg-6 col-md-6 col-sm-5 d-none d-sm-block text-white loginLeftBlock">
            <div className="col-lg-8 offset-lg-2 fw-bold">
              <div className="h2 welcomeMsg">Welcome To</div>
              <div className="h1 text-uppercase">voyage pro</div>
              <p className="h5 text-capitalize">
                Arizona's Most Popular Content Creation Service
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-5 col-md-5 col-sm-6 position-relative loginRightBlock">
            <div className="position-absolute w-100 h-100 blockImage">
              <img src={loginRoundImage} className="img-fluid" alt="" />
            </div>
            <div className="w-100">
              <div className="col-10 captureImage">
                <img src={captureimage} alt="" width={"101%"} />
              </div>
              <div className="col-10 offset-1 bg-white p-5 pt-4 login_screen">
                <div className="h5 pb-4 text-center text-uppercase">
                  User Login
                </div>
                {error?.exactErr?.non_field_errors &&
                  error?.exactErr?.non_field_errors[0] && (
                    <Box sx={{ pb: 2 }}>
                      <small className="errors_color">
                        {error?.exactErr?.non_field_errors[0]}
                      </small>
                    </Box>
                  )}
                <Box>
                  <div className="col-12 mb-3">
                    <TextField
                      type="email"
                      id="email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Id"
                      size="small"
                      autoComplete="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ width: "1rem" }}
                          >
                            <UserIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      type="password"
                      id="password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      size="small"
                      autoComplete="current-password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ width: "0.9rem" }}
                          >
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Box>
                <FormControlLabel
                  className="text-capitalize remember-me"
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="my-3 text-uppercase gradientRoseYellowBtn btnfamily"
                  fullWidth
                  variant="contained"
                >
                  Login
                </Button>
                <div className="col-12 text-center">
                  <Link
                    href="#"
                    className="text-decoration-none text-black-50 text-center text-capitalize"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
