import React, { useState, useEffect, useCallback } from 'react';
import { Button, Checkbox, DialogActions, FormControl, FormControlLabel, TextField, FormHelperText, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { getUserInfo } from '../../utils/utils';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { toast } from '../../shared/Toser/ToastManager';
import LoadingService from '../../shared/loader';
import { Editor } from 'react-draft-wysiwyg';

let emailContent: any = 'Thank you for sharing the information about your project! Attached is the Proposal for your project. Feel free to let me know if you have any questions. If you are all set, you can book your project by selecting the "Book your Project Here" option at the bottom of the PDF. We are looking forward to getting started!';

interface EmailProps {
  handleCloseEmail: (data: any) => void,
  emailDetails: any
}

interface EmailDraft {
  content?: string;
  recipient?: string;
  carbonCopy?: string;
  subject?: string;
}

function SendEmail(props: EmailProps) {
  
  const { handleCloseEmail, emailDetails } = props
  const { post, error, loading } = useAxios({ showLoader: true });
  const [parseEmailData, setParsedEmailData] = useState<EmailDraft>({});
  const userInfo = getUserInfo();
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(ContentState.createFromText(emailContent)),
  );

  useEffect(() => {
    if (error) {
      const _errors = error?.exactErr?.attached_pdf;
      if (_errors) {
        toast.show({ severity: 'error', message: _errors[0] });
      } else {
        toast.show({ severity: 'error', message: error.message });
      }
      handleCloseEmail(false);
    }
  }, [error]);

  useEffect(() => {
    const emailData: string | null = localStorage.getItem(`email_data_${emailDetails.invoice_id}`);    
    if (emailData) {
      const parsedData: EmailDraft | null = JSON.parse(emailData);
      if (parsedData) {
        if(parsedData.content) {
          const blocksFromHTML = convertFromHTML(parsedData.content);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          setEditorState(EditorState.createWithContent(contentState));
        }
        setParsedEmailData(parsedData)
      }
    } 
  }, []);

  const handleCloseTask = () => {
    handleCloseEmail(false);
  };

  const handleSubmit = async (values: any) => {
    const request: any = {
      invoice_id: values.invoice_id,
      email: values.email,
      email_cc: values.email_cc ? values.email_cc.split(',') : [],
      email_subject: values.email_subject,
      email_body: values.email_body,
      attached_pdf: values.attached_pdf
    }

    const response: any = await post({ url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role] + 'send-invoice/', data: request });
    if (response) {
      if (response?.auth_url) {
        let link: any = document.createElement('a');
        link.href = response?.auth_url;
        link.target = '_blank';
        link.click();
        localStorage.removeItem(`email_data_${emailDetails.invoice_id}`)
        handleCloseEmail(false);
      } else {
        toast.show({ severity: 'success', message: response?.message });
        handleCloseEmail(false);
      }

    }

  }

  const onEditorStateChange = (editorState: EditorState, setFieldValue: any) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue('email_body', forFormik);
    setEditorState(editorState);
    saveEmailAsDraft({ content: forFormik });
  };

  const saveEmailAsDraft = useCallback(({ content, recipient, carbonCopy, subject }: EmailDraft) => {
   
    const oldData: EmailDraft | null = JSON.parse(localStorage.getItem(`email_data_${emailDetails.invoice_id}`) || 'null');
  
    let newData: EmailDraft = { content: '', recipient: '', carbonCopy: '', subject: '' };
  
    if (oldData) {

      newData = { ...oldData, ...(content && { content }), ...(recipient && { recipient }), ...(carbonCopy && { carbonCopy }), ...(subject && { subject }) };

    } else {

      newData = { content, recipient, carbonCopy, subject };

    }
    localStorage.setItem(`email_data_${emailDetails.invoice_id}`, JSON.stringify(newData));
  }, []);

  return (
    <div className="sf-email-wrapper position-fixed" style={{ position: 'sticky', top: 40, transform: 'translateY(25px)' }}>
      <Box sx={{ my: 2, textAlign: 'center' }} className="mt-0">
        <h5>Send us a email !</h5>
      </Box>
      <div className="bg-white p-3">
        <Formik
          enableReinitialize
          initialValues={{ 
            email:  parseEmailData?.recipient || emailDetails?.email, 
            email_cc: parseEmailData?.carbonCopy || '', 
            invoice_id: emailDetails.invoice_id, 
            client_id: emailDetails.client_id, 
            email_subject: parseEmailData?.subject || `${emailDetails.project} Proposal for Booking`, 
            email_body: emailContent, 
            attached_pdf: true 
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleSubmit(values);
          }}

          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Please enter above field'),
            attached_pdf: Yup.boolean().required('Please enter above field'),
            email_cc: Yup.array()
              .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) {
                  return value;
                }
                return originalValue ? originalValue.split(/[\s,]+/) : [];
              })
              .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
            email_subject: Yup.string()
              .required('Please enter email subject'),
            email_body: Yup.string()
              .required(`Email body can't be empty`),
          })}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue
            } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <LoadingService open={loading} />
                <FormControl variant="standard" sx={{}} fullWidth>
                  <TextField
                    variant="standard"
                    error={errors.email && touched.email}
                    label="To"
                    name="email"
                    value={values.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      saveEmailAsDraft({ recipient: event.target.value });
                    }}
                    onBlur={handleBlur}
                    helperText={(errors.email && touched.email) && errors.email}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
                <FormControl variant="standard" sx={{}} fullWidth>
                  <TextField
                    variant="standard"
                    error={errors.email_cc && touched.email_cc}
                    label="CC"
                    name="email_cc"
                    value={values.email_cc}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      saveEmailAsDraft({ carbonCopy: event.target.value });
                      
                    }}
                    onBlur={handleBlur}
                    helperText={(errors.email_cc && touched.email_cc) && errors.email_cc}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
                <FormControl variant="standard" sx={{}} fullWidth>
                  <TextField
                    variant="standard"
                    error={errors.email_subject && touched.email_subject}
                    label="Subject"
                    name="email_subject"
                    value={values.email_subject}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      saveEmailAsDraft({ subject: event.target.value });
                    }}
                    onBlur={handleBlur}
                    helperText={(errors.email_subject && touched.email_subject) && errors.email_subject}
                    margin="dense"
                    size="small"
                  />
                </FormControl>

                <FormControl variant="standard" sx={{}} fullWidth>
                  <Editor
                    // editorState={editorState}
                    editorStyle={{ lineHeight: '20px', padding: '10px', minHeight: '100px', border: '1px solid #F1F1F1', borderTop: 0 }}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    spellCheck
                    editorState={editorState}
                    onEditorStateChange={(editor: any) => onEditorStateChange(editor, setFieldValue)}
                  />
                  <FormHelperText>
                    {(errors.email_body && touched.email_body) && errors.email_body}
                  </FormHelperText>
                </FormControl>
                <FormControlLabel name='attached_pdf' onChange={handleChange} control={
                  <Checkbox
                    size='small'
                    defaultChecked
                  />
                } label="Attach PDF" />

                <DialogActions>
                  <Button
                    type="button"
                    className="outline"
                    onClick={handleCloseTask}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
export default SendEmail;