import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  TextField,
  FormHelperText,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAxios } from "../../core/useAxios";
import { getUserInfo } from "../../utils/utils";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toast } from "../../shared/Toser/ToastManager";
import LoadingService from "../../shared/loader";
import { Editor } from "react-draft-wysiwyg";
import { constants } from "../../core/constant";
import { APIMETHOD } from "../../core/constant";


const env = process.env.REACT_APP_ENV || "dev";



interface EmailProps {
  handleCloseEmail: (data: any) => void;
  emailDetails: any;
  clientDetails: any;
  emailRequestDetails: any;
  setEmailRequestDetails: (data: any) => void;
}

// Define the interface for the forwarded ref
export interface SendEmailToClientRef {
  checkingEmailValidations: () => Promise<boolean>;
}

interface EmailDraft {
  content?: string;
  recipient?: string;
  carbonCopy?: string;
  subject?: string;
}

const SendEmailToClient = forwardRef<SendEmailToClientRef, EmailProps>(
  (props, ref) => {
  const {
    handleCloseEmail,
    emailDetails,
    clientDetails,
    emailRequestDetails,
    setEmailRequestDetails,
  } = props;
  const { Id, clientId } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  // const checkoutLink =
  //   invoiceDetails?.payment_link ||
  //   `${APIMETHOD.CHECKOUT.INVOICE}?invoice_id=${clientDetails.invoice_id}`;
  const emailContent =
  `Hi {client_name}, this is a reminder that your payment is past due. Please view your invoice <a href="https://www.voyageproapp.com/preview-invoice/${clientDetails.invoice_id}">here</a> to proceed with your payment`;
  console.log(emailContent)
    const [isEmailLoaded, setIsEmailLoaded] = useState(false);
    const { get, error, loading } = useAxios({ showLoader: true });
    const [parseEmailData, setParsedEmailData] = useState<EmailDraft>({});
    const userInfo = getUserInfo();
    // const [emailContent,setEmailContent] = useState()
    const formikRef = useRef<any>(null);
    const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(ContentState.createFromText(emailContent))
    );
    const [replacement, setReplacement] = useState();
    const [hasValidationErrors, setHasValidationErrors] =
      React.useState<boolean>(false);
    useEffect(() => {
      if (error) {
        const _errors = error?.exactErr?.attached_pdf;
        if (_errors) {
          toast.show({ severity: "error", message: _errors[0] });
        } else {
          toast.show({ severity: "error", message: error.message });
        }
        handleCloseEmail(false);
      }
    }, [error]);

    useEffect(() => {
        if (Id) {
          const getInvoiceDetails = async () => {
            const response: any = await get({
              url: APIMETHOD.INVOICE.CREATE_INVOICE[userInfo?.role] + Id + "/",
            });
            console.log(response)
            if (response) {
              setInvoiceDetails(response);
            }
          };
          getInvoiceDetails();
        }
      }, [Id]);
//     useEffect(() => {
//       let replacementsTemp: any = {
//         client_description: `<p>Description = ${
//           emailDetails && emailDetails.description
//             ? emailDetails.description
//             : "Not Provided"
//         } </p>`,
//       };
//       // if (clientDetails) {
//       //   replacementsTemp["client_description"] =
//       //     "<p>Amount: {amount}</p>\n" +
//       //     "<a href='{webapp_link}'>Link to response page</a>";
//       //   replacementsTemp["project_description"] =
//       //     "<p>{p_description}</p>\n<p>Project Name = {p_name}</p><p>Filming days = {p_filming_days}</p><p>Category = {p_category}</p>";
//       // }
//       let modifiedString: string = emailContent.replace(
//         /{([^}]+)}/g,
//         (match: any, key: any) => replacementsTemp[key] || match
//       );
//       replacementsTemp = {
//         client_name: emailDetails.client_name,
//         client_email_address: emailDetails.client_email,
//         webapp_link: `${constants.PAGEURLS.INQUIRE_RESPONSE[env]}/$
// /${emailRequestDetails?.email ? emailRequestDetails.email : null}/${
//           emailDetails.parject_manager_id
//             ? emailDetails.parject_manager_id
//             : null
//         }/${clientDetails ? clientDetails.id : null}`,
//       };
//       if (clientDetails) {
//         replacementsTemp["invoice_url"] = clientDetails.invoice_url;
//         replacementsTemp["amount"] = clientDetails.amount;
//         replacementsTemp["invoice_id"] = clientDetails.invoice_id;
//       }
//       modifiedString = modifiedString.replace(
//         /{([^}]+)}/g,
//         (match: any, key: any) => replacementsTemp[key] || match
//       );

//       const blocksFromHTML = convertFromHTML(modifiedString);
//       // console.log("blocksFromHTML::",blocksFromHTML)
//       const contentState = ContentState.createFromBlockArray(
//         blocksFromHTML.contentBlocks,
//         blocksFromHTML.entityMap
//       );
//       setEditorState(EditorState.createWithContent(contentState));
//       // console.log("email_body in hii this useEffect::",modifiedString)
//       setEmailRequestDetails({
//         email: emailDetails.client_email,
//         email_cc: "",
//         // client_id: emailDetails.client_id,
//         email_subject: `Overdue payment reminder for ${emailDetails.client_name}`,
//         email_body: emailContent,
//         attached_pdf: false,
//       });
//     }, []);

    useEffect(() => {
      if (emailDetails?.email && !isEmailLoaded) {
        let replacementsTemp: any = {
          client_description: `<p>Description = ${
            emailDetails && emailDetails.description
              ? emailDetails.description
              : "Not Provided"
          } </p>`,
        };
        // if (clientDetails) {
        //   replacementsTemp["client_description"] =
        //     "<p>Amount: {amount}</p>\n" +
        //     "<a href='{webapp_link}'>Link to response page</a>";
        // }
        let modifiedString: string = emailContent.replace(
          /{([^}]+)}/g,
          (match: any, key: any) => replacementsTemp[key] || match
        );

        replacementsTemp = {
          client_name: emailDetails.client_name,
          client_email_address: emailDetails.client_email,
          webapp_link: `${constants.PAGEURLS.INQUIRE_RESPONSE[env]}/${
            emailDetails.client_id
          }/${
            emailDetails.parject_manager_id
              ? emailDetails.parject_manager_id
              : null
          }/${emailDetails.email ? emailDetails.email : null}/${
            clientDetails ? clientDetails.id : null
          }`,
        };
        // console.log("clientDetails::",clientDetails)
        if (clientDetails) {
          replacementsTemp["invoice_url"] = clientDetails.invoice_url;
          replacementsTemp["amount"] = clientDetails.amount;
          replacementsTemp["invoice_id"] = clientDetails.invoice_id;
          
        }
        modifiedString = modifiedString.replace(
          /{([^}]+)}/g,
          (match: any, key: any) => replacementsTemp[key] || match
        );

        const blocksFromHTML = convertFromHTML(modifiedString);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        // console.log("email_body in for adding project useEffect::",modifiedString)
        setEmailRequestDetails({
          ...emailRequestDetails,
          email: emailDetails.client_email,
          email_body: modifiedString,
          // client_id: emailDetails.client_id,
          email_subject: `Overdue payment reminder for ${emailDetails.client_name}`,
        });
        setIsEmailLoaded(true);
        // console.log("for adding project ,manager email::")
      }
    }, [emailDetails]);

    const handleCloseTask = () => {
      handleCloseEmail(false);
    };

    const onEditorStateChange = (
      editorState: EditorState,
      setFieldValue: any
    ) => {
      const forFormik = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      setFieldValue("email_body", forFormik);
      setEditorState(editorState);
      setEmailRequestDetails({ ...emailRequestDetails, email_body: forFormik });

      // saveEmailAsDraft({ content: forFormik });
    };

    useImperativeHandle(ref, () => ({
      checkingEmailValidations:
        async function checkingEmailValidationsAsync(): Promise<boolean> {
          if (formikRef.current) {

            // console.log(" formikRef.current.email_body::", formikRef.current);

            await formikRef.current.submitForm();
            // Check if there are validation errors
            const validationErrorsExist =
              Object.keys(formikRef.current.errors).length > 0;
            setHasValidationErrors(validationErrorsExist);

            // console.log("submitForm::");

            return validationErrorsExist;
          }

          return false;
        },
    }));

    const saveEmailAsDraft = useCallback(
      ({ content, recipient, carbonCopy, subject }: EmailDraft) => {
        const oldData: EmailDraft | null = JSON.parse(
          localStorage.getItem(
            `email_client_invoice${emailDetails.client_id}`
          ) || "null"
        );

        let newData: EmailDraft = {
          content: "",
          recipient: "",
          carbonCopy: "",
          subject: "",
        };

        if (oldData) {
          newData = {
            ...oldData,
            ...(content && { content }),
            ...(recipient && { recipient }),
            ...(carbonCopy && { carbonCopy }),
            ...(subject && { subject }),
          };
        } else {
          newData = { content, recipient, carbonCopy, subject };
        }
        localStorage.setItem(
          `email_client_invoice${emailDetails.client_id}`,
          JSON.stringify(newData)
        );
      },
      []
    );

    return (
      <div
        className="sf-email-wrapper"
        style={{ position: "sticky", top: 0, transform: "translateY(25px)" }}
      >
        <div className="bg-white p-3">
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
              email: emailDetails?.client_email,
              // email_cc: parseEmailData?.carbonCopy || "",
              email_cc: "",
              // client_id: emailDetails.client_id,
              email_subject:
                parseEmailData?.subject ||
                `Overdue Payment Reminder for ${emailDetails.client_name}`,
              email_body: emailContent,
              attached_pdf: false,
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required("Please enter above field"),
              attached_pdf: Yup.boolean().required("Please enter above field"),
              email_cc: Yup.array()
                .transform(function (value, originalValue) {
                  if (this.isType(value) && value !== null) {
                    return value;
                  }
                  return originalValue ? originalValue.split(/[\s,]+/) : [];
                })
                .of(
                  Yup.string().email(
                    ({ value }) => `${value} is not a valid email`
                  )
                ),
              email_subject: Yup.string().required(
                "Please enter email subject"
              ),
              email_body: Yup.string().required(`Email body can't be empty`),
            })}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
              } = props;
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <LoadingService open={loading} />
                  <FormControl variant="standard" sx={{}} fullWidth>
                    <TextField
                      id="1"
                      variant="standard"
                      error={errors.email && touched.email}
                      label="To"
                      name="email"
                      value={values.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChange(event);
                        setEmailRequestDetails({
                          ...emailRequestDetails,
                          email: event.target.value,
                        });
                        saveEmailAsDraft({ recipient: event.target.value });
                      }}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && errors.email}
                      margin="dense"
                      size="small"
                    />
                  </FormControl>
                  <FormControl variant="standard" sx={{}} fullWidth>
                    <TextField
                      variant="standard"
                      error={errors.email_subject && touched.email_subject}
                      label="Subject"
                      id="3"
                      name="email_subject"
                      value={values.email_subject}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChange(event);
                        saveEmailAsDraft({ subject: event.target.value });
                        setEmailRequestDetails({
                          ...emailRequestDetails,
                          email_subject: event.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.email_subject &&
                        touched.email_subject &&
                        errors.email_subject
                      }
                      margin="dense"
                      size="small"
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{}} fullWidth>
                    <Editor
                      editorStyle={{
                        lineHeight: "20px",
                        padding: "10px",
                        minHeight: "100px",
                        border: "1px solid #F1F1F1",
                        borderTop: 0,
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      editorState={editorState}
                      onEditorStateChange={(editor: any) =>
                        onEditorStateChange(editor, setFieldValue)
                      }
                    />
                    <FormHelperText>
                      {errors.email_body &&
                        touched.email_body &&
                        errors.email_body}
                    </FormHelperText>
                  </FormControl>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
);
export default SendEmailToClient;
