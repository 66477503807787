
import { AuthState } from "./state";
import { Action } from '..';
import { AuthActionType } from "./actionType";

export const INITIAL_STATE: AuthState = {
    isAuthenticated: false
}

export const authReducer = (state = INITIAL_STATE, action: Action) => {
    switch(action.type) {
        case AuthActionType.USER_LOGGED_IN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.data.user
            }
        case AuthActionType.USER_LOGGED_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: undefined
            }
        case AuthActionType.USER_LOGIN_FAILED:
            return {
                ...state,
                error: action.data.error
            }
        case AuthActionType.SET_APP_CONTEXT:
            return {
                ...state,
                isAuthenticated: true,
                user: action.data.user
            }
        default:
            return state;
    }
}