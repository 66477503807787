import { useAppStateContext } from './../state/provider/index';
import { useEffect } from 'react';
import axios, { AxiosRequestConfig, CancelTokenSource, AxiosResponse, AxiosError } from "axios";
import React from 'react';
import { baseUrl } from './constant';
import LoadingService from '../shared/loader';
import { _LoaderService } from '../state/config/actions';
import { getToken } from '../utils/utils';

const axiosConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
    // responseType: 'json',
    // withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
};

export const axiosInstance = axios.create(axiosConfig);

export interface HttpRequestConfig extends AxiosRequestConfig {
    showLoader?: boolean,
    loadingMessage?: string
}

const DEFAULT_REQUEST_CONFIG: HttpRequestConfig = {
    showLoader: true,
    loadingMessage: 'Loading...'
}

export const useAxios = (requestConfig?: HttpRequestConfig) => {
    let source: CancelTokenSource;
    const { dispatch } = useAppStateContext();
    const { state } = {state: {auth: {accesToken: ''}}};

    const [error, setError] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect((): any => {
        return () => source?.cancel
    }, []);

    const get = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'GET'})
    };

    const post = async (overrideRequestConfig?: HttpRequestConfig, reqType: any = '') => {
        return await invokeCall({...overrideRequestConfig, method: 'POST'}, reqType)
    };

    const put = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'PUT'})
    };

    const patch = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'PATCH'})
    };

    const del = async(overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'DELETE'})
    };

    const invokeCall = async (overrideRequestConfig: HttpRequestConfig, reqType: any = '') => {
        setError(null);
        const config = {...DEFAULT_REQUEST_CONFIG, ...requestConfig, ...overrideRequestConfig}
        source = axios.CancelToken.source();

        if(config.showLoader) {
            setLoading(true);
            dispatch(_LoaderService(false))
            // spinnerService.Show(config.loadingMessage);
        }

        try {
            const token = getToken();
            let headers: any = { ...config?.headers }
            if(reqType === 'blob') {
                headers = {
                    'Content-Type': `multipart/form-data`,
                }
            }
            if(token) {
                headers['Authorization'] = `Token ${token}`;
            }
            const res: AxiosResponse<any> = await axiosInstance({
                ...config,
                headers,
                cancelToken: source.token
            })
            return res.data;
            
        } catch(err: any) {
            setError(handleApiError(err));
        } finally {
            if(config.showLoader) {
                setLoading(false);
                dispatch(_LoaderService(false));
                // spinnerService.Hide()
            }
        }
    }

    const handleApiError = (err: AxiosError<any>) => {
        return {
            exactErr: err?.response?.data,
            status: err?.response?.data?.status ? err?.response?.data?.status : err?.response?.status,
            statusText: err.response ? err.response?.statusText : null,
            message: err?.response?.data?.message || err?.response?.data?.err || err?.response?.data?.detail || 'Server encountered an error while processing the request',
            stack: err?.stack
        }
    }

    return {
        error,
        loading,
        get,
        post,
        put,
        del,
        patch
    }
};