import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { UserRoles } from './model';
import { EMAIL_HELPER_TEXT } from '../../utils/utils';
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import LoadingService from '../../shared/loader';
import { toast } from '../../shared/Toser/ToastManager';

interface UserDiaglogProps {
    isOpen: boolean,
    handleClose: (data: any) => void
}

const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3
    }
    
}

function AddUser(props: UserDiaglogProps) {
    const { isOpen, handleClose } = props;
    const [userEmails, setUserEmails] = useState<any>('');
    const [addRole, setAddRole] = useState<string>('');
    const { post, loading, error } = useAxios({showLoader: true});
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        if(error) {
            const _errors = error?.exactErr;
            if(_errors?.email && _errors?.email[0]){
                toast.show({severity: 'error', message: error?.exactErr?.email[0] || error.message});
            } else {
                toast.show({severity: 'error', message: error?.message});
            }
        }
    }, [error]);

    const handleCloseTask = () => {
        handleClose(false);
    };
    const handleChange = (event: SelectChangeEvent) => {
        setAddRole(event.target.value as string);
    };

    const sendInvitation = async () => {
        setIsLoading(true);
        const response: any = await post({url: APIMETHOD.user.INVITE_USER, data: {role: addRole, email: userEmails}});
        
        setIsLoading(false);
        if(response) {
            toast.show({severity: 'success', message: 'Invitation sent successfully'});
            handleCloseTask();
            console.log('Invitation Sent', response);
        }
    }

    return (
        <div>
            <LoadingService open={isLoading} />
            <Dialog PaperProps={{style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' }   }} open={isOpen} onClose={handleCloseTask} fullWidth={true}>
                <DialogContent>
                    <h5 className='p-2'>Add User</h5>
                    <Box>
                        <FormControl variant="standard" sx={{ p: 1 }} fullWidth>
                            <TextField
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                onChange={(e: any) => {setUserEmails(e.target.value)}}
                                fullWidth
                                variant="standard"
                                helperText={EMAIL_HELPER_TEXT}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, width: '15rem' }} size='small'>
                        <InputLabel id="role">Role</InputLabel>
                        <Select
                            labelId="role"
                            id="role"
                            value={addRole}
                            onChange={handleChange}
                            label="Role"
                        >
                            {
                                UserRoles?.map((u: any) => <MenuItem value={u.id} key={u.id}>{u.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2}}>
                    <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
                    <Button sx={style.submit} onClick={() => {sendInvitation()}}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddUser;