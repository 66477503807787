import BackupTableIcon from '@mui/icons-material/BackupTable';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const headersData = [
  {
    label: "Home",
    href: "/home",
    isVisible: false
  },
  // {
  //   label: "Templates",
  //   href: "/templates",
  //   isVisible: false
  // },
  {
    label: "Pricing",
    href: "/pricing",
    isVisible: false
  },
  {
    label: "Clients",
    href: "/clients",
    isVisible: false
  },
  {
    label: "Payments",
    href: "/payments",
    isVisible: false
  },
  {
    label: "Projects",
    href: "/projects",
    isVisible: false
  },
  {
    label: "User Management",
    href: "/usermanagement",
    isVisible: false
  }
];

export const subMenuItem: any = [
  {
    icon: AssignmentIcon,
    name: 'Create New Invoice Form',
    id: '1',
    path: '/invoice',
    isDisabled: false
  },
  {
    icon: AssignmentIcon,
    name: 'Create Project Planning Only Invoice',
    id: '2',
    path: '/invoice?type=pre_production',
    isDisabled: false
  },
  {
    icon: AssignmentIcon,
    name: 'Create Post Production Only Invoice',
    id: '3',
    path: '/invoice?type=post_production',
    isDisabled: false
  },
  {
    icon: AssignmentIcon,
    name: 'Create Talent Only Invoice',
    id: '4',
    path: '/invoice?type=talents',
    isDisabled: false
  },
  {
    icon: AssignmentIcon,
    name: 'Create Studios Only Invoice',
    id: '4',
    path: '/invoice?type=rentals',
    isDisabled: false
  },
  {
    icon: AssignmentIcon,
    name: 'Create Custom Items Invoice',
    id: '5',
    path: '/invoice?type=custom',
    isDisabled: false
  },
  // {
  //   icon: BackupTableIcon,
  //   name: 'Create New Template',
  //   id: '6',
  //   path: '/home',
  //   isDisabled: true
  // },
  // {
  //   icon: GroupAddIcon,
  //   name: 'Create New Client',
  //   id: '7',
  //   path: '/home',
  //   isDisabled: true
  // },
  // {
  //   icon: AssignmentIcon,
  //   name: 'Create New Invoice',
  //   id: '8',
  //   path: '/home',
  //   isDisabled: true
  // }
];