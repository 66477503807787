import React, { useEffect, useState } from "react";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useAxios } from '../../core/useAxios';
import { APIMETHOD } from '../../core/constant';
import { useParams, } from 'react-router-dom';
import { style } from "../Invoice";

function PaymentScheduleGenerate() {
    const { put } = useAxios({showLoader: true});
    const { id } = useParams();
    const [number, setNumber] = useState("")
    const [error, setError] = useState("");

    useEffect(() => {
        if (!!number) setError("")
    }, [number]);

    const generate = async () => {
        const requestData = {
            num_of_generate: number
        }

        try {
            if (!!number) {
                const result: any = await put({ 
                    url: APIMETHOD.INVOICE.PAYMENT_SCHEDULE+ "generate/" + id,
                    data: requestData
                });
        
                console.log("Payment Schedule Generate Result: ", result);
                if (result.success) {
                    window.location.reload();
                } else {
                    console.error(result)
                }
            } else {
                setError("Select number of payment schedule to generate.")
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="bg-white py-3 px-3 mt-4">
            <h5 className="fs-14 mb-3">Regenerate Payment Schedule</h5>
            <FormControl
                variant="outlined"
                // sx={{ m: 1 }}
                size="small"
                fullWidth
            >
                <InputLabel id="psGenerate" style={{ backgroundColor: "white" }}>
                    How many payment schedule?
                </InputLabel>
                <Select
                    labelId="psGenerate"
                    id="psg"
                    name={`psg`}
                    label="Role"
                    value={number}
                    onChange={(evt) => setNumber(evt.target.value)}
                >
                    <MenuItem value={1}>
                        1
                    </MenuItem>
                    <MenuItem value={2}>
                        2
                    </MenuItem>
                    <MenuItem value={3}>
                        3
                    </MenuItem>
                </Select>

                <FormHelperText>
                    {!!error? error : "" }
                </FormHelperText>
            </FormControl>

            <Button
                sx={style.submit}
                className="mt-4"
                onClick={generate}
            >
                Generate
            </Button>
        </div>
    );
}

export default PaymentScheduleGenerate;