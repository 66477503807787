import React, { useEffect, useMemo, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { EMAIL_HELPER_TEXT, getUserInfo } from "../../utils/utils";
import { useAxios } from "../../core/useAxios";
import { APIMETHOD } from "../../core/constant";
import LoadingService from "../../shared/loader";
import { toast } from "../../shared/Toser/ToastManager";
import SendEmailToClient from "./SendInvoiceEmail";
import { SendEmailToClientRef } from "./SendInvoiceEmail";

interface UserDiaglogProps {
  isOpen: boolean;
  // addClients: any,
  handleClose: (data: any) => void;
  clientDetails: any;
}

export const style: any = {
  secondary: {
    background: "#F3F3F3",
    boxShadow: "0px 2px 5px #0000000D",
    border: "1px solid #E5E5E5",
    borderRadius: "4px",
    color: "#000",
    px: 3,
    fontFamily: "VerdanaBold",
  },
  submit: {
    background:
      "transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 10px 20px #0000000D",
    borderRadius: "4px",
    opacity: 1,
    color: "#ffffff",
    px: 3,
    fontFamily: "VerdanaBold",
  },
};

function SendReminder(props: UserDiaglogProps) {
  const { isOpen, handleClose, clientDetails } = props;
  const [client_name, setClientName] = useState<string>("");
  const [projectManager, setProjectManager] = useState<any>(null);
  const [projectManagerId, setProjectManagerId] = useState<any>(null);
  const [errors, setErrors] = useState<any>(null);
  const [clientList, setClients] = React.useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { post, error, get } = useAxios({ showLoader: true });
  const userInfo = getUserInfo();
  const [showSendEmail, setShowSendEmail] = useState(true);
  const [emailRequestDetails, setEmailRequestDetails] = useState<any>(null);
  const handleCloseTask = () => {
    handleClose(false);
  };
  const sendEmailToClientRef = useRef<SendEmailToClientRef | null>(null);

  const defaultProps = {
    options: clientList,
    getOptionLabel: (option: any) => `${option.full_name}`,
  };

  useEffect(() => {
    const getClients = async () => {
      const result = await get({ url: APIMETHOD.user.GET_USER });
      if (result) {
        mapProjectData(result);
        setClients(result);
        defaultProps.options = result;
      }
    };
    getClients();
  }, []);

  const mapProjectData = (clients: any[] = []) => {
    if (clientDetails.id) {
      const selectedClientIndex = clients.findIndex(
        (x: any) => x?.full_name === clientDetails?.assigned_to
      );
      setClientName(clientDetails?.name);
      if (selectedClientIndex > -1) {
        setProjectManager(clients[selectedClientIndex]);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      let hasErrorsInMail = null;
      if (sendEmailToClientRef.current) {
        hasErrorsInMail =
          await sendEmailToClientRef.current.checkingEmailValidations();
        if (hasErrorsInMail) return;
      }
      const formData: any = new FormData();
      formData.append("project_manager_id", projectManagerId);
      !emailRequestDetails.email &&
        (emailRequestDetails.email = projectManager.email);

      setIsLoading(true);
      const response = await sendEmail(emailRequestDetails);
      setIsLoading(false);
      if (response) {
        handleCloseTask();

        // console.log("auth_url::", response.auth_url);

        if (response?.auth_url) {
          let link: any = document.createElement("a");
          link.href = response?.auth_url;
          link.target = "_blank";
          link.click();
          localStorage.removeItem(`email_client_invoice${clientDetails?.id}`);
        } else {
          toast.show({ severity: "success", message: response?.message });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendEmail = async (
    // clientDetails: any,
    emailRequestDetails: any
  ) => {
    return await post({
      url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role] + "reminder/",
      data: { ...emailRequestDetails },
    });
  };
  return (
    <div>
      <LoadingService open={isLoading} />
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 28,
            border: "1px solid #D0D0D0",
            boxShadow: "0px 20px 30px #0000000D",
          },
        }}
        open={isOpen}
        onClose={handleCloseTask}
        fullWidth={true}
        scroll="body"
      >
        <DialogContent>
          <h5 className="p-2">Send reminder</h5>
          {/* {error?.exactErr?.project_manager_id &&
            error?.exactErr?.project_manager_id[0] && (
              <Box sx={{ pb: 2 }}>
                <small className="errors_color">
                  {error?.exactErr?.project_manager_id[0]}
                </small>
              </Box>
            )}
          {error?.exactErr?.client_id && error?.exactErr?.client_id[0] && (
            <Box sx={{ pb: 2 }}>
              <small className="errors_color">
                {error?.exactErr?.client_id[0]}
              </small>
            </Box>
          )} */}
          {showSendEmail && (
            <SendEmailToClient
              emailDetails={{
                email: clientDetails?.customer_email || "",
                client_name: clientDetails?.customer_name,
                client_email: clientDetails?.customer_email,
                description: clientDetails?.description,
              }}
              handleCloseEmail={() => {
                setShowSendEmail(false);
              }}
              key={clientDetails?.id}

              clientDetails={{
                amount: clientDetails?.total,
                invoice_id: clientDetails?.id,
                invoice_url: clientDetails?.url || "",
              }}
              emailRequestDetails={emailRequestDetails}
              setEmailRequestDetails={setEmailRequestDetails}
              ref={sendEmailToClientRef}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: "1px solid #D0D0D0",
            justifyContent: "left",
            px: 4,
            py: 2,
          }}
        >
          <Button sx={style.secondary} onClick={handleCloseTask}>
            Cancel
          </Button>
          <Button
            sx={style.submit}
            onClick={() => {
              handleSubmit();
            }}
          >
            {"Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SendReminder;
