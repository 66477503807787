import { useEffect } from "react";
import { FORMAT_SLASH_MM_DD_YYYY } from "../../../utils/dateformat";
import moment from 'moment';
import { makeTime, setupEndTime, shootingEndTime, getCrewSizeBasedOnCrewMember, getCrewSizeDescription, getCrewMembers } from "../../../utils/utils";
import _ from "lodash";


const ProductionTable = (props: any) => {

    const { invoiceDetails } = props;

    
    useEffect(() => {
        console.log("Invoice Details From Production Table Component: ", invoiceDetails);

    }, [invoiceDetails])

    return (
        <div>
            <table className="table" style={{ width: "100%" }}>
                <thead className="table-dark">
                    <tr>
                        <th>DETAILS</th>
                        <th className="text-right">TOTAL COST</th>
                    </tr>
                </thead>
                <tbody>
                    { invoiceDetails?.invoice && 
                        Object.keys(invoiceDetails?.invoice?.production)
                        ?.filter((s: any) => s.includes("day_"))
                        ?.map((item: any, index: number) => {
                            return (
                                <tr>
                                    <td colSpan={3}>
                                    <div className="day_heading py-3">
                                        Day {index + 1}
                                    </div>

                                    {Object.keys(
                                          invoiceDetails?.invoice?.production[
                                            item
                                          ]?.line_items
                                        )?.map((crew: any, index: number) => {
                                          const invoiceItem =
                                            invoiceDetails?.invoice?.production[
                                              item
                                            ]?.line_items[crew];

                                          const sizes: Record<string, string> =
                                            {
                                              solo: "Solo Crew",
                                              large: "Large Crew",
                                              medium: "Medium Crew",
                                              small: "Small Crew",
                                            };

                                          let crewSize: string | undefined =
                                            sizes[
                                              invoiceItem?.crew_size?.toLowerCase()
                                            ];

                                            const crewMembers = _.join(getCrewMembers(invoiceItem.crew_members), ", ");

                                            let setup_end_time = setupEndTime(invoiceItem?.arrival_time, invoiceItem?.setup_time ?? 15);
                                            let shooting_end_time = shootingEndTime(invoiceItem?.departure_time, invoiceItem?.setup_time ?? 15);

                                            return (
                                                <div className="col-12 border-radius border border-1 border-secondary rounded mb-3">
                                                    <div className="row justify-content-between p-3">
                                                        <div className="col-md-9">
                                                            <p className="fs-12">
                                                                <span className="fw-bold text-capitalize">What is being shot with this package?</span> <br />
                                                                <span>{ invoiceItem?.shot_with_this_package }</span> 
                                                            </p>
                                                            <div className="col-12">
                                                                <h5 className="fs-11">Crew Details</h5>
                                                                <p>
                                                                    { `${getCrewSizeDescription(invoiceItem?.crew_size, invoiceDetails?.show_crew_size_description)} Crew will have a meal break every 4 hours.` }
                                                                </p>
                                                            </div>
                                                            <div className="col-12 my-2 pb-2">
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        <h5 className="fs-11">Shoot Date</h5>
                                                                        <span className="bg-yellow px-2 py-1 rounded">
                                                                            {FORMAT_SLASH_MM_DD_YYYY(
                                                                                invoiceDetails?.invoice?.production[
                                                                                item
                                                                                ]?.filming_date
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-7">
                                                                        <h5 className="fs-11">Location</h5>
                                                                        <span className="text-capitalize bg-yellow px-2 py-1 rounded">
                                                                            {/* { getCrewSizeBasedOnCrewMember(invoiceItem) } */}
                                                                            { invoiceItem?.location }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row gy-1">
                                                                    {/* <div className="col-12">
                                                                        <span className="font-600">Location: </span>
                                                                        <span>
                                                                            {invoiceItem?.location}
                                                                        </span>
                                                                    </div> */}
                                                                    <div className="col-12">
                                                                        <span className="font-600">Setup Time: </span>
                                                                        <span>
                                                                            <span className="bg-yellow px-1 py-1 rounded">
                                                                            {moment(
                                                                                invoiceItem?.arrival_time,
                                                                                "HH:mm:ss"
                                                                                ).format("hh:mm A")}
                                                                            </span> - <span>{ setup_end_time }</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <span className="font-600">Shooting Time: </span>
                                                                        <span>
                                                                            <span>{ setup_end_time }</span> - <span>{ shooting_end_time }</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <span className="font-600">Tear-down Time: </span>
                                                                        <span>
                                                                            <span>{ shooting_end_time }</span> 
                                                                            {" "}
                                                                            - 
                                                                            {" "}
                                                                            <span className="bg-yellow px-1 py-1 rounded">
                                                                                {moment(
                                                                                invoiceItem?.departure_time,
                                                                                "HH:mm:ss"
                                                                                ).format("hh:mm A")}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-12 pt-3">
                                                                        <span className="font-600">Crew Members: </span>
                                                                        <span className="text-capitalize">
                                                                            { crewMembers }
                                                                            {/* {invoiceItem?.crew_member_1 && (
                                                                                <span className="mb-0">
                                                                                {
                                                                                    invoiceItem?.crew_member_1
                                                                                }
                                                                                </span>
                                                                            )}
                                                                            {invoiceItem?.crew_member_2 && (
                                                                                <span className="mb-0">
                                                                                    {", "}
                                                                                    {
                                                                                        invoiceItem?.crew_member_2
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                            {invoiceItem?.crew_size == "medium" && (
                                                                                <span className="mb-0">
                                                                                {", "}
                                                                                { invoiceItem?.crew_member_3 }
                                                                                </span>
                                                                            )}
                                                                            {invoiceItem?.crew_size == "large" && (
                                                                                <span className="mb-0">
                                                                                {", "}
                                                                                {
                                                                                    invoiceItem?.crew_member_3
                                                                                }{", "}
                                                                                {
                                                                                    invoiceItem?.crew_member_4
                                                                                }
                                                                                </span>
                                                                            )} */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 text-right">
                                                            ${invoiceItem?.total_cost}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 border-top border-secondary">
                                                        <div className="d-flex justify-content-end">
                                                            <div className="col-12">
                                                                <div className="d-flex justify-content-between py-3 px-3">
                                                                    <div className="col">
                                                                        <span className="font-600">Sales Tax: </span>
                                                                        <span>
                                                                            <span>{ invoiceItem?.sales_tax }</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col">
                                                                        <span className="font-600">Total Travel Distance: </span>
                                                                        <span>
                                                                            <span>{ invoiceItem?.total_travel_miles }</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <span className="font-600">Travel Cost: </span>
                                                                        <span>
                                                                            <span>${ invoiceItem?.travel_cost }</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table> 

        </div>
    );
}

export default ProductionTable;