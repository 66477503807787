import React, { useEffect, useState, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TABS_NUM } from "../../utils/utils";
import ProductionForm from "../../pages/Invoice/ProductionForm";
import { PROJECT_TABS, PROJECT_TAB_INFO_DTO } from "../../pages/Project/model";
import { Button } from "@mui/material";
import { TabContext } from "../../pages/Project/context/tabcontext";
import CropSquareIcon from "@mui/icons-material/CropSquare";

interface tabsProps {
  tabInfo: PROJECT_TAB_INFO_DTO[];
  onArchive: (status: boolean) => Promise<void>;
  onTabChange: (newValue:any) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default function ProjectTabs(props: tabsProps) {
  const { tabInfo } = props;
  const [value, setValue] = React.useState<any>(0);
  const { state, dispatch } = useContext(TabContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props.onTabChange(newValue);
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <Tabs
          value={value}
          className="custom_tabs pentagon custom_tabs_style"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          centered={false}
        >
          {tabInfo.map((tabs: any, index: number) => {
            return (
              <Tab
                key={tabs.tabid}
                label={tabs.tabName}
                onClick={() => {
                  dispatch({ type: "SET_TAB", payload: tabs.tabid });
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabInfo.map((tabs: any, index: number) => {
        const _Component = tabs.components;
        return (
          <TabPanel key={tabs.tabid} value={value} index={index}>
            <div className="row">
              <div className="col-md-6">
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#81c784", backgroundColor: "#81c784" }}
                  />
                  <p>Booked Clients</p>
                </div>
                <div className="mx-3 d-flex defaultFontFamilyB">
                  <CropSquareIcon
                    sx={{ color: "#90caf9", backgroundColor: "#90caf9" }}
                  />
                  <p>Proposal Sent but NOT Booked Clients</p>
                </div>
              </div>
              {["inquiry", "invoice_sent", "fully_paid"].includes(
                tabs.tabid
              ) ? (
                <div className="text-right mb-3 col-md-6">
                  <Button
                    variant="contained"
                    onClick={() => props.onArchive(true)}
                  >
                    Move to archive
                  </Button>
                </div>
              ) : tabs.tabid === "archive" ? (
                <div className="text-right mb-3 col-md-6">
                  <Button
                    variant="contained"
                    onClick={() => props.onArchive(false)}
                  >
                    Unarchive
                  </Button>
                </div>
              ) : null}
            </div>
            <_Component
              rows={tabs.rows}
              columns={tabs.columns}
              handleSelectedRows={tabs.handleSelectedRows}
              handlePageChange={tabs.handlePageChange}
              showCheckbox={Boolean(tabs.showCheckbox)}
            />
          </TabPanel>
        );
      })}
    </>
  );
}
