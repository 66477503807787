import React from 'react';
import DynamicTabs from '../../shared/tabs/Tabs';
import ProductionForm from './ProductionForm';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { NUMBER_10, _days } from './modal';
import { TABS_NUM } from '../../utils/utils';


function ProductionDetails(props: any) {
    const { values, setFieldValue, errors, touched } = props.formikProps;

    const handleChangeFilmingDays = (e: any) => {
        const filming_days = Number(e?.target?.value);
        let data: any = values?.production_details?.day;
        const remaining_days = filming_days - data?.length;
        if (remaining_days > 0) {
            TABS_NUM(remaining_days || 0)
                .forEach(() => data.push(_days))

            setFieldValue('production_details.day', data);
        };
        if (filming_days < data?.length && data.length > 0) {
            const updated_day = data.slice(0, filming_days);
            setFieldValue(`production_details.day`, updated_day);
        }
        setFieldValue('client_details.filming_days_num', Number(filming_days))
    }

    return (
        <>
            {/* <div className='row m-2 d-tabs'> */}
            <div className='row m-2'>
                <div className='col-6 p-0'>
                    <FormControl variant="outlined" sx={{ m: 1 }} margin="dense" size='small' fullWidth>
                        <InputLabel id="role" style={{backgroundColor:"white"}}>Filming Days Needed?</InputLabel>
                        <Select
                            labelId="role"
                            label="Role"
                            id="role"
                            name='client_details.filming_days_num'
                            value={values?.client_details?.filming_days_num}
                            onChange={(e: any) => handleChangeFilmingDays(e)}
                        >
                            {
                                NUMBER_10.map((u: number) => <MenuItem value={u} key={u + 'key'}>{u}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>{(errors?.client_details?.filming_days_num &&
                            touched?.client_details?.filming_days_num) && errors?.client_details?.filming_days_num}
                        </FormHelperText>
                    </FormControl>
                </div>
                {values.client_details.filming_days_num ?
                    <DynamicTabs
                        tabNumber={values.client_details.filming_days_num}
                        formikProps={props.formikProps}
                        label={'day'}
                        components={ProductionForm}
                        formName={'production_details'}
                    />
                    : null
                }
            </div>
        </>

    );
}

export default ProductionDetails;