import React, { useState, useEffect, useCallback, useRef, useImperativeHandle, forwardRef } from 'react';
import { Button, Checkbox, DialogActions, FormControl, FormControlLabel, TextField, FormHelperText, Box } from '@mui/material';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAxios } from '../../core/useAxios';
import { getUserInfo } from '../../utils/utils';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { toast } from '../../shared/Toser/ToastManager';
import LoadingService from '../../shared/loader';
import { Editor } from 'react-draft-wysiwyg';
import { constants } from '../../core/constant';
import { APIMETHOD } from '../../core/constant';
const emailContent = 'Response Body .....'
const env = process.env.REACT_APP_ENV || 'dev'
interface EmailProps {
  handleCloseEmail: (data: any) => void,
  emailDetails: any,
  emailRequestDetails: any,
  setEmailRequestDetails: (data: any) => void,
  emailFields:any,
}

// Define the interface for the forwarded ref
export interface SendResponseEmailRef {
  checkingEmailValidations: () => Promise<boolean>;
}

interface EmailDraft {
  content?: string;
  recipient?: string;
  carbonCopy?: string;
  subject?: string;
}

const SendResponseEmail = forwardRef<SendResponseEmailRef, EmailProps>((props, ref) => {

  const { handleCloseEmail, emailDetails, emailRequestDetails, setEmailRequestDetails,emailFields } = props
  const { post, error, loading } = useAxios({ showLoader: true });
  const [parseEmailData, setParsedEmailData] = useState<EmailDraft>({});
  const userInfo = getUserInfo();
  // const [emailContent,setEmailContent] = useState()
  const formikRef = useRef<any>(null);
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(ContentState.createFromText(emailContent)),
  );
  const [replacement, setReplacement] = useState()
  const [hasValidationErrors, setHasValidationErrors] = React.useState<boolean>(false);


  const handleSubmit = async (values: any) => {
    const request: any = {
      email: values.email,
      email_cc: values.email_cc ? values.email_cc.split(',') : [],
      email_subject: values.email_subject,
      email_body: values.email_body,
      attached_pdf: false,
      client_id: emailFields['client_id'],
    }
    // let response = false 
    const response: any = await post({ url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role] + 'send-inquirey-mail/', data: request });
    if (response) {
      if (response?.auth_url) {
        let link: any = document.createElement('a');
        link.href = response?.auth_url;
        link.target = '_blank';
        link.click();
        localStorage.removeItem(`email_data_${emailDetails.invoice_id}`)
        handleCloseEmail(false);
      } else {
        toast.show({ severity: 'success', message: response?.message });
        handleCloseEmail(false);
      }

    }

  }

  useEffect(() => {
    if (error) {
      const _errors = error?.exactErr?.attached_pdf;
      if (_errors) {
        toast.show({ severity: 'error', message: _errors[0] });
      } else {
        toast.show({ severity: 'error', message: error.message });
      }
      handleCloseEmail(false);
    }
  }, [error]);


  useEffect(() => {

    let replacementsTemp : any = {
      'client_email_address': emailDetails.client_email,
      'webapp_link': `${constants.PAGEURLS.INQUIRE_RESPONSE[env]}/ravi/aaaa`
    }
    const modifiedString:string = emailContent.replace(
      /{([^}]+)}/g,
      (match: any, key: any) => replacementsTemp[key] || match
    );
    console.log("emailDetails?.body::",emailDetails?.body)
    const blocksFromHTML = convertFromHTML(emailDetails.body);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(contentState));

    setEmailRequestDetails({
      email: emailDetails?.email,
      email_cc: '',
      client_id: emailDetails.client_id,
      email_subject: emailDetails.subject,
      email_body: emailDetails.body,
      attached_pdf: false
    })

  
    console.log("hii this called::")
  }, [emailFields])

  const handleCloseTask = () => {
    handleCloseEmail(false);
  };



  const onEditorStateChange = (editorState: EditorState, setFieldValue: any) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue('email_body', forFormik);
    setEditorState(editorState);
    setEmailRequestDetails({ ...emailRequestDetails, email_body: forFormik })
    saveEmailAsDraft({ content: forFormik });
  };

  const bold_italic_first_12_chars = {
    "font-weight": "bold",
    "font-style": "italic",
    "display": "inline-block",
    "overflow": "hidden",
    "white-space": "nowrap",
  }
  useImperativeHandle(ref, () => ({
    checkingEmailValidations: async function checkingEmailValidationsAsync(): Promise<boolean> {
      if (formikRef.current) {
        await formikRef.current.submitForm();
        // Check if there are validation errors
        const validationErrorsExist = Object.keys(formikRef.current.errors).length > 0;
        setHasValidationErrors(validationErrorsExist);

        return validationErrorsExist;
      }
      return false;
    },
  }));

  const saveEmailAsDraft = useCallback(({ content, recipient, carbonCopy, subject }: EmailDraft) => {

    const oldData: EmailDraft | null = JSON.parse(localStorage.getItem(`email_data_manager${emailDetails.client_id}`) || 'null');

    let newData: EmailDraft = { content: '', recipient: '', carbonCopy: '', subject: '' };

    if (oldData) {

      newData = { ...oldData, ...(content && { content }), ...(recipient && { recipient }), ...(carbonCopy && { carbonCopy }), ...(subject && { subject }) };

    } else {

      newData = { content, recipient, carbonCopy, subject };

    }
    localStorage.setItem(`email_data_manager${emailDetails.client_id}`, JSON.stringify(newData));
  }, []);


  return (
    <div className="sf-email-wrapper" style={{ position: 'sticky', top: 0, transform: 'translateY(25px)' }}>
      <Box sx={{ my: 2, textAlign: 'center' }} className="mt-4">
        <h5>Email Details!</h5>
      </Box>
      <div className="bg-white p-3">
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={{
            email: emailDetails?.email,
            email_cc: parseEmailData?.carbonCopy || '',
            client_id: emailDetails.client_id,
            email_subject: emailDetails.subject,
            email_body: emailDetails.body,
            attached_pdf: false
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleSubmit(values);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email()
              .required('Please enter above field'),
            attached_pdf: Yup.boolean().required('Please enter above field'),
            email_cc: Yup.array()
              .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) {
                  return value;
                }
                return originalValue ? originalValue.split(/[\s,]+/) : [];
              })
              .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
            email_subject: Yup.string()
              .required('Please enter email subject'),
            email_body: Yup.string()
              .required(`Email body can't be empty`),
          })}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue
            } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <LoadingService open={loading} />
                <FormControl variant="standard" sx={{}} fullWidth>
                  <TextField
                    id='1'
                    variant="standard"
                    error={errors.email && touched.email}
                    label="To"
                    name="email"
                    value={values.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      setEmailRequestDetails({ ...emailRequestDetails, email: event.target.value })
                      saveEmailAsDraft({ recipient: event.target.value });
                    }}
                    onBlur={handleBlur}
                    helperText={(errors.email && touched.email) && errors.email}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
                <FormControl variant="standard" sx={{}} fullWidth>
                  
                  <TextField
                    variant="standard"
                    error={errors.email_cc && touched.email_cc}
                    label="CC"
                    id='2'
                    name="email_cc"
                    value={values.email_cc}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(event);
                      saveEmailAsDraft({ carbonCopy: event.target.value });
                      setEmailRequestDetails({ ...emailRequestDetails, email_cc: event.target.value })
                    }}
                    onBlur={handleBlur}
                    helperText={(errors.email_cc && touched.email_cc) && errors.email_cc}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
                  <FormControl variant="standard" sx={{}} fullWidth>
                    <TextField
                      variant="standard"
                      error={errors.email_subject && touched.email_subject}
                      label="Subject"
                      id="3"
                      name="email_subject"
                      value={values.email_subject}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event);
                        saveEmailAsDraft({ subject: event.target.value });
                        setEmailRequestDetails({ ...emailRequestDetails, email_subject: event.target.value });
                      }}
                      onBlur={handleBlur}
                      InputProps={{
                        style: values.email_subject === "{Project Name} Details for Booking" ? bold_italic_first_12_chars : {}
                      }}
                      margin="dense"
                      size="small"
                    />
                  </FormControl>

                <FormControl variant="standard" sx={{}} fullWidth>
                  <Editor
                    editorStyle={{ lineHeight: '20px', padding: '10px', minHeight: '100px', border: '1px solid #F1F1F1', borderTop: 0 }}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={editorState}
                    onEditorStateChange={(editor: any) => onEditorStateChange(editor, setFieldValue)}
                  />
                  <FormHelperText>
                    {(errors.email_body && touched.email_body) && errors.email_body}
                  </FormHelperText>
                </FormControl>
                <DialogActions>
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
})
export default SendResponseEmail;