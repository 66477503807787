import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader, CardContent, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { getUserInfo } from '../../../utils/utils';
import moment from 'moment';
import './widgets.scss';
import { APIMETHOD } from '../../../core/constant';
import { useAxios } from '../../../core/useAxios';
import { Pagination } from "@mui/material";


const users = [{ "id": 9, "name": "c9", "email": "c9@gmail.com", "phone": "7894561230", "address": null, "total_projects": 0, "projects": [], "assigned_to": "m 1", "assigned_to_fullname": "m 1", "from_google_sheets": false, "new_client": true, "description": null, "created_by": 1, "updated_by": 1, "created_at": "2023-09-15T17:43:12.319557Z", "updated_at": "2023-09-16T14:50:40.686339Z", "response_time": 0, "assigned_at": "2023-09-16 14:50:40.686339+00:00" }, { "id": 6, "name": "c6", "email": "c6@gmail.com", "phone": "7895463120", "address": null, "total_projects": 0, "projects": [], "assigned_to": "m 1", "assigned_to_fullname": "m 1", "from_google_sheets": false, "new_client": true, "description": null, "created_by": 1, "updated_by": 1, "created_at": "2023-09-14T13:27:01.882783Z", "updated_at": "2023-09-15T14:25:11.322193Z", "response_time": 23002, "assigned_at": "2023-09-15 14:25:11.322193+00:00" }]

interface Client {
  name: string;
  created_at: string;
  assigned_to: string;
  response_time: number;
}



const CardWrapper = styled(Card)({
  width: '620px', // Reduce the card width
  margin: 'auto',
  backgroundColor: 'white',
  maxHeight: '800px', // Reduce the card height
  overflowY: 'auto',
  paddingTop: '0px',
  marginTop: '0px',
});

const CardHeaderWrapper = styled(CardHeader)({
  backgroundColor: 'white',
  color: 'black',
  font: "menu",
  textAlign: 'start',
  position: 'sticky',
  top: '0',
  zIndex: '1',
  paddingBottom: '0px',
  fontSize: '2rem', // Set your desired font size here
  fontWeight: 'bold', // Set font weight to bold for emphasis
});

const HeaderIconWrapper = styled(Avatar)({
  width: '3rem', // Reduce the avatar size
  height: '3rem', // Reduce the avatar size
  backgroundColor: 'white',
  color: "black"
})

const ListItemWrapper = styled(ListItem)({
  // borderBottom: '1px solid #ccc',
  padding: '8px', // Reduce the padding
});

const ListItemTextWrapper = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '200px', // Reduce the text width
  },
});

const CardContentWrapper = styled(CardContent)({
  paddingTop: '0px'
})

const NewClientsWidget: React.FC = () => {
  // const top5Users = users.slice(0, 5);
  const [usersList, setUsersList] = useState<Client[]>([]);
  const [page, setPage] = React.useState(1);
  const [totalRows, setTotalRows] = React.useState(0);


  const theme = useTheme();
  const { get, put, loading } = useAxios({ showLoader: true });
  const userInfo:any = getUserInfo();
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const getClients = async () => {
    // const result: Client[] = await get({ url: APIMETHOD.DETAILS.REACHED_CLIENTS[userInfo.role]+        
    const result: any = await get({ url: APIMETHOD.DETAILS.REACHED_CLIENTS[userInfo.role]+        
    `?page=${page}`,
    });
    setUsersList(result)
    setTotalRows(result.count)
  }
  useEffect(() => {
    getClients()
  }, [page])


  const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 10, sortable:true, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1},
  { field: 'name', headerName: 'Client Name', width: 100 },
  { field: 'created_at', headerName: 'Created On', width: 100, renderCell:(params:any)=>{
      let createddate = `${params.row.created_at?moment(params.row.created_at).format('MM/DD/YYYY'):'-'}`
      return createddate
    } },
  { field: 'assigned_to', headerName: 'Assigned To', width: 130} ,
  { field: 'response_time', headerName: 'Response Time', width: 100, 
    renderCell:(params:any)=>{
      let resTime= `${params.row.response_time ? Math.floor(Number(params.row.response_time) / 60)+" M" : '00' + " M"} : ${params.row.response_time ? Number(params.row.response_time) % 60 +" S" : '00' + " S"}`
      return resTime
    }
 },
];


  return (
    <CardWrapper>
      <CardHeaderWrapper
        avatar={<HeaderIconWrapper> <WorkOutlineIcon fontSize="large" /> </HeaderIconWrapper>}
        title={<Typography variant="h5">
          New Clients
        </Typography>}
      />
      <CardContentWrapper>
            <ListItemWrapper>
                <div style={{"height":630, "width":620}}>
                   <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold !important",
                    },
                    }}
                    rows={usersList}
                    columns={columns}
                    pageSize={10}
                    onPageChange={(params: any) =>
                    handlePageChange(params.event, params.page)
                    }
                    autoPageSize={true}
                    // autoHeight={true}
                    rowCount={totalRows}
                    rowsPerPageOptions={[5]}
                    // getRowId={(row) => row.id}
                  />
{/*                   <Pagination
                  count={Math.ceil(totalRows / 5)}
                  page={page}
                  onChange={handlePageChange}
                /> */}
                </div>
            
            </ListItemWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};

export default NewClientsWidget;
