import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderServiceProps {
    open: boolean
    text?: string;
}

function LoadingService({ open, text }: LoaderServiceProps) {
  return (
    <>
    {open ? <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={open}
        onClick={() => {}}
      >
        <div className='d-flex align-items-center'>
          <CircularProgress color="inherit" />
          {text && (
            <span className="ml-3 fs-16">{text}...</span>
          )}
        </div>
      </Backdrop>
    </div> : null
    }
    </>
  );
}

export default LoadingService;