import React, { useState, useEffect, createContext, useContext } from "react";
import { TextField, Button, Box, Switch, Pagination } from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { TabContext } from "./context/tabcontext";

interface RowInterface {
  count: number;
  next: any;
  previous: any;
  results: any[];
}

interface GridProps {
  rows: RowInterface;
  columns: any[];
  handleSelectedRows?: (selected: number[]) => void;
  handlePageChange?: (evt:any, pageNumber:number) => void,
  showCheckbox: boolean;
}

function Grid(props: GridProps) {
  const { rows, columns, handleSelectedRows, handlePageChange, showCheckbox } = props;
  const { state } = useContext(TabContext);
  const [currentPage, setcurrentPage] = useState(1);

  const pageSize = 50;

  const doPageChange = (evt:any, pageNumber:number) => {
    setcurrentPage(pageNumber);
    console.log("Current Page Change: ", pageNumber);
    if (handlePageChange)
        handlePageChange(evt, pageNumber);
  }

  return (
    <div>
      <Box sx={{ width: "100%", minHeight: window.innerHeight - 190 + "px" }}>
        <DataGridPro
          className="border-0 defaultFontFamily"
          sx={{
            ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold !important" },
            ".MuiDataGrid-row": {
              backgroundColor:
                state.setTab === "card_info_saved" ||
                state.setTab === "first_down_payment" ||
                state.setTab === "fully_paid" ||
                state.setTab === "partial_payment"
                  ? "#81c784"
                  : state.setTab === "invoice_sent"
                  ? "#90caf9"
                  : "",
            },
          }}
          columns={columns}
          rows={rows?.results}
          rowThreshold={0}
          rowHeight={30}
          autoHeight={true}
          autoPageSize={true}
          rowsPerPageOptions={[pageSize]}
          rowCount={rows.count}
          onPageChange={(params:any) => {
            doPageChange(params.event, params.page)
          }}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          // getRowClassName={(param:any) => {
          //   console.log("Get Row Classname: ", param)
          //   return ""
          // }}
          checkboxSelection={showCheckbox || false} // TODO: This is the added code to allow for multiple row selection,
          onSelectionModelChange={(rowsSelected: any, allRows: any) => {
            console.log(rowsSelected, allRows);

            if (handleSelectedRows) {
              handleSelectedRows(rowsSelected);
            }
          }}
        />
        {rows.count > 0 && (
          <Pagination
            count={Math.ceil(rows.count / pageSize)}
            page={currentPage}
            onChange={doPageChange}
          />
        )}
      </Box>
    </div>
  );
}

export default Grid;
