import React, { useEffect, useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button } from "@mui/material";
import UpdatePrice from "./UpdatePrice";
import { APIMETHOD } from "../../core/constant";
import { useAxios } from "../../core/useAxios";

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }
    
}

function PricingManager () {
    const { get } = useAxios({showLoader: true})
    const [prices, setPrices] = useState<any>([]);
    const [open, setOpen] = useState<any>({});
    const exclude_rates = [
        "live_stream_crew_package_price",
        "tempe_studio_hourly_rate",
        "chandler_studio_hourly_rate",
        "wedding_crew_package_price",
    ]

    useEffect(() => {
        fetchPrices();
    }, [])

    const fetchPrices = async () => {
        const response: any = await get ({
            url: APIMETHOD.INVOICE.PRICING,
        });

        console.log("Pricing: ", response);
        setPrices(response);
    }

    const makeTitle = (key:any) => {
        switch(key) {
            case "pre_production_hourly_rate":
                return "Project Planning Hourly Rate";

            case "solo_videographer_crew_package_price":
                return "Solo Crew Hourly Rate"

            default:
                let title = key.replace(/_/g, " ")
                if (key != "travel_per_mile_price") {
                    return title.replace("price", "hourly rate");
                }
                return title;
        }
    }

    const pushPricing = (pl:any) => {
        setPrices((currentPrices:any) => [pl, ...currentPrices]);
        console.log(pl);
    }
    
    return (
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-8">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-5">Pricing Management</h4>
                        <Button sx={style.submit} onClick={() => setOpen({create: true})}>Add Rate</Button>
                    </div>

                    <UpdatePrice 
                        isOpen={open.create}
                        price={{}}
                        title={null}
                        newUpdate={pushPricing}
                        handleClose={() => setOpen({create: false})} />

                    <div className="list-group rounded shadow">
                        { prices && prices?.map((item: any, index: any) => {
                            const title = makeTitle (item.key)
                            if (!exclude_rates.includes (item.key)) {
                                return (
                                    <>
                                        {open[item.key] && (
                                            <UpdatePrice 
                                                key={`item${index}`}
                                                isOpen={open[item.key]}
                                                price={item}
                                                title={title}
                                                index={index}
                                                newUpdate={(pl) => item.value = pl.value}
                                                handleClose={() => setOpen({[`${item.value}`]: false})} />
                                        )}
                                        <div key={index} className="list-group-item py-3 px-3 border-bottom">
                                            <div className="d-flex justify-content-between">
                                                <div className="col-6">
                                                    <span className="fs-14 text-capitalize">{title}</span>
                                                </div>
                                                <div className="col-4">
                                                    <span>${item.value}</span>
                                                </div>
                                                <div className="col-2 d-flex justify-content-end">
                                                    <Button
                                                        onClick={() => setOpen({[`${item.key}`]: true})}
                                                        size="small"
                                                        // aria-controls={open ? "account-menu" : undefined}
                                                        aria-haspopup="true"
                                                        // aria-expanded={open ? "true" : undefined}
                                                        sx={{ padding: "5px 3px" }}
                                                        // className="w-6 h-6"
                                                        disableElevation
                                                    >
                                                        <EditOutlinedIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })}
                    </div>    
                </div>

            </div>
        </div>
    );
}

export default PricingManager;