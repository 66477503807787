import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText, Autocomplete } from '@mui/material';
import { _editData, _rental_day } from './modal';
import moment from 'moment';
import DateInput from '../../shared/date/DateInput';
import { NUMBER_10 } from './modal';

function RentalLocationForm(props: any) {
    const { index, formikProps } = props;
    const { handleChange, setFieldValue, values, errors, touched } = formikProps;
    // const [selectedArrival, setSelectedArrival] = useState(moment())
    const [selectedArrival, setSelectedArrival] = useState()


    useEffect(() => {
        let data: any = values.rentals.days;
        if (!data[index]) {
            data[index] = _rental_day;
            setFieldValue('rentals.days', data);
        }
    }, [index]);

    const dayTimeSlots = () => {
        const timeSlots = [];
        const startTime = moment().startOf('day');
        const endTime = moment().endOf('day');
  

        while (startTime.isBefore(endTime)) {
            timeSlots.push(startTime.format('hh:mm A'));
            startTime.add(15, 'minutes');
        }

        return timeSlots;
    }

    const timeSlots = useMemo(dayTimeSlots, []);

    const departureTimeSlots = () => {
        const deptimeSlots = [];
        const startTime = moment(selectedArrival,'hh:mm A').add(15, 'minutes')
        const endTime = moment().endOf('day').add(10,'hours')
        while (startTime.isBefore(endTime)) {
            deptimeSlots.push(startTime.format('hh:mm A'));
            startTime.add(15, 'minutes');
        }
        
        return deptimeSlots;
    }   


    const deptimeSlots = useMemo(()=>departureTimeSlots(), [selectedArrival]);
    return (
      values.rentals.days[index] && (
        <div className="row">
          <div className="col-6">
            <FormControl variant="standard" sx={{ mb: 1 }} fullWidth>
              <DateInput 
                    name={`rentals.days[${index}].date_of_shoot`}
                    value={values.rentals.days[index].date_of_shoot}
                    label={`Date Of Shoot`}
                    handleChange={(date: string) => {
                        // setFieldValue(`post_production.edit[${index}].deadline_of_initial_delivery`, date)
                        handleChange({
                            target: {
                                name: `rentals.days[${index}].date_of_shoot`,
                                value: date
                            }
                        })
                    }}
                    helperText={(errors?.post_production?.edit[index]?.deadline_of_initial_delivery &&
                        touched?.post_production?.edit[index]?.deadline_of_initial_delivery) &&
                        errors?.post_production?.edit[index]?.deadline_of_initial_delivery
                    } 
                />

              {/* <TextField
                name={`rentals.days[${index}].date_of_shoot`}
                type={"date"}
                variant="outlined"
                label={`Date Of Shoot`}
                onChange={handleChange}
                value={values.rentals.days[index].date_of_shoot || ""}
                margin="dense"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  (errors?.rentals?.days[index]?.date_of_shoot ||
                    touched?.rentals?.days[index]?.date_of_shoot) &&
                  errors?.rentals?.days[index]?.date_of_shoot
                }
              /> */}
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="aspect">Studio Location</InputLabel>
              <Select
                labelId="aspect"
                id="aspect"
                name={`rentals.days[${index}].location`}
                value={values.rentals.days[index].location}
                onChange={handleChange}
                label="Studio Location"
              >
                <MenuItem value={"Tempe Studio"}>Tempe Studio</MenuItem>
                <MenuItem value={"Chandler Studio"}>Chandler Studio</MenuItem>
                <MenuItem value={"scottsdale_studio"}>Scottsdale Studio</MenuItem>
              </Select>
              <FormHelperText>
                {(errors?.rentals?.days[index]?.location ||
                  touched?.rentals?.days[index]?.location) &&
                  errors?.rentals?.days[index]?.location}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" size="small" fullWidth>
              <Autocomplete
                id={`rental-start-time-autocomplete-${index}`}
                onOpen={() => {
                  setTimeout(() => {
                    const optionEl = document.querySelector(
                      `[data-name="10:00 AM"]`
                    );
                    optionEl?.scrollIntoView();
                  }, 1);
                }}
                options={timeSlots}
                getOptionLabel={(option) => option}
                value={values.rentals.days[index].rental_start_time}
                onChange={(_, newValue) => {
                  setSelectedArrival(newValue);
                  handleChange({
                    target: {
                      name: `rentals.days[${index}].rental_start_time`,
                      value: newValue,
                    },
                  });
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} data-name={option}>
                      {option}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`Rental Start Time`}
                    margin="dense"
                    size="small"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    helperText={
                      (errors?.rentals?.days[index]?.rental_start_time ||
                        touched?.rentals?.days[index]?.rental_start_time) &&
                      errors?.rentals?.days[index]?.rental_start_time
                    }
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="col-6">
            <FormControl variant="outlined" size="small" fullWidth>
              <Autocomplete
                id={`rental-end-time-autocomplete-${index}`}
                options={deptimeSlots}
                getOptionLabel={(option) => option}
                value={values.rentals.days[index].rental_end_time}
                onChange={(_, newValue) => {
                  handleChange({
                    target: {
                      name: `rentals.days[${index}].rental_end_time`,
                      value: newValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`Rental End Time`}
                    margin="dense"
                    size="small"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    helperText={
                      (errors?.rentals?.days[index]?.rental_end_time ||
                        touched?.rentals?.days[index]?.rental_end_time) &&
                      errors?.rentals?.days[index]?.rental_end_time
                    }
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
      )
    );
}

export default RentalLocationForm;