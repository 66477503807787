import moment from "moment";

export enum DateFormat {
    DASH_DD_MM_YYYY = 'DD-MM-YYYY',
    SAPCE_DD_MM_YYYY = 'DD MM YYYY',
    SAPCE_DD_MMM_YYYY = 'DD MMM YYYY',
    SLASH_MM_DD_YYYY = 'MM/DD/YYYY',
    FORMAT_LL = 'LL'
}

const FORMAT_DASH_DD_MM_YYYY = (date: any) => {
    if(date) {
        return moment(date).format(DateFormat.DASH_DD_MM_YYYY);
    }
    return '';
}

const FORMAT_SLASH_MM_DD_YYYY = (date: any) => {
    if(date) {
        return moment(date).format(DateFormat.SLASH_MM_DD_YYYY);
    }
    return '';
}

const formatDateLL = (date: any) => {
    if(date) {
        return moment(date).format(DateFormat.FORMAT_LL);
    }
    return '';
}

export {
    FORMAT_DASH_DD_MM_YYYY,
    FORMAT_SLASH_MM_DD_YYYY,
    formatDateLL
};