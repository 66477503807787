interface USER_ROLES {
    name: string,
    id: string
}

export const UserRoles: USER_ROLES[] = [
    {
        name: 'Project Manager',
        id: 'project_manager'
    },
    {
        name: 'Admin',
        id: 'admin'
    }
];

export interface REGFORMDATA {
    first_name: string,
    last_name: string,
    userPhone: any,
    "email": string,
    "password": string,
    "password2": string,
    "userRole": string,
}

export const INITIAL_REGISTRATION_FORM: REGFORMDATA = {
    first_name: '',
    last_name: '',
    userPhone: '',
    "email": "",
    "password": "",
    "password2": "",
    "userRole": ""
}

export const ROWS: any = [
    { id: 1, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 2, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 3, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 4, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 5, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 6, name: "James Jacob", role: "Bussiness Manager", email: null, phone: "0987654321", status: 'Active' },
    { id: 7, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 8, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Active' },
    { id: 9, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Inactive' },
    { id: 10, name: "James Jacob", role: "Bussiness Manager", email: "Jamesjacob@gmail.com", phone: "0987654321", status: 'Inactive' }
];