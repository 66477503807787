import React from 'react';

function Unauthorized(props: any) {
    return (
        <div>
            <h5>User is Unauthorized...</h5>
        </div>
    );
}

export default Unauthorized;