import React, { useState, useEffect, createContext, useContext } from "react";
import { APIMETHOD } from "../../core/constant";
import { useAxios } from "../../core/useAxios";
import ProjectTabs from "../../shared/tabs/ProjectTabs";
import { getUserInfo } from "../../utils/utils";
import Grid from "./Grid";
import {
  FILTER_ROW_DATA,
  PROJECT_TABS,
  PROJECT_TABS_NAME,
  PROJECT_TAB_INFO_DTO,
} from "./model";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import Moment from "react-moment";
import LoadingService from "../../shared/loader";
import { Box, Button, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "../../shared/Toser/ToastManager";
import { darken, lighten, styled } from "@mui/material/styles";
import { TabContextProvider } from "./context/tabcontext";
import { confirm } from "material-ui-confirm";
import { _initialApiPaginationResponse } from "../Invoice/modal";

export const cloneBtnStyle = {
  btnStyle: {
    background: "#F2A039",
    boxShadow: "0px 2px 5px #0000000D",
    border: "1px solid #D1D1D1",
    borderRadius: "4px",
    fontFamily: "VerdanaBold",
    fontSize: "10px",
    color: "#ffffff",
  },
};

const Project = ({ defaultTab, ...props }: any) => {
  const { get, put, loading } = useAxios({ showLoader: true });
  const userInfo = getUserInfo();
  const [sortedProjects, setSortedProjects] = useState([]);
  const [inquiryRowData, setInquiryRowData] = useState<any>(_initialApiPaginationResponse);
  const [invoiceRowData, setInvoiceRowData] = useState<any>(_initialApiPaginationResponse);
  const [cardInfoSavedRowData, setCardInfoSavedRowData] = useState<any>(_initialApiPaginationResponse);
  const [downpaymentRowData, setDownpaymentRowData] = useState<any>(_initialApiPaginationResponse);
  const [partialRowData, setPartialRowData] = useState<any>(_initialApiPaginationResponse);
  const [fullpaymentRowData, setFullpaymentRowData] = useState<any>(_initialApiPaginationResponse);
  const [archiveRowData, setArchiveRowData] = useState<any>(_initialApiPaginationResponse);
  const navigate = useNavigate();
  const [archiveIds, setArchiveIds] = useState<any[]>([]);
  const [unarchiveIds, setUnarchiveIds] = useState<any[]>([]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleClone = (params: any) => {
    navigate("/invoice/" + params?.row?.invoice_id + "/clone/");
  };

  const handleManuallyBook = (param:any) => {
    console.log("Selected Param: ", param)
    confirm({
      title: (
        <div className="fs-16 fw-400">
          Manually Book: <span className="fw-500 text-primary">{param.row.project_name}</span>
        </div>
      ),
      confirmationText: "Yes",
      cancellationText: "No",
      description: (
        <div className="py-2 fs-17">
          Are you sure you want to manually book this project.
        </div>
      ),
    }).then(() => {
      // console.log("Params: ", inquiryRowData)
      manuallyBookProject(param);
    })
  }

  const manuallyBookProject = async (params: any) => {
    console.log("Params: ", params)
    // setLoading(true);
    const invoice_id = params.row.invoice_id;

    const res = await put({
      url: APIMETHOD.INVOICE.CREATE_INVOICE.admin+invoice_id+"/manually_book/",
    })

    console.log("Manually Book Response: ", res);

    if (res.success) {
      const findInquiry = inquiryRowData.results.find((item:any) => item.invoice_id == invoice_id);
      findInquiry.stage = "manually_booked"
      // console.log("Found Row: ", findInquiry)
      const filtered = inquiryRowData.results.filter((item:any) => item.invoice_id !== invoice_id);
      setInquiryRowData({...inquiryRowData, results: filtered})
      // const newInvoiceRowData = [...invoiceRowData.results, findInquiry];
      // setInvoiceRowData({...invoiceRowData, results: newInvoiceRowData});
    }
  }

  const draftedColumns: GridColumns = [
    { field: "client_name", headerName: "Client Name", width: 200, flex: 1 },
    { field: "project_name", headerName: "Project Name", width: 200, flex: 1 },
    {
      field: "first_shoot_date",
      headerName: "First shoot date",
      flex: 1,
      renderCell: (params: any) => {
        return <Moment format="MM/DD/YYYY">{params?.value}</Moment>;
      },
    },
    {
      field: "created_at",
      headerName: "Invoice Drafted",
      flex: 1,
      sortable: true, // Enable sorting for this column
      sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(), // Comparator function for sorting
      renderCell: (params: any) => {
        return (
          <Moment format="MM/DD/YYYY" utc>
            {params?.value}
          </Moment>
        );
      },
    },

    { field: "project_manager", headerName: "Project Manager", flex: 1 },
    { field: "filming_location", headerName: "Filming Location", width: 200 },
    {
      field: "invoice_id",
      headerName: "Invoice",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            sx={{
              height: "25px",
              background: "#9DECF2",
              border: "border: 1px solid #81D0D8",
              color: "#000",
              fontFamily: "VerdanaBold",
            }}
            variant="contained"
            onClick={() =>
              navigate(`/preview-invoice/${params?.row?.invoice_id}/`)
            }
          >
            Proposal
          </Button>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              sx={{
                height: "25px",
                background: "#9DECF2",
                border: "border: 1px solid #81D0D8",
                color: "#000",
                fontFamily: "VerdanaBold",
              }}
              variant="contained"
              onClick={() =>
                navigate(
                  `/invoice/${params?.row?.invoice_id}/${params?.row?.client_name}`
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              sx={cloneBtnStyle.btnStyle}
              className="btn btn_custom mx-2"
              onClick={() => handleClone(params)}
            >
              Clone
            </Button>
            <Button
              size="small"
              // sx={cloneBtnStyle.btnStyle}
              className="btn btn-primary mx-1 px-2 fw-bold"
              disabled={[
                "fully_paid",
                "manually_booked",
                "first_down_payment",
                "partial_payment",
                "card_info_saved",
              ].includes(params.row.stage)}
              onClick={() => handleManuallyBook(params)}
            >
              Manually Book
            </Button>
          </>
        );
      },
    },
  ];

  const columns: GridColumns = [
    { field: "client_name", headerName: "Client Name", width: 200, flex: 1 },
    { field: "project_name", headerName: "Project Name", width: 200, flex: 1 },
    {
      field: "first_shoot_date",
      headerName: "First shoot date",
      flex: 1,
      renderCell: (params: any) => {
        return <Moment format="MM/DD/YYYY">{params?.value}</Moment>;
      },
    },
    {
      field: "invoice_sent_at",
      headerName: "Invoice Sent",
      flex: 1,
      sortable: true, // Enable sorting for this column
      sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(), // Comparator function for sorting
      renderCell: (params: any) => {
        return (
          <>
            {params?.value && (
              <Moment format="MM/DD/YYYY" utc>
                {params?.value}
              </Moment>
            )}
          </>
        );
      },
    },

    { field: "project_manager", headerName: "Project Manager", flex: 1 },
    { field: "filming_location", headerName: "Filming Location", width: 200 },
    {
      field: "invoice_id",
      headerName: "Invoice",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            sx={{
              height: "25px",
              background: "#9DECF2",
              border: "border: 1px solid #81D0D8",
              color: "#000",
              fontFamily: "VerdanaBold",
            }}
            variant="contained"
            onClick={() =>
              navigate(`/preview-invoice/${params?.row?.invoice_id}/`)
            }
          >
            Proposal
          </Button>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              sx={{
                height: "25px",
                background: "#9DECF2",
                border: "border: 1px solid #81D0D8",
                color: "#000",
                fontFamily: "VerdanaBold",
              }}
              variant="contained"
              onClick={() =>
                navigate(
                  `/invoice/${params?.row?.invoice_id}/${params?.row?.client_name}`
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              sx={cloneBtnStyle.btnStyle}
              className="btn btn_custom mx-2"
              onClick={() => handleClone(params)}
            >
              Clone
            </Button>
          </>
        );
      },
    },
  ];
  const noeditcolumns: GridColumns = [
    { field: "client_name", headerName: "Client Name", width: 200, flex: 1 },
    { field: "project_name", headerName: "Project Name", width: 200, flex: 1 },
    {
      field: "first_shoot_date",
      headerName: "First shoot date",
      flex: 1,
      renderCell: (params: any) => {
        return <Moment format="MM/DD/YYYY">{params?.value}</Moment>;
      },
    },
    { field: "project_manager", headerName: "Project Manager", flex: 1 },
    { field: "filming_location", headerName: "Filming Location", width: 200 },
    {
      field: "invoice_id",
      headerName: "Invoice",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            sx={{
              height: "25px",
              background: "#9DECF2",
              border: "border: 1px solid #81D0D8",
              color: "#000",
              fontFamily: "VerdanaBold",
            }}
            variant="contained"
            onClick={() =>
              navigate(`/preview-invoice/${params?.row?.invoice_id}/`)
            }
          >
            Proposal
          </Button>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              sx={cloneBtnStyle.btnStyle}
              className="btn btn_custom mx-2"
              onClick={() => handleClone(params)}
            >
              Clone
            </Button>
          </>
        );
      },
    },
  ];

  const getProjects = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=inquiry&page=${page}`,
      });
      console.log("Fetched Projects: ", result)
      // const archiveResults: any = await get({
      //   url:
      //     APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
      //     "simple/?is_archived=True",
      // });
  
      // let paidprojects: any[] = [];
      // result.results.map((pr: any) => {
      //   if (
      //     (pr.is_stripe_payment_setup === true && pr.stage === "invoice_sent") ||
      //     (pr.is_stripe_payment_setup === true && pr.stage === "inquiry")
      //   ) {
      //     pr.stage = "card_info_saved";
      //     paidprojects.push(pr);
      //   }
      // });
  
      result?.results.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
  
      // const sortedArchives = archiveResults.sort((a: any, b: any) => {
      //   return (
      //     new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      //   );
      // });
  
      // setSortedProjects(sortedResult);
  
      // setInquiryRowData(FILTER_ROW_DATA(sortedResult, PROJECT_TABS.INQUIRY));
      setInquiryRowData(result);
      // setInvoiceRowData(FILTER_ROW_DATA(sortedResult, [PROJECT_TABS.INVOICE_SENT, "manually_booked"]));
  
      console.log("Inquiry Projects: ", inquiryRowData)
  
      // if (sortedResult) {
      //   // setCardInfoSavedRowData(
      //   //   FILTER_ROW_DATA(sortedResult, PROJECT_TABS.CARD_INFO_SAVED) ||
      //   //     paidprojects
      //   // );
      //   // setDownpaymentRowData(
      //   //   FILTER_ROW_DATA(sortedResult, PROJECT_TABS.FIRST_DOWN_PAYMENTS)
      //   // );
      //   // setPartialRowData(
      //   //   FILTER_ROW_DATA(sortedResult, PROJECT_TABS.PARTIAL_PAYMENTS)
      //   // );
      //   // setFullpaymentRowData(
      //   //   FILTER_ROW_DATA(sortedResult, PROJECT_TABS.FULLY_PAID)
      //   // );
      // }
      // if (sortedArchives) {
      //   setArchiveRowData(sortedArchives);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInvoiceSent = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=invoice_sent&page=${page}`,
      });
  
      console.log("Invoice Sent Result: ", result);
  
      setInvoiceRowData(result);
      
    } catch (error) {
      console.error(error);
    }
  }

  const fetchCardInfoSaved = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=card_info_saved&page${page}`,
      });
  
      console.log("Card Info Saved Result: ", result);
  
      setCardInfoSavedRowData(result);
      
    } catch (error) {
      console.error(error)
    }
  }

  const fetchFirstDownPayment = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=first_down_payment&page=${page}`,
      });
  
      console.log("First Down Payment Result: ", result);
  
      setDownpaymentRowData(result);
      
    } catch (error) {
      console.error(error);
    }
  }

  const fetchPartiallyPayment = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=partial_payment&page=${page}`,
      });
  
      console.log("Partially Payment Result: ", result);
  
      setPartialRowData(result);
      
    } catch (error) {
      console.error(error);
    }
  }

  const fetchFullyPaid = async (page=1) => {
    try {
      const result: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?stages=fully_paid&page=${page}`,
      });
  
      console.log("Fully Paid Result: ", result);
  
      setFullpaymentRowData(result);
      
    } catch (error) {
      console.error(error);
    }
  }

  const fetchArchive = async (page=1) => {
    try {
      const archiveResults: any = await get({
        url:
          APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo.role] +
          `simple/?is_archived=True&page=${page}`,
      });

      console.log("Archive Result: ", archiveResults)
  
      archiveResults.results.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
  
      if (archiveResults) {
        setArchiveRowData(archiveResults);
      }
      
    } catch (error) {
      console.error(error);
    }
  }

  const handleArchiveStatus = async (ids: any[], is_archived: boolean) => {
    try {
      if (ids.length <= 0) {
        toast.show({
          severity: "error",
          message: "Please select project first",
        });
        return;
      }

      await put({
        url: APIMETHOD.INVOICE.CREATE_PROJECTS[userInfo?.role] + "simple/",
        data: { project_ids: ids, is_archived },
      });
      await getProjects();

      if (is_archived) setArchiveIds([]);
      else setUnarchiveIds([]);

      const action = is_archived ? "archived" : "unarchived";

      toast.show({
        severity: "success",
        message: `Project ${action} successfully`,
      });
    } catch (errors) {
      toast.show({ severity: "error", message: "Something went wrong" });
    }
  };

  const triggerArchiveUpdate = async (status: boolean) => {
    let ids = status ? archiveIds : unarchiveIds;
    await handleArchiveStatus(ids, status);
  };

  const doResetIds = (newValue:any) => {
    setArchiveIds([]);
    setUnarchiveIds([]);
    setCurrentTab(newValue);
  };

  const handleSelectedArchivedIds = (selected: number[]) => {
    setArchiveIds(selected);
  };

  const handleSelectedUnArchivedIds = (selected: number[]) => {
    setUnarchiveIds(selected);
  };

  // useEffect(() => {
  //   getProjects();
  // }, []);

  useEffect(() => {
    console.log("Current Tab Number: ", currentTab);
    switch(currentTab) {
      case 0:
          getProjects();
        break;
      case 1:
          fetchInvoiceSent()
        break;

      case 2:
          fetchCardInfoSaved();
        break;

      case 3:
          fetchFirstDownPayment();
        break;

      case 4:
        fetchPartiallyPayment()
        break;

      case 5:
          fetchFullyPaid()
        break;

      case 6:
          fetchArchive();
        break;
    }
  }, [currentTab]);

  const columnsWithoutCheckbox = noeditcolumns.slice(1);
  const columnsForArchive: GridColumns = [
    // {
    //   field: "id",
    //   headerName: "",
    //   width: 5,
    //   renderCell: (params: any) => (
    //     <Checkbox
    //       onChange={() => setUnarchiveIds((prev) => [...prev, params?.value])}
    //     />
    //   ),
    // },
    { field: "client_name", headerName: "Client Name", width: 200, flex: 1 },
    { field: "project_name", headerName: "Project Name", width: 200, flex: 1 },
    {
      field: "first_shoot_date",
      headerName: "First shoot date",
      flex: 1,
      renderCell: (params: any) => {
        return <Moment format="MM/DD/YYYY">{params?.value}</Moment>;
      },
    },
    { field: "project_manager", headerName: "Project Manager", flex: 1 },
    { field: "filming_location", headerName: "Filming Location", width: 200 },
    {
      field: "invoice_id",
      headerName: "Invoice",
      width: 100,
      renderCell: (params: any) => {
        return (
          <Button
            size="small"
            sx={{
              height: "25px",
              background: "#9DECF2",
              border: "border: 1px solid #81D0D8",
              color: "#000",
              fontFamily: "VerdanaBold",
            }}
            variant="contained"
            onClick={() =>
              navigate(`/preview-invoice/${params?.row?.invoice_id}/`)
            }
          >
            Proposal
          </Button>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              sx={{
                height: "25px",
                background: "#9DECF2",
                border: "border: 1px solid #81D0D8",
                color: "#000",
                fontFamily: "VerdanaBold",
              }}
              variant="contained"
              onClick={() =>
                navigate(
                  `/invoice/${params?.row?.invoice_id}/${params?.row?.client_name}`
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              sx={cloneBtnStyle.btnStyle}
              className="btn btn_custom mx-2"
              onClick={() => handleClone(params)}
            >
              Clone
            </Button>
          </>
        );
      },
    },
  ];

  const tabInfo: PROJECT_TAB_INFO_DTO[] = [
    {
      tabName: PROJECT_TABS_NAME.INQUIRY,
      tabid: PROJECT_TABS.INQUIRY,
      components: Grid,
      rows: inquiryRowData,
      columns: draftedColumns,
      handleSelectedRows: handleSelectedArchivedIds,
      handlePageChange: (evt:any, pageNumber:number) => {
        getProjects(pageNumber)
      },
      showCheckbox: true,
    },
    {
      tabName: PROJECT_TABS_NAME.INVOICE_SENT,
      tabid: PROJECT_TABS.INVOICE_SENT,
      components: Grid,
      rows: invoiceRowData,
      columns: columns,
      handleSelectedRows: handleSelectedArchivedIds,
      handlePageChange: (evt:any, pageNumber:number) => {
        fetchInvoiceSent(pageNumber)
      },
      showCheckbox: true,
    },
    {
      tabName: PROJECT_TABS_NAME.CARD_INFO_SAVED,
      tabid: PROJECT_TABS.CARD_INFO_SAVED,
      components: Grid,
      rows: cardInfoSavedRowData,
      columns: columnsWithoutCheckbox,
      handlePageChange: (evt:any, pageNumber:number) => {
        fetchCardInfoSaved(pageNumber);
      },
      showCheckbox: false,
    },
    {
      tabName: PROJECT_TABS_NAME.FIRST_DOWN_PAYMENTS,
      tabid: PROJECT_TABS.FIRST_DOWN_PAYMENTS,
      components: Grid,
      rows: downpaymentRowData,
      columns: columnsWithoutCheckbox,
      handlePageChange: (evt:any, pageNumber:number) => {
        fetchFirstDownPayment(pageNumber);
      },
      showCheckbox: false,
    },
    {
      tabName: PROJECT_TABS_NAME.PARTIAL_PAYMENTS,
      tabid: PROJECT_TABS.PARTIAL_PAYMENTS,
      components: Grid,
      rows: partialRowData,
      columns: columnsWithoutCheckbox,
      handlePageChange: (evt:any, pageNumber:number) => fetchPartiallyPayment(pageNumber),
      showCheckbox: false,
    },
    {
      tabName: PROJECT_TABS_NAME.FULLY_PAID,
      tabid: PROJECT_TABS.FULLY_PAID,
      components: Grid,
      rows: fullpaymentRowData,
      columns: noeditcolumns,
      handleSelectedRows: handleSelectedArchivedIds,
      handlePageChange: (evt:any, pageNumber:number) => fetchFullyPaid(pageNumber),
      showCheckbox: true,
    },
    {
      tabName: PROJECT_TABS_NAME.ARCHIVE,
      tabid: PROJECT_TABS.ARCHIVE,
      components: Grid,
      rows: archiveRowData,
      columns: columnsForArchive,
      handleSelectedRows: handleSelectedUnArchivedIds,
      handlePageChange: (evt:any, pageNumber:number) => fetchArchive(pageNumber),
      showCheckbox: true,
    },
  ];

  return (
    <div className="container">
      <LoadingService open={loading} />
      <Box sx={{ my: 2 }}>
        <h5>Project Management</h5>
      </Box>
      <div className="p-4 bg-white shadow rounded-3">
        <TabContextProvider>
          <ProjectTabs
            tabInfo={tabInfo}
            onArchive={(status) => triggerArchiveUpdate(status)}
            onTabChange={doResetIds}
          />
        </TabContextProvider>
      </div>
    </div>
  );
};

export default Project;
