import React from 'react';
import { Box } from '@mui/material';
import NewUsersWidget from './Widgets/NewClientsWidget'; // Import the NewUsersWidget component
import LoadingService from '../../shared/loader';
import { useAxios } from '../../core/useAxios';
import BookingValueWidget from './Widgets/BookingValuePaidWidget';
import RecentProposalsWidget from './Widgets/RecentProposalsWidget';
import UpcomingValueWidget from './Widgets/BookingValueUpcomingWidget'



export default function Home() {
    // Your existing code...
    const { get, put, loading } = useAxios({ showLoader: true });

    return (
        <div className='container-fluid flex-grow-1' style = {{'height':'870vh'}}>
            <LoadingService open={loading} />
            <div className="row" style={{marginTop:"2rem"}}>
                
                <div className="col-md-8 flex-grow-1"> {/* This will make it use 20% of the width on medium-sized screens */}
                    <div className="row justify-content-center">
                        <div className="col-md-3"> {/* This will make it use 20% of the width on medium-sized screens */}
                            <BookingValueWidget />
                        </div>
                        <div className="col-md-3"> {/* This will make it use 20% of the width on medium-sized screens */}
                            <UpcomingValueWidget />
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-5">
                    <div className="row">
                        <div className="col-md-6 flex-grow-1">
                            <NewUsersWidget />
                        </div>
                        <div className= "col-md-6 flex-grow-1">
                            {/* <div className="col-md-6 flex-grow-1" style={{"marginTop":"3rem", "width":"40vw"}}> This will make it use 20% of the width on medium-sized screens */}
                                <RecentProposalsWidget />
                            {/* </div> */}
                        </div>

                    </div>

                </div>
              
            </div>

        </div>
    );
}




