import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CrewDetails from '../../pages/Invoice/CrewDetails';

interface AccordianVprops {
    tabNumber: number,
    crewIndex: number,
    formikProps?: any
}

const accodianStyle = {
    background: '#E0FDFF 0% 0% no-repeat padding-box',
    borderRradius: '4px',
    minHeight: '2.5rem',
    height: '2.5rem',
    color: '#000000'
}

function AccordianVoyage(props: AccordianVprops) {
    const { formikProps,  tabNumber, crewIndex } = props;
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const handlePanelChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    let errors_production = formikProps?.errors?.production_details?.day ? formikProps?.errors?.production_details?.day[tabNumber] : null;
    errors_production = errors_production ? errors_production?.crew_package : null;
    errors_production = errors_production ? errors_production[crewIndex] : null;
    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')} sx={{mb: 1}}>
            <AccordionSummary className='accordionHeader'
                sx={errors_production ? {...accodianStyle, ...{backgroundColor: '#dc3545', color: '#fff'}} : accodianStyle}
                expandIcon={<ExpandMoreIcon sx={{color: '#fff'}} />}
            >
                <Typography>
                    Crew Package {crewIndex + 1}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CrewDetails formikProps={formikProps} tabNumber={tabNumber} crewIndex={crewIndex} key={`crew-package-${crewIndex}`}/>
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordianVoyage;