import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Box,
  Avatar,
  ListItemIcon,
  Menu,
} from "@mui/material";
import Logo from "../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderLeftBanner from "../../assets/images/headerleftbanner.svg";
import React, { useState, useEffect } from "react";
import "./header.scss";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { headersData, subMenuItem } from "./data";
import { useAppStateContext } from "../../state/provider";
import { loggedOut } from "../../state/auth/actions";
import Cookies from "js-cookie";
import { getUserInfo, USER_ACCESS } from "../../utils/utils";

export default function Header(props: any) {
  const { state, dispatch } = useAppStateContext();
  const navigate = useNavigate();
  const { isAuthenticated } = state.auth;
  const path = window?.location?.pathname;
  const userInfo = state.auth?.user || getUserInfo();
  const initialName = userInfo.fullName
    ? userInfo.fullName.split("")[0].substring(0, 1).toUpperCase()
    : userInfo?.email?.substring(0, 1).toUpperCase();
  const [displaystate, setDisplayState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [headerData, setHeaderData] = useState(headersData);
  const [isActive, setIsActive] = useState("21");
  const { mobileView, drawerOpen } = displaystate;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [profileEl, setProfileEl] = React.useState<null | HTMLElement>(null);
  const profileOpen = Boolean(profileEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    setProfileEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setProfileEl(null);
  };

  const showMenu = path.includes("/register");

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 650
        ? setDisplayState((prevState) => ({ ...prevState, mobileView: true }))
        : setDisplayState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    const userAccess: any = USER_ACCESS[userInfo?.role] || [];
    const hdata = headerData.map((x: any) => {
      if (userAccess[0] === "all" || userAccess?.includes(x.label)) {
        x.isVisible = true;
      }
      return x;
    });
    if (!showMenu) {
      setHeaderData(hdata);
    } else {
      setHeaderData([]);
    }
  }, []);

  const logOut = () => {
    Cookies.remove("accessToken");
    dispatch(loggedOut());
    navigate("/login");
  };

  const displayDesktop = () => {
    return (
      <Toolbar variant="dense" className="toolbar pl-0" disableGutters={true}>
        <Box className="">
          <img height={"48px"} src={HeaderLeftBanner} alt="Logo" />
        </Box>
        {femmecubatorLogo}
        <Box className="header-menu d-flex justify-content-center">
          {getMenuButtons()}
        </Box>
        {!showMenu && (
          <Box className="d-flex justify-content-end">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Button
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ padding: "0.25rem 1rem", mr: 2 }}
                className="gradientRoseYellowBtn"
                variant="contained"
                disableElevation
                endIcon={<KeyboardArrowDown />}
              >
                New
              </Button>
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "left", textAlign: "left" }}
            >
              <IconButton
                onClick={handleClickProfile}
                size="small"
                aria-controls={profileOpen ? "profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={profileOpen ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>{initialName}</Avatar>
              </IconButton>
            </Box>
            {open
              ? getMenu(
                  { anchorEl: anchorEl, open: open, handleClose: handleClose },
                  "menu",
                  "account-menu"
                )
              : null}
            {profileOpen
              ? getMenu(
                  {
                    anchorEl: profileEl,
                    open: profileOpen,
                    handleClose: handleCloseProfile,
                  },
                  "profile",
                  "profile-menu"
                )
              : null}
          </Box>
        )}
      </Toolbar>
    );
  };
  // saif test
  const getMenu = (data: any, menuType: string, id: string) => {
    return (
      <Menu
        anchorEl={data.anchorEl}
        id={id}
        open={data.open}
        onClose={data.handleClose}
        onClick={data.handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {menuType === "menu" &&
          subMenuItem.map((menulist: any) => {
            const Icons = menulist.icon;
            return (
              <div key={menulist.id}>
                <RouterLink
                  aria-disabled={menulist.isDisabled}
                  className="submenu-link"
                  to={menulist.path}
                >
                  <MenuItem disabled={menulist.isDisabled}>
                    <ListItemIcon>
                      <Icons fontSize="small" />
                    </ListItemIcon>
                    {menulist.name}
                  </MenuItem>
                </RouterLink>
              </div>
            );
          })}
        {menuType === "profile" && (
          <Box
            sx={{ width: "15rem", flexWrap: "wrap" }}
            className="d-flex mt-2"
          >
            <Box className="position-absolute top-0">
              <img height={"45px"} src={HeaderLeftBanner} alt="Logo" />
            </Box>
            <div className="d-flex justify-content-center w-100 mb-1">
              <Avatar sx={{ width: 32, height: 32 }}>{initialName}</Avatar>
            </div>
            <div className="w-100 text-center mb-1">
              <Typography
                variant="h6"
                component="h6"
                fontSize={14}
                fontWeight={600}
              >
                {userInfo.name}
              </Typography>
              <Typography variant="h6" component="h6" fontSize={12}>
                {userInfo?.email}
              </Typography>
            </div>
            <div className="w-100 text-center mt-2">
              <Button
                onClick={logOut}
                sx={{
                  padding: "3px 10px",
                  fontSize: "12px",
                  border: "1px solid #81D0D8",
                  borderRadius: "4px",
                  background: "#9DECF2",
                }}
              >
                Logout
              </Button>
            </div>
          </Box>
        )}
      </Menu>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setDisplayState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setDisplayState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={"drawerContainer"}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const activeTab = (e: any) => {
    e.stopPropagation();
    setIsActive(e?.currentTarget?.id);
  };

  const getDrawerChoices = () => {
    return headerData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const femmecubatorLogo = (
    <Typography sx={{ cursor: "pointer" }}>
      <img width={"150px"} src={Logo} onClick={() => navigate("/")} />
    </Typography>
  );

  const getMenuButtons = () => {
    return headerData.map(({ label, href, isVisible }: any, index: number) => {
      return (
        <>
          {isVisible ? (
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: RouterLink,
                className: "menuButton",
                id: index + "1",
              }}
              onClick={(e: any) => activeTab(e)}
            >
              <label
                className={
                  "cursor-pointer " +
                  (isActive === index + "1" ? "active " : "")
                }
              >
                {label}
              </label>
            </Button>
          ) : null}
        </>
      );
    });
  };

  return (
    <header>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#000000" }}
        className={"header"}
      >
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
