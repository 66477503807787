import React, { useEffect, useMemo, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useAxios } from '../../core/useAxios';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { toast } from '../../shared/Toser/ToastManager';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Box, Typography } from '@mui/material';
import SendEmailToManager, { SendEmailToManagerRef } from '../Managers/SendEmailToManager';
import { APIMETHOD } from '../../core/constant';
import { EMAIL_HELPER_TEXT, getUserInfo } from '../../utils/utils';
import LoadingService from '../../shared/loader';
import TextField from '@mui/material/TextField';
import Client from '../Clients/Client';


interface UserDialogProps {
    isOpen: boolean,
    // addClients: any,
    handleClose: (data: any) => void,
    clientDetails: any,
    selectedPM:any,
}

export const style: any = {
    secondary: {
        background: '#F3F3F3',
        boxShadow: '0px 2px 5px #0000000D',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        color: '#000',
        px: 3,
        fontFamily: 'VerdanaBold'
    },
    submit: {
        background: 'transparent linear-gradient(258deg, #D256E8 0%, #E7A34F 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 10px 20px #0000000D',
        borderRadius: '4px',
        opacity: 1,
        color: '#ffffff',
        px: 3,
        fontFamily: 'VerdanaBold'
    }

}


function ReassignProjectManager(props: UserDialogProps){
  const { isOpen, handleClose, clientDetails, selectedPM } = props;
  const [showSendEmail, setShowSendEmail] = useState(true);
  const [projectManager, setProjectManager] = useState<any>(null);
  const [emailRequestDetails, setEmailRequestDetails] = useState<any>(null);
  const [client_name, setClientName] = useState<string>('');
  const [clientList, setClients] = React.useState<any>([]);
  const [projectManagerId, setProjectManagerId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = getUserInfo();

  const { post, error, get } = useAxios({ showLoader: true });


  const handleCloseTask = () => {
        handleClose(false);
  };
  
  const defaultProps = {
      options: clientList,
      getOptionLabel: (option: any) => `${option.full_name}`
  };


  const sendEmailToManagerRef = useRef<SendEmailToManagerRef | null>(null);

  useEffect(() => {
        const getManagers = async () => {
            const result = await get({ url: APIMETHOD.user.GET_USER });
            if (result) {
                mapProjectData(result);
                setClients(result);
                defaultProps.options = result;
            }
        }
        getManagers();
    }, []);

  const mapProjectData = (projectmanager: any[] = []) => {
        if (clientDetails.id) {
            const selectedClientIndex = projectmanager.findIndex((x: any) => x?.full_name === clientDetails?.assigned_to);
            setClientName(clientDetails?.name);
            if (selectedClientIndex > -1) {
                setProjectManager(projectmanager[selectedClientIndex]);
            }
        }
    }
    const handleSubmit = async () => {
        // console.log("data is ::", emailRequestDetails)
        let hasErrorsInMail = null
        if (sendEmailToManagerRef.current) {
            hasErrorsInMail = await sendEmailToManagerRef.current.checkingEmailValidations();
            if (hasErrorsInMail) return
        }
        const formData: any = new FormData();
        const data = {
            'project_manager_id': projectManagerId,
            'client_id': clientDetails.id
        }
        formData.append('project_manager_id', projectManagerId);
        formData.append('client_id', clientDetails.id);
        (!emailRequestDetails.email) && (emailRequestDetails.email = projectManager.email)
        console.log(emailRequestDetails)
        if (!emailRequestDetails.email_cc) {
            emailRequestDetails.email_cc = []
        } else {
            emailRequestDetails.email_cc = emailRequestDetails.email_cc.split(',')
        }
        setIsLoading(true);
        const response = await reassignProjectManager(data, emailRequestDetails);
        setIsLoading(false);

        if (response) {
            handleCloseTask();
            console.log("auth_url::",response.auth_url)
            if (response?.auth_url) {
                let link: any = document.createElement('a');
                link.href = response?.auth_url;
                link.target = '_blank';
                link.click();
                localStorage.removeItem(`email_data_manager${clientDetails?.id}`)
            } else {
                window.location.reload()
                toast.show({ severity: 'success', message: response?.message });
            }
        }
    }
    const reassignProjectManager = async (clientDetails: any, emailRequestDetails: any) => {
        console.log("formData::", clientDetails)
      return await post({ url: APIMETHOD.INVOICE.CLIENTS[userInfo?.role] + 'assign/', data: { ...clientDetails, ...emailRequestDetails } });
    }
return(
  <div>
    <LoadingService open={isLoading} />
    <Dialog PaperProps={{ style: { borderRadius: 28, border: '1px solid #D0D0D0', boxShadow: '0px 20px 30px #0000000D' } }} open={isOpen} onClose={handleCloseTask} fullWidth={true} scroll='body' >
      <DialogContent>
        <div>
            <h5 className='p-2'>Re-assign Project Manager</h5>
                    {error?.exactErr?.project_manager_id && error?.exactErr?.project_manager_id[0] &&
                        <Box sx={{ pb: 2 }}>
                            <small className='errors_color'>{error?.exactErr?.project_manager_id[0]}</small>
                        </Box>
                    }
                    {error?.exactErr?.client_id && error?.exactErr?.client_id[0] &&
                        <Box sx={{ pb: 2 }}>
                            <small className='errors_color'>{error?.exactErr?.client_id[0]}</small>
                        </Box>
            }
            <FormControl variant="standard" fullWidth>
                <Autocomplete
                    {...defaultProps}
                    id="_clients"
                    value={projectManager}
                    onChange={(event: any, newValue: any) => {
                        // console.log("newValue::", newValue)
                        setProjectManagerId(newValue?.id);
                        setProjectManager(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Confirm Project Manager" variant="standard" />
                    )}
                />
            </FormControl>
            </div>
            <div className='col-12'>
        </div>
        {showSendEmail && (
            <SendEmailToManager
                emailDetails={{ client_id: clientDetails?.id, email: (projectManager?.email || ''), client_name: clientDetails?.name,client_email: clientDetails?.email,parject_manager_id: (projectManager?.id || ''),description:clientDetails?.description}}
                handleCloseEmail={() => { setShowSendEmail(false) }}
                key={clientDetails?.id}
                projectDetails = {clientDetails?.projects.length ? clientDetails.projects[clientDetails.projects.length -1 ] : null }
                emailRequestDetails={emailRequestDetails}
                setEmailRequestDetails={setEmailRequestDetails}
                ref={sendEmailToManagerRef}
            />
        )}
      </DialogContent>
       <DialogActions sx={{ borderTop: '1px solid #D0D0D0', justifyContent: 'left', px: 4, py: 2 }}>
            <Button sx={style.secondary} onClick={handleCloseTask}>Cancel</Button>
            <Button sx={style.submit} onClick={() => { handleSubmit() }}>{'Save'}</Button>
        </DialogActions>
    </Dialog>
  </div>

)

}
export default ReassignProjectManager;